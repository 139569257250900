import React, { Component } from "react";
import CommunicationPossibleContext from '../../contexts/CommunicationPossibleContext';
import WarningPopup from '../WarningPopup/WarningPopup'

/*
  The notifier component is a warning popup shown in the time of on boarding into the application by the user. The popup
  is shown only if the communication between Workshop maintenance service and xentry device is disabled.
*/
export default class Notifier extends Component {
  constructor(props) {
    super(props);
    this.state = { showWarningPopup: true }
  }

  componentDidMount() {
    this.setState({ isCommunicationPossible: this.context.isCommunicationPossible })
    if (!this.state.isCommunicationPossible) {
      this.setState({ showWarningPopup: true })
    }
  }
  setWarning = () => {
    // Function sets the flag to open the warning popup
    this.setState({ showWarningPopup: false })
  }
  closeWarningPopup = () => {
    // Function resets the flag to close the warning popup
    this.setState({ showWarningPopup: false });
  }
  
  render() {
    return (
      <div className="create-alert  text-capitalize fs-16 mr-1 notifier">
        <WarningPopup isOpen={this.state.showWarningPopup} toggle={this.setWarning} message="WARNING_FOR_COMMUNICATION_POSSIBLE" />
      </div>
    );
  }
}

Notifier.contextType = CommunicationPossibleContext;

