import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Information from "./../../../assets/icons/Information.svg";
import './CommunicationToggle.scss'
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import CommunicationPossibleDisable from '../CommunicationPossibleDisable/CommunicationPossibleDisable';
import ApiPath from "../../../constants/ApiPath";
import axiosInstance from "../../../utlities/Interceptor";
import CommunicationPossibleContext from '../../../contexts/CommunicationPossibleContext';
import EntitilementsContext from '../../../contexts/EntitlementsContext';
import { entitlements } from '../../../constants/Entitlements';
import * as moment from 'moment';
import { HeaderContext } from '../../../index';

/* The Communicationtoggle component is a toggle switch that toggles the communication between
 Workshop Maintenance Service Application and Xentry device. This switch can be toggled only for users with entitlement 
 KillSwitchAccess. For unauthorised users, the switch is disabled.
*/
class CommunicationToggle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_communication_possible: true,
      showDisableModal: false,
      popupMessage: '',
      toggleActive: false,
      onLoad: true
    };
    this.toggleCommunication = this.toggleCommunication.bind(this);
  }
  checkEntitlements = (entitlementsArray) => {
    // Function checks whether the logged in user is authorised with killswitch access.
    if (this.state.onLoad && entitlementsArray.length > 0) {
      if (!entitlementsArray.includes(entitlements['killSwitchAccess'])) {
        this.setState({ showWarningPopup: true, onLoad: false, isAuthorized: false })
      }
      else {
        this.setState({ isAuthorized: true, onLoad: false })
      }
    }
  }
  toggleCommunication() {
    /* Function toggles the communication possible flag. A confimation popup is rendered so that the user doesnot change the 
    status of communication possible mistakenly.  
    */
    if (this.state.isAuthorized) {
      this.setState({ showDisableModal: true })
      if (this.context.isCommunicationPossible) {
        this.setState({ popupMessage: "ARE_YOU_SURE_TO_DISABLE" })
      } else {
        this.setState({ popupMessage: "ARE_YOU_SURE_TO_ENABLE" })
      }
    }
  }
  cancel = () => {
    // Function to close the communication possible modal
    this.setState({ showDisableModal: false })
  }
  disableCommuncation = (msgObj) => {
    /* On confirmation of the communicationPossibleDisable popup, post api is called to update the status of communication 
    possible in database. On success of the api, an api is called to send an email notifying the admins about the change in 
    status.
     */
    return (e) => {
      let data = { status: !this.context.isCommunicationPossible };
      if (msgObj.message)
        data[`message_${msgObj.language}`] = msgObj.message;
      axiosInstance.put(ApiPath.communication_status, data).then(res => {
        // if (!data.status) {
        //   // this.createCommunicationDisableAlert(msgObj);
        // }
        this.refreshList();
        this.context.toggleComm(this.context.isCommunicationPossible);
      })
        .catch(error => {
          console.log(error)
        });

      this.setState({ showDisableModal: false })
    }
  }
  createCommunicationDisableAlert = (msgObj) => {
    const data = {
      type: 'm',
      publication_date: moment.utc().format(),
      start_date: moment.utc().add(1, 'm').format(),
      is_communication_possible: false,
      is_periodical_maintenance: false,
      editID: -1,
    };
    if (msgObj.message)
      data[`message_${msgObj.language}`] = msgObj.message;
    axiosInstance.post(ApiPath.list, data)
      .then(res => {
        this.refreshList();
        // console.log(res);
      })
      .catch(error => {
        // console.log(error)
      });
  }
  refreshList = () => {
    const { history } = this.props;
    history.push('/');
    history.push('/list');
  }
  render() {

    const popover = (
      <Popover id="popover-basic" className="header-popover popover-body" >
        <FormattedMessage id="COMMUNICATION_HELP_TEXT" />
      </Popover>
    );
    return (
      <div className="communication-toggle text-center">
        <CommunicationPossibleContext.Consumer>
          {({ isCommunicationPossible, setComm, showWarningPopup, setWarning, toggleComm }) => (
            <EntitilementsContext.Consumer>
              {({ entitlements }) => (
                <div className={`create-alert  text-capitalize fs-16  notifier communication-possible ${!this.state.isAuthorized ? 'disable' : ''}`}>
                  {this.state.onLoad ? this.checkEntitlements(entitlements) : ''}
                  <HeaderContext.Consumer>
                    {({ language }) => (
                      <CommunicationPossibleDisable
                        isOpen={this.state.showDisableModal}
                        disableCommunication={this.disableCommuncation}
                        toggle={this.cancel}
                        message={this.state.popupMessage}
                        isCommunicationPossible={this.context.isCommunicationPossible}
                        language={language.code}
                      />
                    )}
                  </HeaderContext.Consumer>
                  <label className="switch ml-2">
                    <input
                      type="checkbox"
                      id="is_communication_possible"
                      name="is_communication_possible"
                      className={'communication-check ' + this.state.isAuthorized ? 'disable' : ''}
                      checked={isCommunicationPossible}
                      onChange={this.toggleCommunication}
                    />
                    <span className={!this.state.isAuthorized ? 'slider round disable' : 'slider round'} />
                  </label>
                  <label className="communication-check fs-12">
                    {isCommunicationPossible ? <FormattedMessage id="COMMUNICATION_ENABLED" /> : <FormattedMessage id="COMMUNICATION_DISABLED" />}
                    <OverlayTrigger trigger="hover" placement="right" overlay={popover}>
                      <Button id="Popover-communication" type="button" className="popover-btn btn btn-secondary">
                        <img src={Information} alt="question mark" />
                      </Button>
                    </OverlayTrigger>
                  </label>

                </div>
              )}
            </EntitilementsContext.Consumer>
          )}
        </CommunicationPossibleContext.Consumer>
      </div>
    )
  }
}
CommunicationToggle.contextType = CommunicationPossibleContext;
export default CommunicationToggle;
