import React, { Component } from "react";
import { injectIntl, FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { renderDetailsText } from '../../components/popups/PeriodicMaintenancePopup/PeriodicMaintenanceForm/PeriodicFunctions';
import { Button, PopoverBody, UncontrolledPopover } from "reactstrap";


class DisplayMessage extends Component {
  constructor(props) {
    super(props);
    this.textRef = React.createRef();
    this.textParentRef = React.createRef();
    this.state = {
      hasExpandBtn: false,
      expanded: false,
    }
  }
  componentDidMount() {
    const hasExpandBtn = this.hasExpandBtn();
    this.setState({ hasExpandBtn })
  }

  hasExpandBtn = () => {
    if (this.textRef.current && this.textParentRef.current) {
      return this.textRef.current.clientHeight > this.textParentRef.current.clientHeight;
    }
    return false;
  }
  toggleExpand = (e) => {
    // this.setState({
    //   expanded: !this.state.expanded
    // })
    const parentTr = e.target.closest('tr')
    if (parentTr.classList.contains('expanded')) {
      parentTr.classList.remove('expanded')
    }
    else {
      parentTr.classList.add('expanded');
    }

  }

  render() {
    const Div = styled.div`
      font-size: 11px;
      align-self: baseline;
    `;
    let info = renderDetailsText(this.props.rowData.recurrence_option, this.props.rowData.recurrence_option_value, this.props.rowData.start_date, this.props.intl.locale);
    const t = this.props.rowData.emergency_uuid || Date.now();
    return (
      <div id={this.props.rowData.id} className="message-col expandable" ref={this.textParentRef}>

        <Div ref={this.textRef}>
          {(this.props.intl.locale === 'de') ?
            (this.props.rowData.message_de ? this.props.rowData.message_de : '-')
            : (this.props.rowData.message_en ? this.props.rowData.message_en : '-')}
        </Div>
        {this.state.hasExpandBtn && <span className="expand-text-btn" onClick={this.toggleExpand}>. . .</span>}
        {/* <DisplayMessage rowData={row} /> */}
        {this.props.rowData.id.includes('emergency_entry') && <>
          <div className="emergency-info">
            <Button id={`Popover-type${this.props.rowData.id}-${t}`} type="button" className="popover-btn">
              <img
                src={require("./../../assets/icons/emergency-warning.svg").default}
                alt="question mark"
              />
            </Button>
            <UncontrolledPopover
              placement="right"
              target={`Popover-type${this.props.rowData.id}-${t}`}
              trigger="hover"
              id={`popover${this.props.rowData.id}-${t}`}
              className="periodic-info-popover"
            >
              <PopoverBody className="popover-body" id={`${this.props.rowData.id}-${t}`}>
                <FormattedMessage id="EMERGENCY_INFO" />
              </PopoverBody>
            </UncontrolledPopover>
          </div>

        </>}
        {this.props.rowData.is_periodical_maintenance && !this.props.rowData.is_expired ? <div className={this.props.rowData.status === "active" ? "periodic-popover active" : " periodic-popover"}>
          <Button id={"Popover-type" + this.props.rowData.id} type="button" className="popover-btn">
            <img
              src={require("./../../assets/icons/info2.png").default}
              alt="question mark"
            />
          </Button>
          <UncontrolledPopover
            placement="right"
            target={"Popover-type" + this.props.rowData.id}
            trigger="hover"
            id={"popover" + this.props.rowData.id}
            className="periodic-info-popover"
          >
            <PopoverBody className="popover-body" id={this.props.rowData.id}>
              {info}
            </PopoverBody>
          </UncontrolledPopover>
        </div> : ''}
      </div>
    )
  }
}

export default injectIntl(DisplayMessage);
