// File contains the constants related tp OAuth login

const app_base_url = process.env.REACT_APP_BASE_URL;
const encoded_base_url = encodeURIComponent(app_base_url)
// set token url
const BASE_URL = process.env.REACT_APP_OPEN_ID_BASE_URL;
const TOKEN_ENDPOINT = "oauth2/token";
export const token_url = BASE_URL + TOKEN_ENDPOINT;

const client_id = process.env.REACT_APP_AWS_CLIENT_ID;

//set redirect uri
export const redirect_uri = app_base_url;

//set client_id_base64
export const client_id_base64 = process.env.REACT_APP_CLIENT_ID_BASE_64;
export const grant_type = 'authorization_code';

//set authorisation url
export const authorisation_url = process.env.REACT_APP_OPEN_ID_BASE_URL + 'oauth2/authorize?response_type=code&client_id=' + client_id +'&redirect_uri=' + encoded_base_url

//set logout authorisation url
export const logout_authorisation_url = process.env.REACT_APP_OPEN_ID_BASE_URL + 'oauth2/authorize?response_type=code&client_id=' + client_id + '&redirect_uri=' + encoded_base_url +  encodeURIComponent('login');

//set aws logout api
export const aws_logout_url = process.env.REACT_APP_OPEN_ID_BASE_URL + 'logout?client_id=' + client_id + '&logout_uri=' + encoded_base_url 

//set daimler logout url
export const daimler_logout_url = 'https://sso.mercedes-benz.com/as/authorization.oauth2?response_type=code&client_id=' + process.env.REACT_APP_DAIMLER_CLIENT_ID + 
                '&redirect_uri=' + encodeURIComponent(process.env.REACT_APP_OPEN_ID_BASE_URL) + 'oauth2%2Fidpresponse&prompt=login'
//set daimler referrer
export const daimler_referrer = 'https://cdiwl-appstest.i.daimler.com/'

//set userinfo url
export const user_info_url = process.env.REACT_APP_OPEN_ID_BASE_URL + 'oauth2/userInfo'
