// File contains the styles related to customising react select

export const SELECT_COLOUR_STYLES = {
  control: styles => ({
    ...styles,
    backgroundColor: "rgba(24, 24, 24, 1)",
    fontSize: "12px",
    border: "none",
    color: "white",
    ":active": {
      outline: "none",
      borderColor: "transparent",
      boxShadow: "none"
    }
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isFocused
        ? "rgba(2, 133, 157, 1)"
        : "rgba(24, 24, 24, 1)",
      color: "white",
      cursor: isDisabled ? "not-allowed" : "default",
      ":active": {
        backgroundColor: "rgba(27, 121, 143, 1)"
      }
    };
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: "black"
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: "white",
    border: "solid 2px rgba(2, 133, 157, 1)"
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: "white",
    backgroundColor: "rgba(2, 133, 157, 1)",
    border: "solid 2px rgba(2, 133, 157, 1)",
    ":hover": {
      color: "rgb(1,80,94)"
    }
  })
};