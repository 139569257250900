import * as moment from "moment";
import React, { Component } from "react";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { FormattedMessage } from "react-intl";
import { Redirect, withRouter } from "react-router";

import { DATE_FORMAT, TIME_FORMAT, WEEKDAYS } from "../../../../constants/Constants";
import "./PeriodicMaintenanceForm.scss"
import { findInfo, findPeriodicStartDate } from '../PeriodicMaintenanceForm/PeriodicFunctions';
import { keydown } from '../../../../utlities/Utils';

const yesterday = Datetime.moment().subtract();
class PeriodicForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDays: [],
      validDays: []
    };
  }
  componentDidMount() {
    this.setState({ validDays: [] })
    if (this.props.values.recurrence_option === 'weekly') {
      this.setState({ selectedDays: this.props.values.recurrence_option_value.slice(0) })
    }
    this.setRecurrenceOption('daily', 1)

  }
  assignOption = (option) => {
    this.props.setFieldValue('valid_days', [])
    this.setState({ selectedDays: [], })
    this.setRecurrenceOption(option, '')
  }
  saveDays = (event, day) => {
    // find the array of checked days
    if (event.target.checked) {
      this.state.selectedDays.push((day.dayIndex))
      let validDays = findPeriodicStartDate(day.dayIndex, this.props.values.start_date, this.props.values.end_date, this.props.values.valid_days)
      this.props.setFieldValue('valid_days', validDays)
    }
    else {
      let index = this.state.selectedDays.indexOf(event.target.value)
      if (index !== -1)
        this.state.selectedDays.splice(index, 1)
      if (this.props.values.valid_days) {
        index = this.props.values.valid_days.indexOf(event.target.value.toString())
        if (index !== -1)
          this.props.values.valid_days.splice(index, 1)

      }
    }
    this.setState({ selectedDays: this.state.selectedDays })
    this.setRecurrenceOption(this.props.values.recurrence_option, this.state.selectedDays)
  }

  setRecurrenceOption = (option, value) => {
    this.props.setFieldValue('recurrence_option', option)
    this.props.setFieldValue('recurrence_option_value', value)
    // set the default cases
    if (option === 'daily') {
      this.props.setFieldValue('recurrence_option_value', 1)
    }

    if (option === 'monthly') {
      setTimeout(this.setMonthDate, 100)
    }
  }
  setMonthDate = () => {
    if (this.props.values.recurrence_option === 'monthly' && this.props.values.start_date && !this.props.values.recurrence_option_value) {
      //set start date as default value 
      this.props.setFieldValue('recurrence_option_value', moment(this.props.values.start_date).format('DD'))
    }
  }
  renderValue = () => {
    let returnVal = null;
    switch (this.props.values.recurrence_option) {
      case 'daily':
        // By default 1 is the value
        returnVal = <div>
          <FormattedMessage id="EVERY" />
          <input className="day-number form-control quantity" type="number"
            value={this.props.values.recurrence_option_value} maxLength="3"
            id="recurrence_option_value" name="recurrence_option_value"
            autoComplete="off"
            max="365"
            onChange={this.props.handleChange} />
          <FormattedMessage id="DAY_(S)" />
        </div>;
        break;
      case 'weekly':
        returnVal = <div className="weekly">
          {WEEKDAYS.map((day, index) => (
            <div className="each-day" key={index}>
              <input type="checkbox" id={index}
                onChange={(event) => this.saveDays(event, day)}
                value={day.dayIndex}
                checked={this.props.values.recurrence_option_value.includes((day.dayIndex))}
              />
              <label htmlFor={index}></label>
              <label htmlFor={index}><span className="day-name">{day.value}</span></label>
            </div>
          ))}
        </div>
        break;
      case 'monthly':
        // By default start date is the value
        returnVal = <div>
          <FormattedMessage id="DAY" />
          <input className="day-number form-control" type="number" value={this.props.values.recurrence_option_value} maxLength="2"
            id="recurrence_option_value" name="recurrence_option_value"
            autoComplete="off"
            onChange={this.props.handleChange} max="31" />

          <FormattedMessage id="OF_EVERY_MONTH" />
        </div>;
        break;
      default: returnVal = null;
    }
    return returnVal;
  }
  blockDates = (current, dateBefore, dateAfter) => {
    return current.isSameOrAfter(yesterday, 'day');
  }
  setDate = (momentObj, field) => {
    this.props.setFieldValue(field, momentObj);
    if (this.props.values.recurrence_option === 'weekly') {
      this.props.setFieldValue('find_periodic', true)
      setTimeout(this.periodicReCalculations, 100)
    }
    if (this.props.values.recurrence_option === 'monthly' && field === 'start_date') {
      // to set the default case
      setTimeout(this.setMonthDate, 100)

    }
  }
  periodicReCalculations = () => {
    this.setState({ validDays: [] })
    let validDays = []
    if (this.props.values.recurrence_option === 'weekly') {
      if (this.props.values.recurrence_option_value.constructor === Array) {
        this.props.values.recurrence_option_value.forEach(element => {
          validDays = findPeriodicStartDate(element, this.props.values.start_date, this.props.values.end_date, validDays)
        });

        this.props.setFieldValue('valid_days', validDays)
        this.props.setFieldValue('find_periodic', true)
      }
    }
  }
  displayError = (field) => {
    if (this.props.touched[field] && this.props.errors[field]) {

      return (
        <div className="errors-text fs-12 text-transform-initial">
          <FormattedMessage id={this.props.errors[field]} />
        </div>
      );
    }
  }
  displayPeriodicErrors = () => {
    if (this.props.values.recurrence_option === 'weekly' && this.props.valid_days) {
      if (this.props.values.valid_days.length !== 0 && this.props.values.valid_days.length !== this.props.values.recurrence_option_value.length && this.state.saveClicked)
        return (
          <div className="errors-text fs-12 text-transform-initial">
            <div>Not all selected days are valid. Only the valid days will be saved</div>

          </div>
        );
    }
  }
  render() {
    if (this.state.toList === true) {
      return <Redirect push to="/list" />;
    }
    return (
      <form onSubmit={(e) => { e.stopPropagation(); this.props.handleSubmit(e) }} className="periodic-maintenance-form alert-form d-flex flex-wrap">
        <div className="d-flex flex-wrap periodic">
          <div className="form-group col-xs-12 col-sm-6">
            <span className="fs-12 label-mb d-block text-transform-initial">
              <FormattedMessage id="START_DATE" />
            </span>
            <Datetime
              isValidDate={current =>
                this.blockDates(current, this.props.values.end_date, "")
              }
              value={
                this.props.values.start_date &&
                  this.props.values.start_date !== "-"
                  ? new Date(moment(this.props.values.start_date).format())
                  : ""
              }
              onChange={momentObj => this.setDate(momentObj, "start_date")}
              closeOnSelect={true}
              dateFormat={DATE_FORMAT}
              timeFormat={false}
              className="custom-datepicker"
              inputProps={{onKeyDown: event => keydown(event), readOnly: true}}
            />
          </div>
          <div className="form-group col-xs-12 col-sm-6">
            <span className="fs-12 label-mb d-block text-transform-initial">
              <FormattedMessage id="END_DATE" />
            </span>
            <Datetime
              isValidDate={current =>
                this.blockDates(current, "", this.props.values.start_date)
              }
              value={
                this.props.values.end_date && this.props.values.end_date !== "-"
                  ? new Date(moment(this.props.values.end_date).format())
                  : ""
              }
              onChange={momentObj => this.setDate(momentObj, "end_date")}
              closeOnSelect={true}
              dateFormat={DATE_FORMAT}
              timeFormat={false}
              className="custom-datepicker"
              inputProps={{onKeyDown: event => keydown(event), readOnly: true}}
            />

          </div>
          <div className="form-group col-xs-12 col-sm-6 periodic-time"
          >
            <span className="fs-12 label-mb d-block text-transform-initial">
              <FormattedMessage id="START_TIME" />
            </span>
            <Datetime
              closeOnSelect={false}
              isValidDate={current =>
                this.blockDates(
                  current,
                  this.props.values.periodical_end_date,
                  ""
                )
                && this.blockDates(current,
                  this.props.values.end_date, this.props.values.start_date)
              }
              value={
                this.props.values.start_date &&
                  this.props.values.start_date !== "-"
                  ? new Date(
                    moment(this.props.values.start_date).format()
                  )
                  : ""
              }
              onChange={momentObj =>
                this.setDate(momentObj, "start_date")
              }
              dateFormat={false}
              timeFormat={TIME_FORMAT}
              inputProps={{onKeyDown: event => keydown(event), readOnly: true}}
              className="custom-datepicker timepicker"
            />
            {this.displayError('start_date')}

          </div>


          <div className="form-group col-xs-12 col-sm-6 periodic-time"
          >
            <span className="fs-12 label-mb d-block text-transform-initial">
              <FormattedMessage id="END_TIME" />
            </span>
            <Datetime
              isValidDate={current =>
                this.blockDates(
                  current,
                  "",
                  this.props.values.start_date
                )
                && this.blockDates(current,
                  this.props.values.end_date, this.props.values.start_date)
              }
              value={
                this.props.values.end_date &&
                  this.props.values.start_date !== "-"
                  ? new Date(
                    moment(this.props.values.end_date).format()
                  )
                  : ""
              }
              onChange={momentObj =>
                this.setDate(momentObj, "end_date")
              }
              closeOnSelect={false}
              dateFormat={false}
              timeFormat={TIME_FORMAT}
              className="custom-datepicker timepicker"
              inputProps={{onKeyDown: event => keydown(event), readOnly: true}}

            />
            {this.displayError('end_date')}

          </div>

          <div className="recurrence-pattern">
            <div className="heading">
              <FormattedMessage id="RECURRENCE_PATTERN" />
            </div>
            <div className="options">
              <div className="mr-4">
                <input type="radio" id="daily" name="recurrence-type" className="default" value="daily"
                  checked={this.props.values.recurrence_option === 'daily'}
                  onChange={() => this.assignOption('daily')}
                />
                <label htmlFor="daily" className="type-radio text-capitalize fs-12">
                  <FormattedMessage id="DAILY" />
                </label>
              </div>
              <div className="mr-4">
                <input type="radio" id="weekly" name="recurrence-type" className="default" value="weekly"
                  checked={this.props.values.recurrence_option === 'weekly'} onChange={() => this.assignOption('weekly')} />
                <label htmlFor="weekly" className="type-radio text-capitalize fs-12">
                  <FormattedMessage id="WEEKLY" />
                </label>
              </div>
              <div className="mr-4">
                <input type="radio" id="monthly" name="recurrence-type" className="default" value="monthly"
                  checked={this.props.values.recurrence_option === 'monthly'} onChange={() => this.assignOption('monthly')} />
                <label htmlFor="monthly" className="type-radio text-capitalize fs-12">
                  <FormattedMessage id="MONTHLY" />
                </label>
              </div>
            </div>
            <div className="line" />
            <div className="value">
              {this.renderValue()}
              {this.displayError('recurrence_option_value')}
              {this.displayPeriodicErrors()}
              {findInfo(this.props.values.recurrence_option, this.props.values.recurrence_option_value)}
            </div>
          </div>
          <div className="form-group col-xs-10 col-sm-12 col-md-12 col-lg-12 d-flex flex-wrap modal-footer">
            <button
              className="btn btn-secondary text-capitalize fs-13  btn-cancel btn-modal"
              type="button"
              onClick={this.props.toggle}
            >
              <FormattedMessage id="CANCEL" />
            </button>
            <button
              variant="raised"
              type="submit"
              className="btn btn-primary text-capitalize fs-13  btn btn-modal"
              onClick={() => this.setState({ saveClicked: true })}
            >
              <FormattedMessage id="SAVE" />
            </button>
          </div>
        </div>
      </form >
    );
  }
}

export default withRouter(PeriodicForm);
