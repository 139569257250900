import { FormattedMessage } from "react-intl";
import React from "react";
import * as moment from "moment";
import orderBy from 'lodash/orderBy';
import loKeys from 'lodash/keys';


import { WEEKDAYS_MAPPING } from "../../../../constants/Constants";

export const findInfo = (option, value) => {
  let info = null;
  if (value) {
    switch (option) {
      case 'daily': info =
        <span>
          <FormattedMessage id="OCCURS_EVERY" />{' '}
          {value}{' '}
          <FormattedMessage id="DAYS" />
        </span>
        break;
      case 'weekly':
        if (value.length > 0) {
          info =
            <span>
              <FormattedMessage id="OCCURS_EVERY" />{' '}
              {value.constructor === Array && value.map((day, index) =>
                <span key={index}>

                  <span className="text-capitalize"> {WEEKDAYS_MAPPING[value[index]]}</span>
                  {WEEKDAYS_MAPPING[value[index + 1]] ? ',' : ""}
                </span>
              )}
            </span>
        }
        break;
      case 'monthly': info =
        <span>
          <FormattedMessage id="OCCURS_DAY" />
          {' '}
          {value}{' '}
          <FormattedMessage id="OF_EVERY_MONTH" />
        </span>
        break;
      default: break;
    }
    // console.log(typeof(info))
    // info += 
    // <span>
    //   <FormattedMessage id="EFFECTIVE" />{ }
    //   {this.props.values.start_date}{ }
    //   <FormattedMessage id="UNTIL" />{ }
    //   {this.props.values.end_date}{ }
    //   <FormattedMessage id="FROM" />{ }        
    //   {this.props.values.periodical_start_date} { }
    //   <FormattedMessage id="TO" />{ }
    //   {this.props.values.periodical_end_date} { }

    //   </span>
  }
  return info;

}
export const getFirstDayInstanceFromDate = (m, i) => {
  const momentObj = moment.isMoment(m) ? m.clone() : moment.utc(m);
  const dayIndex = parseInt(i);
  const day = momentObj.isoWeekday();
  if (day <= dayIndex) {
    return momentObj.isoWeekday(dayIndex).local().format('DD-MM-YYYY');
  } else {
    return momentObj.add(1, 'weeks').isoWeekday(dayIndex).local().format('DD-MM-YYYY');
  }
}

export const renderDetailsText = (recurrence_option, recurrence_option_value, start_date, locale = 'en') => {
  switch (recurrence_option) {
    case 'daily':
      return <p>
        <FormattedMessage id="OCCURS_ONCE_EVERY" />&nbsp;
        {recurrence_option_value.value || '1'}&nbsp;
        <FormattedMessage id="DAY_(S)" />&nbsp;
        {start_date && <>
          <FormattedMessage id="FROM" />&nbsp;
          <span className="text-capitalize">{moment.utc(start_date).local().format('DD-MM-YYYY')}</span>&nbsp;
          <FormattedMessage id="AT" />&nbsp;
          <span className="text-capitalize">
            {moment.utc(recurrence_option_value.startTime, 'H:mm:ss').local().format('H:mm:ss')}
          </span>&nbsp;
        </>
        }
        {locale === 'de' && <span>ein</span>}
      </p>
    case 'weekly':
      return <div>
        {orderBy(loKeys(recurrence_option_value)).map((el, index) => <p key={index}>
          <FormattedMessage id="OCCURS_EVERY" />&nbsp;
          <FormattedMessage id={WEEKDAYS_MAPPING[el.split('_')[1]].toUpperCase()} />&nbsp;
          {start_date && <>
            <FormattedMessage id="FROM" />&nbsp;
          <span className="text-capitalize">{getFirstDayInstanceFromDate(start_date, el.split('_')[1])}&nbsp;</span>

            <FormattedMessage id="AT" />&nbsp;
          <span className="text-capitalize">
              {moment.utc(recurrence_option_value[el].startTime, 'H:mm:ss').local().format('H:mm:ss')}
            </span>&nbsp;
          {/* <FormattedMessage id="TO" />&nbsp;
          <span className="text-capitalize">{recurrence_option_value[el].endTime}</span> */}
          </>}
          {locale === 'de' && <span>ein</span>}
        </p>)}
      </div>
    case 'monthly':
      let dayLocale = moment().date(parseInt(recurrence_option_value.value) || 1);
      dayLocale.locale(locale);
      return <p>
        <FormattedMessage id="OCCURS_ON" />&nbsp;
        {dayLocale.format('Do')}&nbsp;
        <FormattedMessage id="OF_EVERY_MONTH_FROM" />&nbsp;
        {start_date && <>
          <FormattedMessage id="FROM" />&nbsp;
          <span className="text-capitalize">{moment.utc(start_date).local().format('DD-MM-YYYY')}</span>&nbsp;
          <FormattedMessage id="AT" />&nbsp;
          <span className="text-capitalize">
            {moment.utc(recurrence_option_value.startTime, 'H:mm:ss').local().format('H:mm:ss')}
          </span>&nbsp;
        </>}
        {locale === 'de' && <span>ein</span>}
      </p>
    default:
      return null;
  }
}

export const findPeriodicStartDate = (day, start_date, end_date, validDays) => {
  day = parseInt(day)
  if (!start_date) {
    return;
  }
  let startDate = moment(Object.assign({}, start_date));
  let startDiff = (day - startDate.day()) % 7;
  if (startDiff < 0) {
    startDiff += 7;
  }
  startDate = moment(startDate.add(startDiff, 'days').format());
  //periodical start should be less then endDate date
  if (startDate.isSameOrBefore(moment(end_date))) {
    startDate.set({ h: 0, m: 0 });
    day = day.toString()
    validDays.push(day)
  }
  else {
    return validDays;
  }
  return validDays;
}