import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { NavLink } from "react-router-dom";
import NAVIGATION_ITEMS from "./NavItems";
import "./SideNav.scss";
import CommunicationToggle from "../CommunicationToggle/CommunicationToggle";
import EntitlementsContext from '../../../contexts/EntitlementsContext';
import { entitlements } from '../../../constants/Entitlements';

/*
  SideNav component is the sidebar displayed in the application. Sidebar contains the options to navigate to the following pages:
  Overview, create-alert, category, function category, system function mapping, api validator. The items in sidebar are disabled
  if the user is not authorised to view a particular item in sidebar.
*/
export default class SideNav extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  // componentDidUpdate(prevProps) {
  //   if (this.context.entitlements && this.context.entitlements.includes(entitlements['componentsCreation'])) {
  //     this.setState({ enableDataEntry: true, onLoad: false })

  //   }
  // }
  isLinkDisabled = (type) => {
    if (type && this.context.entitlements) {
      if (type === 'newAlert')
        return !entitlements['componentsCreation'].includes(this.context.entitlements);
      if (type === 'apiValidator')
        return !entitlements['apiValidator'].includes(this.context.entitlements);
        if (type === 'email')
        return !entitlements['email'].includes(this.context.entitlements);
    }
    return false;
  }
  generateNavItems = (type) => {
    /* Function creates an array of items to be displayed in the sidebar. These items are the key-value pairs of Navigation_items
    object */
    let navItems = [];
    NAVIGATION_ITEMS[type].forEach(item => {
      navItems.push(
        <FormattedMessage
          id={item.translation}
          defaultMessage={item.text}>
          {(msg) => (
            <NavLink
              to={item.url}
              exact
              activeClassName="active"
              key={item.id}
              className={`nav-item fs-14 text-capitalize ${this.isLinkDisabled(item.type) ? 'disabled-link' : ''}`}
              title={msg}
              onClick={(e) => {
                if (this.isLinkDisabled(item.type))
                  e.preventDefault();
              }}
            >
              <img
                src={require(`./../../../assets/icons/${item.image}`).default}
                alt={item.text}
                className={item.class ? item.class : 'pr-16'}
              />
              <span className="text">{msg}</span>
            </NavLink>
          )}
        </FormattedMessage>
      );
    });
    return navItems;
  }
  handleClick = (e, item) => {
    /* 
    Function handles the click on items in sidebar. The default behaviour of the click is navigation to the associated url.
     Api-validator page is enabled only for the users with entitlement TestSuiteAccess and CreateAlert page is enabled only for
     the users with entitlement DataEntry. In these unauthorised cases, the default behaviour is prevented. 
     */
    // if (item.type === 'apiValidator')
    //   e.preventDefault()
    // if (item.type === 'newAlert')
    //   e.preventDefault()
  }
  render(props) {
    return (
      <div className="sideNav-container d-flex flex-column">
        <div className={`hamburger ${this.props.isOpen ? "open" : "close-nav"}`} onClick={this.props.toggleMenu}>
          {/* <div className={`greetings  d - inline - flex flex - column text - left mobile - only ${this.props.isOpen ? "open" : "close-nav"}`}>
            <span className="text fs-11 font-blue-green">
              <FormattedMessage
                id="WELCOME"
                defaultMessage="Welcome"
                description="Welcome header"
              />
            </span>
            <span className="name fs-15">John Doe</span>
          </div> */}
          <div
            id="nav-icon1"
            className={this.props.isOpen ? "open" : "close-nav"}
          >
            <span />
            <span />
            <span />
          </div>
        </div>
        <ul
          className={
            this.props.isOpen
              ? "sideNav navbar-nav side-nav-width open"
              : "sideNav navbar-nav side-nav-close-width closed"
          }
        >
          <hr className="mobile-only mt-1" />
          <div className="toggle"><CommunicationToggle></CommunicationToggle></div>
          {this.generateNavItems("user")}
          {/* <hr /> */}
          {this.generateNavItems("admin")}
        </ul>
      </div>
    );
  }
}
SideNav.contextType = EntitlementsContext;
