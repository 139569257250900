import { Formik } from "formik";
import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { FormattedMessage } from "react-intl";
import * as Yup from "yup";

import Delete from "../../assets/icons/Delete_3.svg";
import Plus from "../../assets/icons/Plus.svg";
import Close from "../../assets/icons/Plus_1.svg";
import AddNewItemForm from "../../components/AddNewItemForm/AddNewItemForm";
import DeleteConfirmation from "../../components/DeleteConfirmation/DeleteConfirmation";
import ApiPath from "../../constants/ApiPath";
import { ALLOWED_TEXT } from "../../constants/Constants";
import axiosInstance from "../../utlities/Interceptor";
import { customTableHeader } from "../List/TableFunctions";
import EntitlementsContext from '../../contexts/EntitlementsContext';
import { entitlements } from '../../constants/Entitlements';
import "./Systems.scss";

export const columns = [
  {
    dataField: "name",
    text: "SYSTEM",
    headerFormatter: (column, colIndex, { sortElement, filterElement }) =>
      customTableHeader(column, colIndex, { sortElement, filterElement })
  },
  {
    dataField: "actions",
    isDummyField: true,
    text: "",
    formatter: (cellContent, row) => {
      return (
        <img src={Delete} alt="delete" className="delete" />
      );
    }
  }
];
/*
  Systems component lets the users to view, create and delete systems. Any user with entitlement startAuthorisation can
  view the page. Only the users with componentsCreation can create or delete systems
*/
export default class Systems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      systemList: [],
      isLoading: true,
      isDataSaved: false,
      popupActive: false,
      values: {
        item_name: ""
      },
      showDeleteModal: false,
      rowData: [],
    };
    this.formik = React.createRef();
    Yup.addMethod(Yup.string, "noDuplicate", this.noDuplicate);
  }
  componentDidMount() {
    axiosInstance.get(ApiPath.system).then(res => {
      let systemList = []
      res.data.forEach(element => {
        systemList.push({name: element})
      });
      systemList.sort(function (a, b) {
        return a.name.localeCompare(b.name);
      });
      this.setState({
        systemList: systemList,
        isLoading: false
      });
    });
  }
  noDuplicate(ref, msg) {
    // test function returns false if new value is a duplicate
    return this.test('noDuplicate', msg, function (value) {
      if (value) {
        let uppercaseValue = value.toString().toUpperCase();
        let check = this.resolve(ref).find(
          item => item.name === uppercaseValue
        );
        if (check) return false;
        return true;
      }
      return true;
    })
  }
  submitValues = (data) => {
  // Submit the Form of system creation. Function calls the post api.
    this.setState({
      isSubmitting: true
    });
    let fd = { name: data.item_name.trim().toUpperCase() };
    axiosInstance.post(ApiPath.system, fd).then(res => {
      this.newRow(fd);
      if (this.formik.current) {
        this.formik.current.resetForm();
      }
      this.setState({
        isSubmitting: false,
        popupActive: false,
      });
    })
      .catch(error => {
        this.setState({
          isSubmitting: false,
          popupActive: false,
        });
      });
  }

  newRow = (data) => {
    // Push the new system into the array and sorts the array in alphabetical order
    this.setState(prevState => ({
      systemList: [data, ...prevState.systemList]
    }));
    this.state.systemList.sort(function (a, b) {
      return a.name.localeCompare(b.name);
    });
  }

  deleteRow = () => {
    // Delete a category. Functions the delete api by appending the system name in the api url
    this.setState({
      isDeleting: true
    });
    axiosInstance.delete(`${ApiPath.system}`, {data: {name: this.state.rowData.name}})
      .then(res => {
        this.setState({
          isDeleting: false,
          showDeleteModal: false,
        });
        if (res.status === 200) {
          const filteredItems = this.state.systemList.filter(
            item => item.name !== this.state.rowData.name
          );
          this.setState({
            systemList: [...filteredItems]
          });
        }
      })
      .catch(error => {
        this.setState({
          isDeleting: false,
          showDeleteModal: false,
        });
      });
  }

  toggleDeleteModal = () => {
    this.setState({
      showDeleteModal: !this.state.showDeleteModal
    });
  }

  deleteItem(rowData) {
    // Enables the delete functionality only for users with entitlement for components creation
    if (this.context.entitlements.length > 0 && entitlements['componentsCreation'].includes(this.context.entitlements)) {
      this.setState({
        showDeleteModal: true,
        rowData: rowData
      });
    }
  }

  popupHandler = () => {
    this.setState({ popupActive: !this.state.popupActive });
  };
  findClasses = (row, rowIndex) => {
    // find the style classes according to the status and entitlement
    let classes = "custom-row-class ";
    if (!!row.created_by) {
      classes += "upcoming";
    }
    if (this.context.entitlements.length > 0 && entitlements['componentsCreation'].includes(this.context.entitlements)) {
      classes += ' enable';
    } else {
      classes += ' disable';
    }
    return classes;
  };

  render() {
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        if ((e.target.classList.contains('delete') || (e.target.parentElement && e.target.parentElement.classList.contains('delete'))))
          this.deleteItem(row)
      }
    };
    const validationSchema = Yup.object({
      item_name: Yup.string("enter name")
        .matches(ALLOWED_TEXT, { message: 'NO_SPECIAL_CHARA', excludeEmptyString: true })
        .noDuplicate(this.state.systemList, "NO_DUPLICATE_ALLOWED")
        .required("REQUIRED")
    });
    return (
      <div className="systems page-padding">
        <DeleteConfirmation isOpen={this.state.showDeleteModal} toggle={this.toggleDeleteModal} deleteRow={this.deleteRow}
          isDeleting={this.state.isDeleting} deleteText="YOU_ARE_ABOUT_TO_DELETE_A_SYSTEM_ARE_YOU_SURE"></DeleteConfirmation>
        <div className="mb-4 d-flex mt-4 w-100 px-3 heading-text-div">
          <span className="heading text-capitalize fs-16 page-header">
            <FormattedMessage id="SYSTEMS" defaultMessage="systems" />
            <button className="plus-btn" onClick={this.popupHandler}>
              <img src={Plus} alt="Plus" />
            </button>
          </span>
        </div>
        <hr />
        <div
          className={"add-new-item" + (this.state.popupActive ? " active" : "")}
        >
          <span className="heading text-capitalize fs-16 mobile-only popup-header">
            <FormattedMessage id="SYSTEMS" defaultMessage="systems" />
            <button onClick={this.popupHandler}>
              <img src={Close} alt="close" />
            </button>
          </span>
          <Formik
            ref={this.formik}
            render={props => (
              <AddNewItemForm
                {...props}
                isDataSaved={this.state.isSubmitting}
                textLabel="NAME_OF_NEW_SYSTEM"
                saveBtnText="NEW_SYSTEM"
                plusIcon={true}
              />
            )}
            initialValues={this.state.values}
            validationSchema={validationSchema}
            onSubmit={this.submitValues}
          />
        </div>
        <div className="w-100 px-3 single-column-list clearfix">
          <BootstrapTable
            keyField="name"
            data={this.state.systemList}
            columns={columns}
            bordered={false}
            bootstrap4={true}
            rowClasses={this.findClasses}
            rowEvents={rowEvents}
          />
          <div className="d-flex justify-content-center flex-column">
            {this.state.systemList.length === 0 && !this.state.isLoading && (
              <div className="text-center">
                <span className="load-more text-capitalize pointer btn text-center">
                  <FormattedMessage id="NO_DATA" />
                </span>
              </div>
            )}
            {this.state.isLoading && (
              <div className="text-center">
                <span className="load-more text-capitalize pointer btn text-center">
                  <FormattedMessage id="LOADING" />
                  {this.state.isLoading && <div className="lds-dual-ring" />}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
Systems.contextType = EntitlementsContext;