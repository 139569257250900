import { Formik } from "formik";
import * as moment from "moment";
import * as queryString from "query-string";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Redirect } from "react-router";
import AlertDataContext from "../../contexts/AlertDataContext";
import axiosInstance from "../../utlities/Interceptor";
import ApiPath from "../../constants/ApiPath";
import { ALERT_TYPE, DATE_TIME_FORMAT } from "../../constants/Constants";
import "./CreateAlert.scss";
import Form from "./Form/Form";
import { validationSchema } from './ValidationSchema';
import WarningPopup from '../../components/WarningPopup/WarningPopup';
import { entitlements } from '../../constants/Entitlements';
import EntitlementsContext from "../../contexts/EntitlementsContext";

/* 
  Create alert page is the component for creating or editing an alert. It also has option to delete an existing alert.
  This page is visible only to users with entitlement ComponentsCreation
*/

class CreateAlert extends Component {
  constructor(props) {
    super(props);
    this.formik = React.createRef();
    this.state = {
      toList: false,
      isSubmitting: false,
      editID: -1,
      alertData: {},
      showWarningPopup: false,
      onLoad: true,
      isAuthorized: false,
      values: {
        type: ALERT_TYPE.information.value,
        system: "",
        publication_date: moment().add(1, 'm'),
        start_date: "",
        end_date: "",
        reason: "",
        message_en: "",
        message_de: "",
        is_communication_possible: false,
        is_periodical_maintenance: false,
        editID: -1,
        recurrence_option: '',
        recurrence_option_value: {},
        weekly_recurrence_option_values: {},
        recurrence_data: '',
        valid_days: [],
        old_start_date: ""
      }
    };
  }
  componentWillMount() {
    let alertData = this.context.alertData;
    let setContextAlertData = this.context.setData;
    /* In case of edit or duplicate, the url contains the id of the specific alert. This id is set as state variable - editID 
    For create alert - editID is set as -1 by default
    */
    let parsed = queryString.parse(this.props.location.search);
    if ("edit" in parsed) {
      let state = this.state.values;
      state.editID = parsed.edit;
      this.setState({
        values: state
      });
      this.setState({ editID: parsed.edit })
    }
    if (
      this.props.location.search.length !== 0 &&
      !alertData.hasOwnProperty("id")
    ) {
      this.cancel();
    } else if (
      this.props.location.search.length > 0 &&
      alertData.hasOwnProperty("id")
    ) {
      this.fillData(alertData);
    } else {
      setContextAlertData();
    }

  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.onRouteChanged();
    }
  }
  checkEntitlements = (entitlementsArray) => {
    // check whether the user has entitlement required to create a new alert
    if (this.state.onLoad && entitlementsArray && entitlementsArray.length > 0) {
      if (!entitlements['newAlert'].includes(entitlementsArray)) {
        this.setState({ showWarningPopup: true, onLoad: false, isAuthorized: false })
      }
      else {
        this.setState({ isAuthorized: true, onLoad: false })
      }
    }
  }
  cancel = () => {
    this.setState({
      toList: true
    });
  }

  fillData = (data) => {
    // In case of edit or duplicate, populate the state variable values with the data corresponding to the editID
    this.setState({
      values: { ...data, recurrence_data: JSON.stringify(data.recurrence_option_value) },
      is_communication_possible: data.is_communication_possible
    });
  }

  submitValues = (data) => {
    // function to submit the Form data
    //in case of create alert, update publication date with current date and time
    let currentDate = new moment();
    if (moment(data.publication_date).isBefore(currentDate)) {
      data.publication_date = currentDate;
    }

    // if (this.state.editID === -1) {
    //   data.publication_date = moment().add(1, 'm');
    // }
    this.setState({
      isSubmitting: true,
    });
    // convert the moment object in data to string - as per the format as required in backend
    let formData = this.convertMomentToString(data);
    let apiPath =
      this.state.editID !== -1
        ? `${ApiPath.list}`
        : ApiPath.list;
    let type = "post";
    if (this.state.editID === -1) {
      Object.assign(formData);
      delete formData["id"]
      delete formData["user_id"]
    } else {
      formData.maintenance_id = formData.id;
      delete formData["id"];
      delete formData["user_id"];
      type = "put";
    }
    this.postData(formData, apiPath, type);

  }
  convertMomentToString = (data) => {
    // converts moment object to string
    let value = {};
    value = data;
    Object.keys(value).forEach(element => {
      if (moment.isMoment(value[element])) {
        value[element] = moment(value[element], DATE_TIME_FORMAT)
          .utc()
          .format()
          .toString();
      }
    });
    return JSON.parse(JSON.stringify(value));
  }

  // Function saves the alert form data and calls the post api with the saved data
  postData = (data, apiPath, type) => {
    Object.keys(data).forEach(
      key =>
        (data[key] == null || data[key] === "" || data[key] === "-") &&
        delete data[key]
    );
    axiosInstance[type](apiPath, data)
      .then(res => {
        this.setState({
          isSubmitting: false,
          toList: true
        });
      })
      .catch(error => {
        this.setState({
          isSubmitting: false
        });
      });
  }
  onRouteChanged = () => {
    // if the page is refreshed, editID is reset to -1 so as to create a new alert
    this.setState({
      editID: -1
    });
  }
  setDates = (momentObj, field) => {
    // set the date fields with moment object
    let items = this.state.values;
    items[field] = momentObj;
    this.setState({ values: items })
  }
  closeWarningPopup = () => {
    // show a popup if the user is unauthorised to view the file and redirect user to overview page
    this.setState({ showWarningPopup: false });
    this.props.history.push("/list")
  }
  setWarning() {
    // function to set the variable to show the warning popup
    this.setState({ showWarningPopup: true })
  }

  render() {
    if (this.state.toList === true) {
      return <Redirect push to="/list" />;
    }
    return (
      <EntitlementsContext.Consumer>
        {({ entitlements }) => (
          <div>
            {this.state.onLoad ? this.checkEntitlements(entitlements) : ''}
            <WarningPopup isOpen={this.state.showWarningPopup} toggle={this.closeWarningPopup} message="YOU_ARE_NOT_AUTHORIZED" />
            {this.state.isAuthorized ?
              <AlertDataContext.Consumer>
                {({ alertData, setData, endEvent, endData, showEndEventPopup }) => (
                  <div className="create-alert  text-capitalize fs-16 mr-1">

                    <div className="d-flex w-100 page-header">
                      <div className="pl-3 my-4 d-flex flex-grow-1">
                        <FormattedMessage id={(this.state.editID === -1) ? "CREATE_ALERT" : "EDIT_ALERT"} defaultMessage="create/edit alert" />
                      </div>
                    </div>
                    <hr />
                    <div>
                      <Formik
                        ref={this.formik}
                        render={props => (
                          <Form
                            {...props}
                            isDataSaved={this.state.isSubmitting}
                            setDates={this.setDates}
                            endEvent={endEvent}
                          />
                        )}
                        initialValues={this.state.values}
                        validationSchema={validationSchema}
                        onSubmit={this.submitValues}
                      />

                    </div>
                  </div>
                )}

              </AlertDataContext.Consumer> : ''}
          </div>
        )}
      </EntitlementsContext.Consumer>
    );
  }
}

CreateAlert.contextType = AlertDataContext;

export default CreateAlert;
