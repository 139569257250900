import { Formik } from 'formik';
import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { FormattedMessage } from 'react-intl';
import * as Yup from "yup";

import Delete from '../../assets/icons/Delete_3.svg';
import Plus from "../../assets/icons/Plus.svg";
import Close from "../../assets/icons/Plus_1.svg";
import AddNewItemForm from '../../components/AddNewItemForm/AddNewItemForm';
import DeleteConfirmation from '../../components/DeleteConfirmation/DeleteConfirmation';
import ApiPath from '../../constants/ApiPath';
import { ALLOWED_TEXT } from '../../constants/Constants';
import { customTableHeader } from '../../utlities/BootstrapTableFunctions';
import axiosInstance from '../../utlities/Interceptor';
import EntitlementsContext from '../../contexts/EntitlementsContext';
import { entitlements } from '../../constants/Entitlements';
import './Category.scss';

export const columns = [
  {
    dataField: 'name',
    text: 'CATEGORY',
    headerFormatter: (column, colIndex, { sortElement, filterElement }) => customTableHeader(column, colIndex, { sortElement, filterElement })
  }, {
    dataField: 'actions',
    isDummyField: true,
    text: '',
    formatter: (cellContent, row) => {
      return (
          <span className="d-flex justify-content-center pointer delete">
            <img src={Delete} alt="" />
          </span>
      );
    }
  }
];
/*
  Category component lets the users to view, create and delete categories. Any user with entitlement startAuthorisation can
  view the page. Only the users with componentsCreation can create or delete categories
*/
export default class Category extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryList: [],
      isLoading: true,
      isDataSaved: false,
      popupActive: false,
      onLoad: true,
      values: {
        item_name: "",
      }
    };
    this.formik = React.createRef();
    Yup.addMethod(Yup.string, "noDuplicate", this.noDuplicate);
  }
  componentDidMount() {
    axiosInstance.get(ApiPath.category)
        .then(res => {
          let categoryList = [];
          res.data.forEach(element => {
            categoryList.push({name: element})
          });
          categoryList.sort(function (a, b) {
            return a.name.localeCompare(b.name);
          });
          this.setState({
            categoryList: categoryList,
            isLoading: false
          });
        }).catch(error => {
      console.log(error)
    })
  }
  noDuplicate(ref, msg) {
    // test function returns false if new value is a duplicate
    return this.test('noDuplicate', msg, function (value) {
      if (value) {
        let uppercaseValue = value.toString().toUpperCase();
        let check = this.resolve(ref).find((item) => item.name === uppercaseValue)
        if (check)
          return false;
        return true;
      }
      return true;
    })
  }

  submitValues = (data) => {
    // Submit the Form of category creation. Function calls the post api.
    this.setState({
      isSubmitting: true
    });
    let fd = { name: (data.item_name).trim().toUpperCase() }
    axiosInstance.post(ApiPath.category, fd)
        .then(res => {
          const timestamp = Date.now();
          fd = { ...fd, id: timestamp }
          this.newRow(fd);
          if (this.formik.current) {
            this.formik.current.resetForm();
          }
          this.setState({
            isSubmitting: false,
            popupActive: false,
          });
        })
        .catch(error => {
          this.setState({
            isSubmitting: false,
            popupActive: false,
          });
        });
  }

  newRow = (data) => {
    // Push the new category into the array and sorts the array in alphabetical order
    this.setState(prevState => ({
      categoryList: [data, ...prevState.categoryList]
    }))
    this.state.categoryList.sort(function (a, b) {
      return a.name.localeCompare(b.name);
    });
    this.setState({
      isLoading: false
    });
  }

  deleteRow = () => {
    // Delete a category. Functions the delete api by appending the category name in the api url
    this.setState({
      isDeleting: true
    });
    axiosInstance.delete(`${ApiPath.category}`, {data: {name: this.state.rowData.name}})
        .then(res => {
          if (res.status === 200) {
            const filteredItems = this.state.categoryList.filter(item => item.name !== this.state.rowData.name)
            this.setState({
              categoryList: [...filteredItems]
            })
          }
          this.setState({
            isDeleting: false,
            showDeleteModal: false,
          });
        })
        .catch(error => {
          this.setState({
            isDeleting: false,
            showDeleteModal: false,
          });
        });
  }

  deleteItem = (rowData) => {
    // Enables the delete functionality only for users with entitlement for components creation
    
    if (this.context.entitlements.length > 0 && entitlements['componentsCreation'].includes(this.context.entitlements)) {
      this.setState({
      showDeleteModal: true,
      rowData: rowData
    });
    }
  }

  toggleDeleteModal = () => {
    // function that enables to toggle the delete modal 
    this.setState({
      showDeleteModal: !this.state.showDeleteModal
    });
  }
  popupHandler = () => {
    this.setState({ popupActive: !this.state.popupActive });
  };
  findClasses = (row, rowIndex) => {
    // find the style classes according to the status, entitlement and category name
    let classes = "custom-row-class ";
    if (!!row.created_by) {
      classes += "upcoming ";
    }
    if (row.name === 'CAR' || row.name === 'CARS') {
      classes += 'car-class';
    }
    else if (row.name === 'TRUCK' || row.name === 'TRUCKS') {
      classes += 'truck-class';
    }
    else if (row.name === 'BUS' || row.name === 'BUSES') {
      classes += 'bus-class';
    }
    else if (row.name === 'VAN' || row.name === 'VANS') {
      classes += 'van-class';
    }
    else {
      classes += 'default-class';
    }
    if (this.context.entitlements.length > 0 && entitlements['componentsCreation'].includes(this.context.entitlements)) {
      classes += ' enable';
    }
    else {
      classes += ' disable';
    }
    return classes;
  }

  render() {
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        if ((e.target.classList.contains('delete') || (e.target.parentElement && e.target.parentElement.classList.contains('delete'))))
          this.deleteItem(row)
      }
    }
    const validationSchema = Yup.object({
      item_name: Yup.string("enter name")
          .matches(ALLOWED_TEXT, { message: 'NO_SPECIAL_CHARA', excludeEmptyString: true })
          .noDuplicate(this.state.categoryList, 'NO_DUPLICATE_ALLOWED')
          .required("REQUIRED")
    });
    return (
        <div className="category page-padding">
          <DeleteConfirmation isOpen={this.state.showDeleteModal} toggle={this.toggleDeleteModal}
                              deleteRow={this.deleteRow} isDeleting={this.state.isDeleting} deleteText="YOU_ARE_ABOUT_TO_DELETE_A_CATEGORY_ARE_YOU_SURE"></DeleteConfirmation>
          <div className="mb-4 d-flex mt-4 w-100 px-3 heading-text-div">
          <span className="heading text-capitalize fs-16 page-header">
            <FormattedMessage id="CATEGORY"
                              defaultMessage="category" />
            <button className="plus-btn" onClick={this.popupHandler}>
              <img src={Plus} alt="Plus" />
            </button>
          </span>
          </div>
          <hr />
          <div className={"add-new-item" + (this.state.popupActive ? " active" : "")}>
          <span className="heading text-capitalize fs-16 mobile-only popup-header">
            <FormattedMessage id="CATEGORY" defaultMessage="CATEGORY" />
            <button onClick={this.popupHandler}>
              <img src={Close} alt="close" />
            </button>
          </span>
            <Formik
                ref={this.formik}
                render={props =>
                    <AddNewItemForm {...props} isDataSaved={this.state.isSubmitting}
                                    textLabel="NAME_OF_NEW_CATEGORY"
                                    saveBtnText="NEW_CATEGORY"
                                    plusIcon={true}
                    />
                }
                initialValues={this.state.values}
                validationSchema={validationSchema}
                onSubmit={this.submitValues}
            />
          </div>
          <div className="w-100 px-3 single-column-list clearfix">
            <BootstrapTable keyField='id'
                            data={this.state.categoryList}
                            columns={columns}
                            bordered={false} bootstrap4={true}
                            rowClasses={this.findClasses}
                            rowEvents={rowEvents}
            />
            {(this.state.categoryList.length === 0 && !this.state.isLoading) &&
            <div className="text-center">
              <span className="load-more text-capitalize pointer btn text-center" >
                <FormattedMessage id="NO_DATA" />
              </span>
            </div>
            }
            {(this.state.isLoading) &&
            <div className="text-center">
              <span className="load-more text-capitalize pointer btn text-center" >
                <FormattedMessage id="LOADING" />
                {this.state.isLoading && <div className="lds-dual-ring"></div>}
              </span>
            </div>
            }
          </div>
        </div>
    )
  }
}
Category.contextType = EntitlementsContext;
