import React, { Component } from "react";
import { FormattedMessage } from 'react-intl';
import { withRouter } from "react-router";
import { Button, Popover, PopoverBody } from 'reactstrap';
import Duplicate from "./../../../assets/icons/Duplicate.svg";
import Edit from "./../../../assets/icons/Edit.svg";
import Delete from "./../../../assets/icons/Delete.svg";
import EndEvent from "./../../../assets/icons/end-event.png"

import More from "./../../../assets/icons/More_button.svg";
import MoreClicked from "./../../../assets/icons/More_button_1.svg";
import './ActionsButton.scss';
import AlertDataContext from "../../../contexts/AlertDataContext";
import * as CONSTANTS from './../../../constants/Constants';
import EntitlementsContext from "../../../contexts/EntitlementsContext";
import { entitlements } from '../../../constants/Entitlements';
import WarningPopup from '../../../components/WarningPopup/WarningPopup'
import * as moment from 'moment';


class ActionsButton extends Component {
  constructor(props) {
    super(props);
    this.toggleActions = this.toggleActions.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.wrapperRef = React.createRef();
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.state = {
      popoverOpen: false,
      endEventWarning: false
    };
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  toggleActions(event) {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }

  closePopup(event) {
    if ((this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) && (event.target.classList) && !(event.target.classList.contains('action') || (event.target.parentElement && event.target.parentElement.classList.contains('action')))) {
      this.setState({
        popoverOpen: false
      });
    }
  }
  gotoCreate = (type, setData, setDelete) => {
    setData(this.props.rowData)
    if (type === 'delete') {
      // setDelete(true, this.props.rowData.id)
      setDelete(true, this.props.rowData)
    } else {
      this.props.history.push(`/alert?${type}=${this.props.rowData.id}`);
    }
  }
  componentDidMount() {
    document.addEventListener('mousedown', this.closePopup);
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.closePopup);
  }
  toggleDeleteModal = () => {
    this.setState({ showDeleteModal: false })
  }
  endEvent = (endData, endEvent) => {
    return (e) => {
      if (moment.utc().isBefore(moment.utc(endData.start_date)))
        this.setState({ endEventWarning: true })
      else
        endEvent(true, endData);
    }
  }
  closeEndEventWarningPopup = () => {
    this.setState({
      endEventWarning: false
    })
  }

  // disabled={} 
  render() {
    const { rowData } = this.props;
    const isActiveEmergency = rowData.id && (rowData.id.includes('emergency_entry') && !rowData.is_expired);
    return (
      <AlertDataContext.Consumer>
        {({ setData, setDelete, endEvent }) => (
          <div style={{ minHeight: '24px' }}>
            <WarningPopup
              isOpen={this.state.endEventWarning}
              toggle={this.closeEndEventWarningPopup}
              message="END_EVENT_WARNING" />
            {entitlements['newAlert'].includes(this.context.entitlements) && !isActiveEmergency ? <div className="popup-more" ref={this.wrapperRef}>
              <Button
                id={"Popover" + this.props.rowData.id}
                className="popover-bttn"
                disabled={isActiveEmergency}
                color="link" style={{ padding: 0, textAlign: 'center' }}>
                <img src={this.state.popoverOpen ? MoreClicked : More} alt="Toggle actions menu" />
              </Button>
              <Popover
                trigger={isActiveEmergency ? null : "click"}
                placement="bottom"
                isOpen={this.state.popoverOpen}
                target={"Popover" + this.props.rowData.id}
                toggle={event => this.toggleActions(event)}
                hideArrow
                className="more-action"
              >
                <PopoverBody>
                  {(!this.props.rowData.is_expired && !isActiveEmergency) &&
                    <button className="btn btn-primary action edit text-capitalize" onClick={() => this.gotoCreate(CONSTANTS.MORE_ACTIONS.edit, setData)}>
                      <img src={Edit} alt="" className="pr-3" />
                      <FormattedMessage id="EDIT"
                        defaultMessage="edit" />
                    </button>
                  }
                  {!rowData.id.includes('emergency_entry') && <>
                    <button className="btn btn-primary action duplicate text-capitalize" onClick={() => this.gotoCreate(CONSTANTS.MORE_ACTIONS.duplicate, setData)}>
                      <img src={Duplicate} alt="" className="pr-3" />
                      <FormattedMessage id="DUPLICATE"
                        defaultMessage="duplicate" />
                    </button>
                  </>
                  }
                  {!(rowData.id.includes('emergency_entry') && !rowData.is_expired) && <>
                    <button className="btn btn-primary action delete text-capitalize" onClick={() => this.gotoCreate(CONSTANTS.MORE_ACTIONS.delete, setData, setDelete)}>
                      <img src={Delete} alt="" className="pr-3" />
                      <FormattedMessage id="DELETE"
                        defaultMessage="delete" />
                    </button>
                  </>
                  }
                  {rowData.type === 'i' && rowData.end_date === '-' && <>
                    <button
                      className="btn btn-primary action end-event text-capitalize"
                      onClick={this.endEvent(this.props.rowData, endEvent)}
                    >
                      <img src={EndEvent} alt="" className="pr-3 end-event" />
                      <FormattedMessage id="END_EVENT"
                        defaultMessage="delete" />
                    </button>
                  </>
                  }
                </PopoverBody>
              </Popover>
            </div> : null}
          </div>
        )}
      </AlertDataContext.Consumer>
    )
  }
}

export default withRouter(ActionsButton);
ActionsButton.contextType = EntitlementsContext;