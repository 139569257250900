import './polyfill';
import React from "react";
import ReactDOM from "react-dom";
import { addLocaleData, IntlProvider } from "react-intl";
import locale_de from "react-intl/locale-data/de";
import locale_en from "react-intl/locale-data/en";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { withRouter } from 'react-router-dom';

import * as CONSTANTS from "./constants/Constants";
import "./index.css";
import Routing from "./routes/Routing";
import * as serviceWorker from "./serviceWorker";
import "./styles/main.scss";
import messages_de from "./translations/de.json";
import messages_en from "./translations/en.json";
import Header from './pages/Header/Header';
import UserInfoContext from './contexts/UserInfoContext';
import CommunicationPossibleContext from './contexts/CommunicationPossibleContext';
import EntitlementsContext from './contexts/EntitlementsContext';
import CheckToken from './CheckToken';
import axiosInstance from "./utlities/Interceptor";
import ApiPath from "./constants/ApiPath";
import { logout } from './utlities/Utils'

import 'moment/locale/de';
export const themes = {
  light: {
    foreground: "#000000",
    background: "#eeeeee"
  },
  dark: {
    foreground: "#ffffff",
    background: "#222222"
  }
};

addLocaleData([...locale_en, ...locale_de]);
const messages = {
  de: messages_de,
  en: messages_en
};
export const HeaderContext = React.createContext({
  theme: themes.dark,
  toggleTheme: () => { },
  language: CONSTANTS.LANGUAGES.EN,
  setLanguage: () => { },
  isMenuOpen: false,
  toggleMenu: () => { }
});
class ThemeTogglerButton extends React.Component {
  render() {
    return (
      <HeaderContext.Consumer>
        {({ theme, toggleTheme }) => (
          <button
            onClick={toggleTheme}
            style={{ backgroundColor: theme.background }}
          >
            Toggle Theme
              </button>
        )}
      </HeaderContext.Consumer>
    );
  }
}
export default ThemeTogglerButton;

class App extends React.Component {
  constructor(props) {
    super(props);

    this.toggleTheme = () => {
      this.setState(state => ({
        theme: state.theme === themes.dark ? themes.light : themes.dark
      }));
    };
    this.toggleMenu = () => {
      this.setState(state => ({
        isMenuOpen: !state.isMenuOpen
      }));
    };
    this.setLanguage = code => {
      this.setState(state => ({
        language: CONSTANTS.LANGUAGES[code]
      }));
    };

    this.setUserInfo = (data) => {
      let state = this.state;
      state.userInfo = data;
      this.setState({ state })
    }
    this.setCommunication = (data) => {
      let state = this.state;
      state.isCommunicationPossible = data;
      this.setState({ state })
    }
    this.setWarning = (val) => {
      this.setState({ showWarningPopup: !this.state.showWarningPopup })
    }
    this.toggleComm = (comm) => {
      this.setState({ isCommunicationPossible: !this.state.isCommunicationPossible })
    }
    this.setEntitlements = (data) => {
      let state = this.state;
      state.entitlements = data;
      this.setState({ state })
    }
    // State also contains the updater function so it will
    // be passed down into the context provider
    this.state = {
      theme: themes.light,
      toggleTheme: this.toggleTheme,
      language: CONSTANTS.LANGUAGES.EN,
      setLanguage: this.setLanguage,
      isMenuOpen: true,
      toggleMenu: this.toggleMenu,
      isCommunicationPossible: true,
      setComm: this.setComm,
      setWarning: this.setWarning,
      toggleComm: this.toggleComm,
      setUserInfo: this.setUserInfo,
      setCommunication: this.setCommunication,
      setEntitlements: this.setEntitlements,
      entitlements: [],
      tokenValid: false,
    };
    this.onClickApp = this.onClickApp.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
//   getUserInfo = () => {
//     let config = { type: 'userInfo' }
//     axiosInstance.get(`${ApiPath.userInfo}`, config).then(res => {
//         this.setUserInfo(res.data.username.replace('Daimler_', ''));
//     }).catch(error => {
//         // logout();
//     })
// }

  getUserData = async () => {
    /*  Function calls the api to get entitlements of the user and sets in global state. For an unauthorised user, status code
    5 is returned and logout function is called
    */
   try {
     const response = await axiosInstance.get(ApiPath.userInfo);
     if (!response.data.is_active) {
       logout();
     } else {
       this.setEntitlements(response.data.payload);
       this.setUserInfo(response.data.user_id)
     }
   } catch (error) {
     if (error.response && error.response.data === 'WMS.Unknown') {
       alert('User does not have access to the application');
       logout();
     }
   }
  }

  componentDidMount() {
    // this.getUserInfo();
    this.getUserData();
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight
    });
    if (window.innerWidth < 768) {
      this.setState({
        isMenuOpen: false
      });
    } else {
      this.setState({
        isMenuOpen: false
      });
    }
  }

  onClickApp(e) {
    if (this.state.width < 768 && (e.target.classList.contains('nav-item') || (e.target.parentElement && e.target.parentElement.classList.contains('nav-item')))) {
      this.setState({
        isMenuOpen: false
      });
    }
  }

  render() {
    window.addEventListener('storage', function (e) {
      // listener added to persist localstorage across all tabs (issue in IE)
    });
    window.addEventListener("unhandledrejection", function (event) {
      console.warn("WARNING: Unhandled promise rejection. Reason: " + event.reason, event);
    });
    return (
      <div>
        <HeaderContext.Provider value={this.state}>
          <UserInfoContext.Provider value={this.state}>
            <CommunicationPossibleContext.Provider value={this.state}>
              <EntitlementsContext.Provider value={this.state}>
                <IntlProvider
                  locale={this.state.language.code}
                  messages={messages[this.state.language.code]}
                  textComponent={React.Fragment}
                >
                  {!this.state.tokenValid ? <CheckToken onTokenValid={() => {
                    this.setState({ tokenValid: true })
                  }} /> :
                    <div
                      className={window.location.pathname === '/login' || window.location.pathname === '/home' || window.location.pathname === '/loggingout' ? 'main-container login-page' : 'main-container'}
                      onClick={this.onClickApp}>
                      <ToastContainer
                        className='toast-container' toastClassName="custom-toast"
                        position="top-center"
                        autoClose={5000} hideProgressBar
                        newestOnTop closeOnClick
                        rtl={false} pauseOnVisibilityChange
                        draggable pauseOnHover
                      />
                      {window.location.pathname === '/login'
                        || window.location.pathname === '/home'
                        || window.location.pathname === '/loggingout' ? ''
                        : <Header {...this.props} />}
                      <Routing />
                    </div>
                   }
                </IntlProvider>
              </EntitlementsContext.Provider>
            </CommunicationPossibleContext.Provider>
          </UserInfoContext.Provider>
        </HeaderContext.Provider>
      </div>
    )
  }
}
const AppWithRouter = withRouter(App);
ReactDOM.render(
  <BrowserRouter>
    <AppWithRouter />
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

