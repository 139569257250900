import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import './DeleteConfirmation.scss';

/*  
  This component is a bootstrap modal which is opened if user tries to delete any item like category, system or 
  function category. Only on confirming in the popup, item is deleted by the application 
*/

export default class DeleteConfirmation extends Component {
  render() {
    return (
      <div className="delete-confirmation">
        <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} className="delete-modal" centered>
          <ModalHeader toggle={this.props.toggle}><FormattedMessage id="DELETE_CONFIRMATION" /></ModalHeader>
          <ModalBody>
            <FormattedMessage id={this.props.deleteText} />
          </ModalBody>
          <ModalFooter className="d-flex flex-wrap">
            <Button color="cancel" onClick={this.props.toggle} size="modal">
              <FormattedMessage id="CANCEL" defaultMessage="Cancel" />
            </Button>
            <Button color="primary" onClick={this.props.deleteRow} disabled={this.props.isDeleting} size="modal"><FormattedMessage id="DELETE" defaultMessage="Delete" />
              {this.props.isDeleting && <div className="lds-dual-ring white" />}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}
