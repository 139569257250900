import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, PopoverBody, UncontrolledPopover } from "reactstrap";
import * as FileSaver from "file-saver";

import ApiPath from "../../constants/ApiPath";
import axiosInstance from "../../utlities/Interceptor";
import EntitlementsContext from '../../contexts/EntitlementsContext';
import WarningPopup from '../../components/WarningPopup/WarningPopup';
import { entitlements } from '../../constants/Entitlements';
import { keydown } from "../../utlities/Utils";
import './ApiValidator.scss'

/*
  ApiValidator component validates the response of xentry-status api. It has option to upload XML request schema.
  User can view/download the response. This page is visible only to users with entitlement TestSuiteAccess
*/

export default class ApiValidator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      request: '',
      response: '',
      isSubmitted: false,
      isValid: true,
      isUploaded: false,
      showWarningPopup: false,
      onLoad: true,
      isAuthorized: false
    }
  }
  componentDidMount() {
    this.findAuth();
  }
  componentDidUpdate() {
    this.findAuth();
  }

  // Function save the request in state variable
  saveReq = (event) => {
    this.setState({ request: event.target.value, isUploaded: false })
  }

  /*
    Function call the api for api-validator with body as ascDataDocument='request'
    The obtained response is decoded using decodeURIComponent and + in response is replaced by %20 for proper
    display of the response
  */
  submitXml = () => {
    this.setState({ isSubmitting: true, response: '' })
    let fd = 'ascDataDocument=' + this.state.request
    axiosInstance.post(ApiPath.xmlRequest, fd, { type: 'api-validator' }).then(res => {
      this.setState({ isSubmitting: false })
      // do plus decoding in the response
      let decodedResponse = decodeURIComponent(res.data.replace(/\+/g, '%20'));
      this.setState({ status: res.status, response: decodedResponse })
    })
      .catch(error => {
        this.setState({
          isSubmitting: false,
          popupActive: false,
        });
      });
  }

  /*
    Function saves the uploaded file from target of input type file. Checks whether filename ends with .xsd or .xml.
    For other file types, isValid flag is set to false
  */
  uploadFile = (event) => {
    this.setState({ request: '', response: '' })
    const input = event.target;
    this.setState({ isUploaded: true })
    if ('files' in input && input.files.length > 0) {
      if ((!input.files[0].name.endsWith(".xml")) && (!input.files[0].name.endsWith(".xsd"))) {
        this.setState({ isValid: false })
        return false
      }
      this.placeFileContent(input.files[0])
    }
  }

  // Function read the content of the input file and store content in state variable
  placeFileContent = (file) => {
    this.setState({ isUploaded: true })
    this.readFileContent(file).then(content => {
      if (file.name.endsWith(".xml") || file.name.endsWith(".xsd")) {
        this.setState({ isValid: true })
      }
      this.setState({ request: content })
    }).catch(error => console.log(error))
  }

  // Function read the file content using FileReader library
  readFileContent = (file) => {
    const reader = new FileReader()
    return new Promise((resolve, reject) => {
      reader.onload = event => resolve(event.target.result)
      reader.onerror = error => reject(error)
      reader.readAsText(file)
    })
  }

  // Function download the response into a file using file-saver library
  downloadResponse = () => {
    let blob = new Blob([this.state.response], { type: "'application/xml'" });
    FileSaver.saveAs(blob, "response.xsd");
  }

  // Fuction to show a popup if the user is unauthorised to view the file and redirect user to overview page
  closeWarningPopup = () => {
    this.setWarning(false)
    this.props.history.push("/list")
  }

  // function to set the variable to show the warning popup
  setWarning(show) {
    this.setState({ showWarningPopup: show })
  }

  // Function check whether the user has the entitlement 'apiValidator'; if no, show the warning popup
  findAuth = () => {
    if (this.context && this.context.entitlements.length > 0 && this.state.onLoad) {
      if (!entitlements['apiValidator'].includes(this.context.entitlements)) {
        this.setWarning(true)
        this.setState({ onLoad: false, isAuthorized: false })
      }
      else {
        this.setState({ isAuthorized: true, onLoad: false })
      }
    }
  }
  render() {
    return (
      <div>
        <WarningPopup isOpen={this.state.showWarningPopup} toggle={this.closeWarningPopup} message="YOU_ARE_NOT_AUTHORIZED" />
        {this.state.isAuthorized ? <div className="api-validator-container">
          <div className="mb-4 d-flex mt-4 w-100 pl-3 heading-text-div">
            <span className="heading text-capitalize">
              <FormattedMessage id="API_VALIDATOR" defaultMessage="API validator" />
            </span>
          </div>
          <hr />
          <div className="w-100 pl-3 last-row-sticky">
            <span className="fs-12 mr-5">
              <span>
                <label htmlFor="myFile" className="btn btn-primary text-capitalize">
                  <FormattedMessage id="UPLOAD_XML"></FormattedMessage>
                </label>
                <input type="file" name="myFile" id="myFile" className="file-upload" onChange={(e) => this.uploadFile(e)} />
              </span>
              <Button id="Popover-type" type="button" className="popover-btn upload-btn">
                <img
                  src={require("../../assets/icons/Information_2.svg").default}
                  alt="question mark"
                />
              </Button>
              <UncontrolledPopover
                placement="right"
                target="Popover-type"
                trigger="hover"
              >
                <PopoverBody className="popover-body">
                  <FormattedMessage id="SUPPORTS_ONLY_XML" />
                </PopoverBody>
              </UncontrolledPopover>
            </span>
            {!this.state.isValid ? <div className="errors-text fs-12 text-transform-initial">
              <FormattedMessage id="SUPPORTS_ONLY_XML" />
            </div> : ''}
            <div className="request-response">
              <textarea id="xml-request" name="xml-request" label="xml-request" className="textbox" onChange={this.saveReq}
                value={this.state.request} spellCheck="false"></textarea>
            </div>
            <button className="btn btn-primary text-capitalize submit" onClick={this.submitXml}
              disabled={!this.state.request.length || this.state.isSubmitting}
              title={!this.state.isValid && this.state.isUploaded ? 'Upload XML file' : ''}>
              <FormattedMessage id="SUBMITXML"></FormattedMessage>
              {this.state.isSubmitting && <div className="lds-dual-ring white" />}
            </button>
            <div className="request-response">
              <textarea id="xml-response" value={this.state.response} readOnly={true} spellCheck="false"
                onKeyDown={keydown}></textarea>
            </div>
            {this.state.response ? <button className="btn btn-primary text-capitalize download" onClick={this.downloadResponse}>
              <FormattedMessage id="DOWNLOAD_RESPONSE" />
            </button>
              : null}
          </div>
        </div> : null}

      </div>
    )
  }
}
ApiValidator.contextType = EntitlementsContext;


