import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import './CommunicationPossibleDisable.scss';

/*  
    This component is a bootstrap modal which is opened if the user tries to disable status of communication possible.
     The popup is shown as warning to the user that the communication between the application and xentry device is going
     to be disabled by the user which is a critical action. Only on confirming in the popup, the status is disabled.
*/

export default class CommunicationPossibleDisable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      communicationDisableMessage: 'All the communications to the back-end system are disabled now',
    }
  }
  handleTextValueChange = (e) => {
    this.setState({
      communicationDisableMessage: e.target.value
    })
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.isOpen !== this.props.isOpen)
      this.setState({
        communicationDisableMessage: 'All the communications to the back-end system are disabled now'
      })
  }

  render() {
    return (
      <div className="communication-possible-disable">
        <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} className="communication-possible-disable-modal" centered>
          <ModalHeader toggle={this.props.toggle}>
            <FormattedMessage id="KILL_COMMUNICATION" /></ModalHeader>
          <ModalBody>
            <FormattedMessage id={this.props.message} />
            {this.props.isCommunicationPossible && <div className="disable-message-text-wrap">
              <FormattedMessage id="OPTIONAL_COMM_DISABLE_MESSAGE">
                {(msg) => (
                  <textarea
                    value={this.state.communicationDisableMessage}
                    className="disable-message-text"
                    placeholder={msg}
                    onChange={this.handleTextValueChange}
                  />
                )}
              </FormattedMessage>
            </div>
            }
          </ModalBody>
          <ModalFooter className="d-flex flex-wrap">
            <Button color="cancel" onClick={this.props.toggle} size="modal">
              <FormattedMessage id="CANCEL" defaultMessage="Cancel" />
            </Button>
            <Button
              color="primary"
              onClick={this.props.disableCommunication({
                message: this.state.communicationDisableMessage,
                language: this.props.language
              })}
              disabled={this.props.isDeleting}
              size="modal">
              <FormattedMessage id="CONFIRM" />
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}
