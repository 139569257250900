import React, { Component } from 'react';
import styled from 'styled-components';
import axiosInstance from "./utlities/Interceptor";
import ApiPath from "./constants/ApiPath";
import { logout, generateRandomNumber } from './utlities/Utils'
import * as openIdConfig from "./constants/OpenIdConfig";
import EntitlementsContext from "./contexts/EntitlementsContext";
import { SpinLoader } from 'react-css-loaders'
import Cookie from "js-cookie";
/* This is the root component of the app. App has token stored in localstorage for a logged in user.
    Component checks whether token is there in localstorage. If yes, token is validated using an api and on successful validation, app
    is redirected to listing page. If no, app is redirected to daimler login page.
    On successful login, redirection is given to this component. A code is appended in the redirect uri.
    An OAuth post request is done with this code to receive the access token. This token is stored in localstorage and
    set as authorisation Header in all api calls.
*/

export default class CheckToken extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { invalidUrl: false }
    }
    componentDidMount() {
        // If redirection url path is /loggingout, app is redirected to daimler logout url
        if (window.location.pathname === '/loggingout') {
            // window.location = ApiPath.daimlerLogout;
        } else {
            var href = window.location.pathname;
            this.validUrls = ['/', '/list', '/category', '/systems', '/alert', '/function_categories', '/mapping', '/api-validator', '/email',
        '/oauth2/idpresponse'];
            if (!this.validUrls.includes(href)) {
                console.log('invalid')
                console.log(href)
                this.setState({ invalidUrl: true })
            } else {
                console.log('valid')
                console.log(href)
                const queryString = require('query-string');
                axiosInstance.get(ApiPath.userInfo)
                .then(res => {
                    if (res.data.payload === 'WMS.Unknown') {
                        alert('User does not have access to the application')
                        logout()
                    } else {
                        this.props.onTokenValid();
                    }
                }).catch(err => {
                    console.log(err)
                    if (err.response && err.response.status === 401) {
                        console.log("unauthorised")
                        window.location.href = ApiPath.login
                    }
                })
                // if (!Cookie.get("AWSELBAuthSessionCookie-0")) {
                //     window.location = "/backend/login"
                //  }
                // else {
                //          this.getEntitlements();
                //          this.checkAuthenticated();
                //  }
            }

        }
    }
    getToken = () => {
        let config = { type: 'auth' }
        axiosInstance.post(ApiPath.getToken, {}, config)
            .then(res => {
                localStorage.setItem('jwt_token', res.data.access_token);
                this.getUserInfo();
            })
            .catch(error => {
                logout();
            });
    }
    checkAuthenticated = () => {
        axiosInstance.get(`${ApiPath.communication_status}`).then(res => {
            this.getUserInfo();
        }).catch(error => {
            logout();
        })
    }
    getUserInfo = () => {
        let config = { type: 'userInfo' }
        axiosInstance.get(`${ApiPath.userInfo}`, config).then(res => {
            this.setState({ userName: res.data.username.replace('Daimler_', '') })
            this.context.setUserInfo(res.data.username.replace('Daimler_', ''));
            this.setEntitlements(this.context.setEntitlements, res.data.username.replace('Daimler_', ''))
        }).catch(error => {
            logout();
        })
    }
    setEntitlements = (setEntitlements, userName) => {
        this.getEntitlements(setEntitlements, userName)
        this.setState({ onLoadEntitlement: false })
    }
    getEntitlements = (setEntitlements, userName) => {
        /*  Function calls the api to get entitlements of the user and sets in global state. For an unauthorised user, status code
        5 is returned and logout function is called
        */
        // axiosInstance.get(process.env.REACT_APP_API_BASE_URL).then(res => {
        //      console.log(res)
        //      console.log(res.data)
        //     if (!res.data.is_active) {
        //         logout();
        //     } else {
        //     setEntitlements(res.data.payload)
        //     }
        // }).catch(error => {
        //     if (error.response && error.response.data === 'WMS.Unknown') {
        //         alert('User does not have access to the application')
        //         logout();
        //     }
        // })
    }
    login = () => {
        window.location = "/backend/login"
    }
    render() {
        const DIV = styled.div`
            position: fixed;
            top: 15px;
            left: 0;
            right: 0;
            bottom: 0;
            background: #000;
            z-index: 15;    
            display: flex;
            justify-content: center;
            align-items: center;   
            flex-direction: column;
       `;
        return (
            <DIV>
                {/* <button onClick={this.login}>Login</button> */}
                {!this.state.invalidUrl && <SpinLoader
                    color="#02859d"
                    background="#000"
                    duration="1.5"
                    size="7"
                />}
                {this.state.invalidUrl && <p>404 page not found</p>}
            </DIV>
        )
    }
}
CheckToken.contextType = EntitlementsContext;
