import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Formik } from "formik";

import PeriodicForm from './PeriodicMaintenanceForm/PeriodicMaintenanceForm';
import {validationSchema} from './ValidationSchema';

export default class PeriodicMaintenancePopup extends Component {
   constructor(props) {
    super(props);
    this.formik = React.createRef();
    this.state = {recurrenceOption: {option: '', value: ''}}
  }
   render() {
    return (
      <div className="periodic-maintenance-popup">
        <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} className="generic-modal" centered>
          <ModalHeader toggle={this.props.toggle}><FormattedMessage id="PERIODICAL_MAINTENANCE" /></ModalHeader>
          <ModalBody>
            <Formik
              ref={this.formik}
              render={props => (
                <PeriodicForm   {...props} savePeriodicForm={this.props.savePeriodicForm} toggle={this.props.toggle}
                />
              )}
              initialValues={this.props.values}
            validationSchema={validationSchema}
            onSubmit={(e) => this.props.submitPeriodicForm(e)}
            savePeriodicForm={this.props.savePeriodicForm}
            />
          </ModalBody>
          
        </Modal>
      </div>
    )
  }
}
