import * as openIdConfig from '../constants/OpenIdConfig';
import * as queryString from 'query-string';

let c = queryString.parse(window.location.search).code;
export const setConfig = (config) => {
    // Function sets the api url configuration according to type
    switch (config.type) {
        case 'auth':
            config.url = `${config.url}?grant_type=${openIdConfig.grant_type}&code=${c}&redirect_uri=${openIdConfig.redirect_uri}`
            config.headers.Authorization = 'Basic ' + openIdConfig.client_id_base64;
            config.headers['Content-Type'] = 'application/x-www-Form-urlencoded'
            break;
        case 'userInfo':
            config.headers.Authorization = 'Bearer ' + localStorage.getItem('jwt_token')
            break;
        case 'send-email':
            config.url = `${config.url}?communication-status=${config.communication_status}&username=${config.username}`
            config.headers.Authorization = localStorage.getItem('jwt_token')
            break;
        default: if (config.type !== 'logout') {
            config.headers.Authorization = localStorage.getItem('jwt_token')

        }
    }
    
    if (config.method === 'get' && config.type !== 'logout') {
        config.url = `${config.url}?t=${+ new Date()}`
    }
    return config;
}