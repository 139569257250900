import moment from 'moment';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, PopoverBody, UncontrolledPopover } from "reactstrap";
import TableHeaderColumn from "react-bootstrap-table-next";

import { DATE_TIME_FORMAT } from '../../constants/Constants';
import Sort from './../../assets/icons/Sort.svg';
import SortAsc from './../../assets/icons/Sort_asc.svg';
import SortDesc from './../../assets/icons/Sort_desc.svg';
import ActionsButton from './ActionsButton/ActionsButton';
import DisplayMessage from './Message';
import Reason from './Reason';
import { findInfo, renderDetailsText } from '../../components/popups/PeriodicMaintenancePopup/PeriodicMaintenanceForm/PeriodicFunctions';

export function customSortCaret(order, column) {
  if (!order) return (<span className="d-inline-flex align-items-center px-2"><img src={Sort} alt="" /></span>);
  else if (order === 'asc') return (<span className="d-inline-flex align-items px-2"><img src={SortAsc} alt="" /></span>);
  else if (order === 'desc') return (<span className="d-inline-flex align-items-center px-2"><img src={SortDesc} alt="" /></span>);
  return null;
}

export function customTableHeader(column, colIndex, { sortElement, filterElement }) {
  return (
    <div className="d-inline-flex text-left">
      <span className='text'>
        {tableHeaderTranslation(column, colIndex)}
      </span>{sortElement}
    </div>
  );
}

export function timeSort(a, b, order, dataField) {
  let one = moment(a);
  let two = moment(b);
  if (order === 'asc') {
    return two.isAfter(one)
  }
  return one.isAfter(two) // desc
}

export default function tableHeaderTranslation(column, colIndex) {
  return (
    <FormattedMessage id={column.text} />
  );
}

export function trueFalseFormattor(cellContent, row) {
  let value = (!!cellContent) ? 'Yes' : 'No';
  return (
    <span>{value}</span>
  );
}

export function dateFormattor(cellContent, row) {
  let formatedDate = (cellContent && cellContent !== '-') ? moment(cellContent).format(DATE_TIME_FORMAT) : cellContent;
  return (
    <span>{formatedDate}</span>
  );
}
export const columns = [
  {
    dataField: 'type',
    text: 'TYPE',
    headerFormatter: (column, colIndex, { sortElement, filterElement }) => customTableHeader(column, colIndex, { sortElement, filterElement })
  }, {
    dataField: 'is_periodical_maintenance',
    text: 'PERIODICAL_EVENT',
    headerFormatter: (column, colIndex, { sortElement, filterElement }) => customTableHeader(column, colIndex, { sortElement, filterElement }),
    formatter: (cellContent, row) => trueFalseFormattor(cellContent, row)
  }, {
    dataField: 'start_date',
    text: 'START_OF_EVENT',
    headerFormatter: (column, colIndex, { sortElement, filterElement }) => customTableHeader(column, colIndex, { sortElement, filterElement }),
    sortFunc: (a, b, order, dataField) => timeSort(a, b, order, dataField),
    formatter: (cellContent, row) => dateFormattor(cellContent, row)
  }, {
    dataField: 'end_date',
    text: 'END_OF_EVENT',
    headerFormatter: (column, colIndex, { sortElement, filterElement }) => customTableHeader(column, colIndex, { sortElement, filterElement }),
    sortFunc: (a, b, order, dataField) => timeSort(a, b, order, dataField),
    formatter: (cellContent, row) => dateFormattor(cellContent, row)
  }, {
    dataField: 'system',
    text: 'SYSTEM',
    headerFormatter: (column, colIndex) => tableHeaderTranslation(column, colIndex),
    formatter: (cellContent, row) => {
      let systems = [];
      if (cellContent) {
        cellContent.forEach((item, i) => {
          systems.push(
            <p key={i} className="m-0">{item}</p>
          )
        })
      }
      if (systems.length === 0) {
        return '-';
      }
      return systems;
    }
  }, {
    dataField: 'reason',
    text: 'REASON',
    headerFormatter: (column, colIndex) => tableHeaderTranslation(column, colIndex),
    formatter: (cellContent, row) => {
      return <div style={{ position: 'relative' }}>
        <Reason cellContent={cellContent} />
      </div>
    }
  },
  {
    dataField: 'publication_date',
    text: 'PUBLICATION_DATE',
    headerFormatter: (column, colIndex, { sortElement, filterElement }) => customTableHeader(column, colIndex, { sortElement, filterElement }),
    sortFunc: (a, b, order, dataField) => timeSort(a, b, order, dataField),
    formatter: (cellContent, row) => dateFormattor(cellContent, row)
  }, {
    dataField: 'message_en',
    text: 'MESSAGE',
    headerFormatter: (column, colIndex) => tableHeaderTranslation(column, colIndex),
    formatter: (cellContent, row) => {
      // let info = "";
      // info = renderDetailsText(row.recurrence_option, row.recurrence_option_value,row.start_date);
      return (
        <div style={{ position: 'relative' }}>
          <DisplayMessage rowData={row} />
        </div>
        // <div id={row.id}>
        //   {row.is_periodical_maintenance && !row.is_expired?<div className={row.status === "active"? "periodic-popover active" : " periodic-popover" }>
        //     <Button id={"Popover-type" + row.id} type="button" className="popover-btn">
        //       <img
        //           src={require("./../../assets/icons/info2.png")}
        //           alt="question mark"
        //       />
        //     </Button>
        //     <UncontrolledPopover
        //         placement="right"
        //         target={"Popover-type" + row.id }
        //         trigger="hover"
        //         id = {"popover" + row.id}
        //         className="periodic-info-popover"
        //     >
        //       <PopoverBody className="popover-body" id={row.id}>
        //         {info}
        //       </PopoverBody>
        //     </UncontrolledPopover>
        //   </div>:''}
        // </div>
      );
    }
  }, {
    dataField: 'actions',
    isDummyField: true,
    text: '',
    formatter: (cellContent, row) => {
      return (
        <ActionsButton rowData={row} />
      );
    },
  }
];
