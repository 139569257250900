import axios from 'axios';
import { loadProgressBar } from 'axios-progress-bar';
import promise from 'promise';

import { logout } from './Utils';
import { showToast } from './Utils';
import { setConfig } from './SetConfig';
import { errorCodeMapping } from '../constants/ErrorCodeMapping';

// Add a request interceptor 
var axiosInstance = axios.create({
  withCredentials: true
});
axiosInstance.defaults.headers.common = {};
axiosInstance.interceptors.request.use(function (config) {
  if (true) {
    config = setConfig(config)
  }
  return config;
}, function (error) {
  return promise.reject(error);
});
axiosInstance.interceptors.response.use((response) => {
  return (response)
}, error => {
  console.log(Promise.resolve({ error }))
  if (!error.response) {
    showToast("NETWORK ERROR");
    return Promise.reject(error)
  } else {
    // for an user not authorized to enter the app, donot show toast
    if (error.response.data.message && error.config.type !== 'authorization') {
      showToast(`${error.response.data.message}`);
      error = {
        ...error,
        isError: true
      }
    } else {
      if (error.response.data_description) {
        showToast(error.response.data_description)
      }
      if (error.response.status === 401) {
        showToast('Unauthorised');
        logout()
      } else {
        showToast(`Error Code: ${error.response.status}`);
      }
    }
    // if unauthorised, call logout
    //if (error.response.status === 401) {
    //  logout();
    //}
  }
  return Promise.reject(error)
})

loadProgressBar({
  minimum: 0.5,
  showSpinner: false,
  easing: 'ease',
  speed: 500
}, axiosInstance);
export default axiosInstance;