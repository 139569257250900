import * as moment from "moment";
import * as queryString from "query-string";
import React, { Component } from "react";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { FormattedMessage, injectIntl } from "react-intl";
import { Redirect, withRouter } from "react-router";
import Select from "react-select";
import { Button, PopoverBody, UncontrolledPopover } from "reactstrap";

import ApiPath from "../../../constants/ApiPath";
import axiosInstance from "../../../utlities/Interceptor";
import { ALERT_TYPE, DATE_FORMAT, TIME_FORMAT, WEEKDAYS, WEEKDAYS_MAPPING } from "../../../constants/Constants";
import { colourStyles } from './ColourStyles';
import WarningPopup from '../../../components/WarningPopup/WarningPopup';
import PeriodicMaintenancePopup from '../../../components/popups/PeriodicMaintenancePopup/PeriodicMaintenancePopup';
import { findInfo, findPeriodicStartDate, getFirstDayInstanceFromDate, renderDetailsText } from '../../../components/popups/PeriodicMaintenancePopup/PeriodicMaintenanceForm/PeriodicFunctions';
import DeleteConfirmation from '../../../components/DeleteConfirmation/DeleteConfirmation';
import { keydown } from '../../../utlities/Utils';
import "./Form.scss";
import orderBy from 'lodash/orderBy';
import omit from 'lodash/omit';
import loKeys from 'lodash/keys';
import GenericPopup from '../../../components/popups/GenericPopup/GenericPopup';

/* 
  Form component created using Formik library. On submit of the Form, submitValues function of CreateAlert component is called.
  The Form also contains the Form required for periodic maintenance.
*/
const yesterday = Datetime.moment().subtract();
class CreateAlert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toList: false,
      systemList: [],
      defaultSystemValues: null,
      isOpen: true,
      showPeriodicModal: false,
      showDeleteModal: false,
      showEndEventModal: false,
      endEventWarning: false,
      isEnding: false,
      mailTemplateType: 'default'
    };
    this.getSystems();
  }

  componentDidMount() {
    let parsed = queryString.parse(this.props.location.search);
    if ("duplicate" in parsed && this.props.values.is_periodical_maintenance) {
      this.props.setFieldValue('publication_date', moment().add(1, 'm'));
    }
    if ("duplicate" in parsed || "edit" in parsed) {
      this.props.values.valid_days = []
      this.props.values.old_start_date = this.props.values.start_date;
      this.props.setFieldValue('old_start_date', this.props.values.start_date)
      if (this.props.values.is_periodical_maintenance) {
        this.setState({ showPeriodicFields: true })
      }
    }
  }
  componentWillMount() {
    let parsed = queryString.parse(this.props.location.search);
    if ("edit" in parsed) {
      this.props.setFieldValue('editID', parsed.edit)
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }
  // function blocks the past dates
  blockDates = (current, dateBefore, dateAfter) => {
    return current.isSameOrAfter(yesterday, 'day');
  }
  // function calls the api to get the systems and store the system list to state
  getSystems = () => {
    axiosInstance.get(ApiPath.system).then(res => {
      let systemList = res.data;
      let transformed = systemList.map((name) => ({
        label: name,
        value: name
      }));
      this.setState({
        systemList: transformed,
        defaultSystemValues: transformed.filter(obj =>
          (this.props.values.system ? this.props.values.system : "").includes(
            obj.value
          )
        )
      });
      // system in case of duplicate or edit should not contain deleted systems
      const systems = this.state.systemList.map(function (d) { return d['label'] });
      if (this.props.values.system && this.props.values.system.length > 0) {
        this.props.setFieldValue('system', this.props.values.system.filter(e => systems.includes(e)))
      }
      let parsed = queryString.parse(this.props.location.search);
      if ("duplicate" in parsed) {
        this.props.setFieldValue('publication_date', moment().add(1, 'm'));
      }
      if("edit" in parsed || "duplicate" in parsed) {
        const { setFieldValue } = this.props;
        const { system } = this.props.values;
        if (Array.isArray(system) && system.length) {
          setFieldValue('type', 'm')
        } else {
          setFieldValue('type', 'i')
        }
      }
      return transformed;
    });
  }
  /* Function converts a date to moment object. Periodic recalculations are done if day value of new date is
     different from existing date.
  */
  setDate = (momentObj, field) => {
    this.props.setFieldValue(field, momentObj);
    if (this.props.values[field] && !moment(this.props.values[field]).isSame(momentObj, 'day') && field !== 'publication_date') {
      if (this.props.values.is_periodical_maintenance) {
        if (this.props.values.recurrence_option === 'weekly') {
          // setTimeout(this.periodicReCalculations, 100)
        }
      }
    }
    else {
      this.props.setFieldTouched(field, true);
      this.props.setFieldValue(field, momentObj);
      this.props.values[field] = momentObj;
    }
  }
  // function finds the valid days for the given recurrence option, start date and end date
  periodicReCalculations = () => {
    this.setState({ validDays: [] })
    let validDays = []
    this.props.values.recurrence_option_value.forEach(element => {
      validDays = findPeriodicStartDate(element, this.props.values.start_date, this.props.values.end_date, validDays)
    });
    this.props.setFieldValue('valid_days', validDays)
  }
  // Function stores the selected systems into an array in state
  setSelectSystem = (data, field) => {
    let arrayValue = [];
    data.forEach(element => {
      arrayValue.push(element.value);
    });
    this.props.setFieldValue(field, arrayValue);
    if (Array.isArray(arrayValue) && arrayValue.length) {
      this.assignType('m')
    } else {
      this.assignType('i')
    }
  }
  // Function displays the form errors. Warning popup is shown if save button is clicked
  displayError = (field) => {
    if (this.props.errors[field]) {
      return (
        <div className="errors-text fs-12 text-transform-initial">
          <FormattedMessage id={this.props.errors[field]} />
          {this.state.saveClicked ? <WarningPopup isOpen={this.state.showWarningPopup} toggle={this.closeWarningPopup} message="ERROR_WARNING" class="error" /> : ''}
        </div>
      );
    }
  }
  // Function is invoked if cancel button of the form is clicked. Application is navigated to overview page
  cancel = () => {
    this.setState({
      toList: true
    });
  }

  translate(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  onRouteChanged = () => {
    for (const key of Object.keys(this.props.values)) {
      this.props.setFieldValue(key, "");
      if (key === 'type')
        this.props.setFieldValue(key, "m");
    }
    this.setState({
      defaultSystemValues: "",
      defaultWeekValues: ""
    });
    this.props.setFieldValue('publication_date', moment().add(1, 'm'));
    this.getSystems()
  }
  // Function assigns the type to the form field
  assignType = (type) => {
    this.props.setFieldValue('type', type)
    this.props.setFieldValue('is_periodical_maintenance', false);
  }
  /* Function assigns whether maintenance is periodical. If yes, a popup is opened that contains the
    periodic maintenance form
  */
  switchPeriodic = (event) => {
    this.props.setFieldValue('is_periodical_maintenance', !this.props.values.is_periodical_maintenance)
    // if (event.target.checked) {
    //   // this.showPeriodicModal();
    // }
    // else {
    // }
    this.resetPeriodic();
  }
  // Function resets the values associated to periodic maintenance.
  resetPeriodic = () => {
    this.props.setFieldValue('recurrence_option', 'daily')
    this.props.setFieldValue('recurrence_option_value', {
      value: '1',
      startTime: moment('07:00:00', 'H:mm:ss').utc().format('H:mm:ss'),
      endTime: moment('16:00:00', 'H:mm:ss').utc().format('H:mm:ss'),
    })
  }
  /* Function is invoked when periodic maintenance form is submitted. The values stored from the periodic
  maintenance form is saved to the main form data.
  */
  submitPeriodicForm = (data) => {
    this.props.setFieldValue('start_date', data.start_date)
    this.props.setFieldValue('end_date', data.end_date)
    this.props.setFieldValue('recurrence_option', data.recurrence_option)
    if (this.props.values.recurrence_option === 'weekly')
      this.props.setFieldValue('recurrence_option_value', data.valid_days);
    else
      this.props.setFieldValue('recurrence_option_value', data.recurrence_option_value);
    this.props.setFieldValue('periodic_info', data.periodic_info)
    this.props.setFieldValue('valid_days', data.valid_days)
    if (this.props.values.recurrence_option === 'weekly') {
      //need to take only a copy of the array
      this.setState({ previousVal: this.props.values.recurrence_option_value.slice(0) })
    }
    this.setState({ showPeriodicFields: true, showPeriodicModal: false })
  }
  // Function sets the value to show the periodic modal
  showPeriodicModal = () => {
    this.setState({ currentValues: this.props.values.recurrence_option_value })
    this.setState({ showPeriodicModal: true })
  }
  // Function calls the api to delete an alert. id, start date and end date of maintenance is passed as body
  deleteAlert = () => {
    this.setState({
      isDeleting: true
    });
    let body = { maintenance_id: this.props.values.id, created_date: this.props.values.created_date }
    axiosInstance.delete(`${ApiPath.list}`, { data: body })
      .then(res => {
        this.setState({ isDeleting: false })
        this.props.history.push('/list')
      })
      .catch(error => {
        this.setState({
          isDeleting: false,
          showDeleteModal: false,
        });
      });
  }
  showDeleteModal = (show) => {
    this.setState({ showDeleteModal: show })
  }
  showEndEventModal = show => {
    if (moment.utc().isBefore(moment.utc(this.props.values.start_date))) {
      this.showEndEventWarning(true)
    }
    else {
      this.setState({ showEndEventModal: show });
    }
  }
  // Function is invoked if form contains errors. Warning popup is shown
  saveClicked = () => {
    this.setState({ saveClicked: true, showWarningPopup: true })
  }
  closeWarningPopup = () => {
    this.setState({ showWarningPopup: false })
  }
  showMailNotifications = () => {
    // show the fields related to email notifications if the enable mail notifications checkbox is checked
    this.setState({ showMailFields: !this.state.showMailFields })
  }
  setMailTemplateType = (type) => {
    // set mail template type as default or custom. In default type, a default template is set for the mail
    this.setState({ mailTemplateType: type })
  }
  togglePeriodicModal = () => {
    this.setState({ showPeriodicModal: false })
    // if there is no recurrence option value, checkbox is disabled on closing popup
    if (!this.state.showPeriodicFields) {
      this.props.values.is_periodical_maintenance = false;
    }
    if (this.props.values.recurrence_option === 'weekly' && this.state.previousVal) {
      // on cancel, set the values to previously filled values
      this.props.setFieldValue('recurrence_option_value', this.state.previousVal.slice(0))
    }
  }

  handleRecurrenceOptionChange = (option) => {
    this.props.setFieldValue('recurrence_option', option);
    this.props.setFieldValue('recurrence_data', '');
    if (option === 'weekly')
      this.props.setFieldValue('recurrence_option_value', {});
    else {
      const updatedValue = {
        value: '1',
        startTime: moment('07:00:00', 'H:mm:ss').utc().format('H:mm:ss'),
        endTime: moment('16:00:00', 'H:mm:ss').utc().format('H:mm:ss'),
      };
      this.props.setFieldValue('recurrence_option_value', updatedValue);
      this.props.setFieldValue('recurrence_data', JSON.stringify(updatedValue));

    }
  }

  saveRecurrenceDays = (event, day) => {
    const { recurrence_option_value } = this.props.values;
    let updatedValue = {};
    if (event.target.checked) {
      updatedValue = {
        ...recurrence_option_value,
        [`day_${day.dayIndex}`]: {
          startTime: moment('07:00:00', 'H:mm:ss').utc().format('H:mm:ss'),
          endTime: moment('16:00:00', 'H:mm:ss').utc().format('H:mm:ss'),
        }
      }
    }
    else {
      updatedValue = omit(recurrence_option_value, [`day_${day.dayIndex}`])
    }
    this.props.setFieldValue('recurrence_option_value', updatedValue);
    this.props.setFieldValue('recurrence_data', JSON.stringify(updatedValue));
  }

  isWeekDaySelected = dayIndex => this.props.values.recurrence_option_value.hasOwnProperty(`day_${dayIndex}`);

  setRecurrenceDayTimeRange = (dayIndex, momentObj, type) => {
    const { recurrence_option, recurrence_option_value } = this.props.values;
    let updatedValue = {};
    if (recurrence_option === 'weekly') {
      updatedValue = {
        ...recurrence_option_value,
        [`day_${dayIndex}`]: {
          ...recurrence_option_value[`day_${dayIndex}`],
          [type]: momentObj.utc().format('HH:mm:ss')
        }
      }
    }
    else {
      updatedValue = {
        ...recurrence_option_value,
        [type]: momentObj.utc().format('HH:mm:ss')
      }
    }

    this.props.setFieldValue('recurrence_option_value', updatedValue);
    this.props.setFieldValue('recurrence_data', JSON.stringify(updatedValue));
  }

  handleRecurrenceDayChange = (e) => {
    const { recurrence_option, recurrence_option_value } = this.props.values;
    let { value } = e.target;
    if (!value)
      value = '1';
    else if (parseInt(value) > 31)
      value = '31';

    const updatedValue = {
      ...recurrence_option_value,
      value: Math.abs(parseInt(value)).toString()
    }
    this.props.setFieldValue('recurrence_option_value', updatedValue);
    this.props.setFieldValue('recurrence_data', JSON.stringify(updatedValue));

  }
  renderRecurrenceDetails = () => {
    const { recurrence_option } = this.props.values;
    switch (recurrence_option) {
      case 'daily':
        return <>
          <div className="recurrence-option-value-wrap">
            <FormattedMessage id="EVERY" />
            <input
              className="day-number form-control quantity"
              type="number"
              value={this.props.values.recurrence_option_value.value}
              maxLength="3"
              id="recurrence_option_value"
              name="recurrence_option_value"
              autoComplete="off"
              min="1"
              max="365"
              onChange={this.handleRecurrenceDayChange}
            />
            <FormattedMessage id="DAY_(S)" />
          </div>
          <div className="active-time-range">
            <Datetime
              locale="en"
              closeOnSelect={false}
              value={moment.utc(this.props.values.recurrence_option_value.startTime, 'HH:mm:ss').local()}
              onChange={momentObj =>
                this.setRecurrenceDayTimeRange(null, momentObj, "startTime")
              }
              dateFormat={false}
              timeFormat={TIME_FORMAT}
              inputProps={{ onKeyDown: event => keydown(event), readOnly: true }}
              className="custom-datepicker timepicker"
            />
            <Datetime
              locale="en"
              closeOnSelect={false}
              value={moment.utc(this.props.values.recurrence_option_value.endTime, 'HH:mm:ss').local()}
              onChange={momentObj =>
                this.setRecurrenceDayTimeRange(null, momentObj, "endTime")
              }
              dateFormat={false}
              timeFormat={TIME_FORMAT}
              inputProps={{ onKeyDown: event => keydown(event), readOnly: true }}
              className="custom-datepicker timepicker"
            />
          </div>
        </>
      case 'weekly':
        return <div className="weekly">
          {WEEKDAYS.map((day, index) => (
            <div className="each-day" key={index}>
              <div className="d-flex day-select-wrap">
                <input
                  type="checkbox"
                  id={index}
                  onChange={(event) => this.saveRecurrenceDays(event, day)}
                  value={day.dayIndex}
                  checked={this.isWeekDaySelected(day.dayIndex)}
                />
                <label htmlFor={index}></label>
                <label htmlFor={index}><span className="day-name">
                  <FormattedMessage id={day.value.toUpperCase()} />
                </span></label>
              </div>
              {this.isWeekDaySelected(day.dayIndex) && <div className="d-flex time-range-wrap">

                <Datetime
                  locale="en"
                  closeOnSelect={false}
                  value={moment.utc(this.props.values.recurrence_option_value[`day_${day.dayIndex}`].startTime, 'HH:mm:ss').local()}
                  onChange={momentObj =>
                    this.setRecurrenceDayTimeRange(day.dayIndex, momentObj, "startTime")
                  }
                  dateFormat={false}
                  timeFormat={TIME_FORMAT}
                  inputProps={{ onKeyDown: event => keydown(event), readOnly: true }}
                  className="custom-datepicker timepicker"
                />
                <Datetime
                  locale="en"
                  closeOnSelect={false}
                  value={moment.utc(this.props.values.recurrence_option_value[`day_${day.dayIndex}`].endTime, 'HH:mm:ss').local()}
                  onChange={momentObj =>
                    this.setRecurrenceDayTimeRange(day.dayIndex, momentObj, "endTime")
                  }
                  dateFormat={false}
                  timeFormat={TIME_FORMAT}
                  inputProps={{ onKeyDown: event => keydown(event), readOnly: true }}
                  className="custom-datepicker timepicker"
                />
              </div>}

            </div>
          ))}
        </div>
      case 'monthly':
        return <>
          <div className="recurrence-option-value-wrap">
            <FormattedMessage id="DAY" />
            <input
              className="day-number form-control"
              type="number"
              value={this.props.values.recurrence_option_value.value}
              maxLength="2"
              id="recurrence_option_value" name="recurrence_option_value"
              autoComplete="off"
              onChange={this.handleRecurrenceDayChange}
              min="1"
              max="31" />

            <FormattedMessage id="OF_EVERY_MONTH" />
          </div>
          <div className="active-time-range">
            <Datetime
              locale="en"
              closeOnSelect={false}
              value={moment.utc(this.props.values.recurrence_option_value.startTime, 'HH:mm:ss').local()}
              onChange={momentObj =>
                this.setRecurrenceDayTimeRange(null, momentObj, "startTime")
              }
              dateFormat={false}
              timeFormat={TIME_FORMAT}
              inputProps={{ onKeyDown: event => keydown(event), readOnly: true }}
              className="custom-datepicker timepicker"
            />
            <Datetime
              locale="en"
              closeOnSelect={false}
              value={moment.utc(this.props.values.recurrence_option_value.endTime, 'HH:mm:ss').local()}
              onChange={momentObj =>
                this.setRecurrenceDayTimeRange(null, momentObj, "endTime")
              }
              dateFormat={false}
              timeFormat={TIME_FORMAT}
              inputProps={{ onKeyDown: event => keydown(event), readOnly: true }}
              className="custom-datepicker timepicker"
            />
          </div>
        </>
      default:
        return null;
    }
  }

  renderRecurrenceOptionValidationMsg = () => {
    const { recurrence_option, recurrence_option_value } = this.props.values;
    switch (recurrence_option) {
      case 'monthly':
        let isValid = true;
        const { start_date, end_date } = this.props.values;
        if (start_date && moment(start_date).date() > parseInt(recurrence_option_value))
          isValid = false;
        if (end_date && moment(start_date).date() < parseInt(recurrence_option_value))
          isValid = false;
        return isValid ? null : <><span className="error"></span></>;
      default:
        return null;
    }
  }

  showEndEventWarning = (show) => {
    this.setState({
      endEventWarning: show
    })
  }
  handleEndEvent = () => {

    this.setState({
      isEnding: true
    });
    const updatedData = {
      ...this.props.values,
      maintenance_id: this.props.values.id,
      publication_date: moment.utc().format(),
      end_date: moment.utc().format()
    }
    axiosInstance.put(ApiPath.list, updatedData)
      .then(res => {
        this.showEndEventModal(false);
        this.setState({
          isEnding: false
        });
        this.props.history.push('/');
        this.props.history.push('/list');
      })
      .catch(err => {
        this.showEndEventModal(false);
        this.setState({
          isEnding: false
        });
      })

  }

  render() {
    if (this.state.toList === true) {
      return <Redirect push to="/list" />;
    }
    const { id } = this.props.values;
    const isEmergencyEntry = id && id.includes('emergency_entry');


    return (
      <div>
        <form onSubmit={this.props.handleSubmit} className="alert-form d-flex flex-wrap pt-2">
          <DeleteConfirmation isOpen={this.state.showDeleteModal} toggle={() => this.showDeleteModal(false)}
            deleteRow={this.deleteAlert} isDeleting={this.state.isDeleting} deleteText="YOU_ARE_ABOUT_TO_DELETE_AN_ALERT_ARE_YOU_SURE" />
          <PeriodicMaintenancePopup isOpen={this.state.showPeriodicModal} values={this.props.values} toggle={this.togglePeriodicModal}
            submitPeriodicForm={this.submitPeriodicForm} />
          <GenericPopup
            isOpen={this.state.showEndEventModal}
            toggle={() => { this.showEndEventModal(false) }}
            onSubmit={this.handleEndEvent}
            header="CONFIRM"
            okTag="END_EVENT"
            message="CONFIRM_END_EVENT"
            cancelTag="CANCEL"
            isLoading={this.state.isEnding}
          />
          <WarningPopup
            isOpen={this.state.endEventWarning}
            toggle={() => { this.showEndEventWarning(false) }}
            message="END_EVENT_WARNING" />
          <div className="form-group col-xs-10 col-sm-12 col-md-12 col-lg-12">
            <span className="fs-12 mr-5">
              <FormattedMessage id="TYPE" />
              <sup>*</sup>
            </span>
            <span className="mr-4">
              <input
                type="radio"
                id="information"
                name="type"
                className="default"
                value={ALERT_TYPE.information.value}
                checked={this.props.values.type === 'i'}
                onChange={() => this.assignType('i')}
                onBlur={this.props.handleBlur}
                disabled={true}
              />
              <label
                htmlFor="information"
                className="type-radio text-capitalize fs-12"
              >
                <FormattedMessage id="INFORMATION" />
              </label>
            </span>
            <span className="mr-4">
              <input
                type="radio"
                id="maintenance"
                name="type"
                className="default"
                value={ALERT_TYPE.maintenance.value}
                checked={this.props.values.type === 'm'}
                onChange={() => this.assignType('m')}
                onBlur={this.props.handleBlur}
                disabled={true}
              />
              <label
                htmlFor="maintenance"
                className="type-radio text-capitalize fs-12"
              >
                <FormattedMessage id="MAINTENANCE" />
              </label>
            </span>
          </div>
          <p className="form-group fs-12 col-xs-10 col-sm-12 col-md-12 col-lg-12 text-transform-initial">
          {this.props.values.type === 'i' ?
            <FormattedMessage id="INFORMATION_MESSAGE" /> :
            <FormattedMessage id="MAINTENANCE_MESSAGE" />
          }
          </p>
          <div className="form-group col-xs-10 col-sm-6 col-md-6 col-lg-6">
            <span className="fs-12 label-mb d-block">
              <FormattedMessage id="SYSTEM" />
            </span>
            {this.state.defaultSystemValues && (
              <FormattedMessage id="SELECT">
                {(msg) => (
                  <Select
                    defaultValue={this.state.defaultSystemValues}
                    options={this.state.systemList}
                    isMulti
                    onChange={data => this.setSelectSystem(data, "system")}
                    closeMenuOnSelect={false}
                    className="custom-multi-select"
                    styles={colourStyles}
                    placeholder={msg}
                  />
                )}
              </FormattedMessage>
            )}
            {!this.state.defaultSystemValues && <div className="lds-dual-ring" />}
          </div>

          <div className="form-group col-xs-10 col-sm-6 col-md-6 col-lg-6">
            <span className="fs-12 label-mb d-block">
              <FormattedMessage id="PUBLICATION_DATE" />
              <sup>*</sup>
            </span>
            <Datetime
              locale="en"
              // disabled={isEmergencyEntry}
              // input
              isValidDate={current => this.blockDates(current, "", "")}
              value={
                this.props.values.publication_date &&
                  this.props.values.publication_date !== "-"
                  ? new Date(moment(this.props.values.publication_date).format())
                  : ""
              }
              onChange={(momentObj) => this.setDate(momentObj, "publication_date")}
              dateFormat={DATE_FORMAT}
              timeFormat={TIME_FORMAT}
              className="custom-datepicker"
              inputProps={{ onKeyDown: event => keydown(event), readOnly: true, disabled: isEmergencyEntry }}
            />
            {this.displayError("publication_date")}
          </div>
          <div className="form-group col-xs-10 col-sm-3 col-md-3 col-lg-3 start-periodical">
            <span className="fs-12 label-mb d-block text-transform-initial">
              <FormattedMessage id="START_OF_EVENT" />
              <sup>*</sup>
            </span>
            <Datetime
              locale="en"
              disabled={isEmergencyEntry}
              isValidDate={current =>
                this.blockDates(current, this.props.values.end_date, "")
              }

              value={
                this.props.values.start_date &&
                  this.props.values.start_date !== "-"
                  ? new Date(moment(this.props.values.start_date).format())
                  : ""
              }
              onChange={momentObj => this.setDate(momentObj, "start_date")}
              closeOnSelect={false}
              dateFormat={DATE_FORMAT}
              timeFormat={TIME_FORMAT}
              className="custom-datepicker"
              inputProps={{ onKeyDown: event => keydown(event), readOnly: true, disabled: isEmergencyEntry }}
            />
            {this.displayError("start_date")}
          </div>
          <div className="form-group col-xs-10 col-sm-3 col-md-3 col-lg-3 endDate-periodical">
            <span className="fs-12 label-mb d-block text-transform-initial">
              <FormattedMessage id="END_OF_EVENT" />
              {this.props.values.type === 'm' ? <sup>*</sup> : ''}
              <Button id="Popover-endDate-maintenance" type="button" className="popover-btn">
                <img
                  src={require("../../../assets/icons/Information_2.svg").default}
                  alt="question mark"
                />
              </Button>
              <UncontrolledPopover
                placement="right"
                target="Popover-endDate-maintenance"
                trigger="hover"
              >
                <PopoverBody className="popover-body">
                  <FormattedMessage id="END_MAINTENANCE_HELP_TEXT" />
                </PopoverBody>
              </UncontrolledPopover>
            </span>
            <Datetime
              locale="en"
              disabled={isEmergencyEntry}
              isValidDate={current =>
                this.blockDates(current, "", this.props.values.start_date)
              }
              value={
                this.props.values.end_date && this.props.values.end_date !== "-"
                  ? new Date(moment(this.props.values.end_date).format())
                  : ""
              }
              onChange={momentObj => this.setDate(momentObj, "end_date")}
              closeOnSelect={false}
              dateFormat={DATE_FORMAT}
              timeFormat={TIME_FORMAT}
              className="custom-datepicker"
              inputProps={{ onKeyDown: event => keydown(event), readOnly: true, disabled: isEmergencyEntry }}
            />
            {this.displayError("end_date")}
          </div>
          <div className="form-group col-xs-10 col-sm-6 col-md-6 col-lg-6 reason">
            <span className="fs-12 label-mb d-block">
              <FormattedMessage id="REASON" />
            </span>
            <input
              id="reason"
              name="reason"
              label="reason"
              disabled={isEmergencyEntry}
              type="text"
              autoComplete="off"
              className={`form-control ${this.props.touched.reason &&
                Boolean(this.props.errors.reason) &&
                "is-invalid"}`}
              value={this.props.values.reason ? this.props.values.reason : ""}
              onChange={this.props.handleChange}
              onBlur={this.props.handleBlur}
            />
            {this.displayError("reason")}
          </div>
          <div className="form-group col-xs-10 col-sm-6 col-md-6 col-lg-6 language-switcher-left">
            <p className="fs-12 label-mb d-block">
              <FormattedMessage id="MESSAGE" />
              &nbsp;|&nbsp;EN
            {this.props.values.type === 'i' && !this.props.values.message_de ? <sup>*</sup> : ''}
            </p>
            <FormattedMessage id="MESSAGE_IN_ENGLISH">
              {(msg) => (
                <textarea
                  id="message_en"
                  name="message_en"
                  label="message_en"
                  value={
                    this.props.values.message_en ? this.props.values.message_en : ""
                  }
                  onChange={this.props.handleChange}
                  onBlur={this.props.handleBlur}
                  rows="4"
                  placeholder={msg}
                  className="w-100 fs-12"
                />
              )}
            </FormattedMessage>

            {/* {this.displayError("message_en")} */}
            <button onClick={this.translate} disabled={true}>Translate</button>
          </div>
          <div className="form-group col-xs-10 col-sm-6 col-md-6 col-lg-6 language-switcher-right">
            <p className="fs-12 label-mb d-block align-left">
              <FormattedMessage id="MESSAGE" />
              &nbsp;|&nbsp;DE
            {this.props.values.type === 'i' && !this.props.values.message_en ?  <sup>*</sup> : ''}
            </p>
            <FormattedMessage id="MESSAGE_IN_GERMAN">
              {(msg) => (
                <textarea
                  id="message_de"
                  name="message_de"
                  label="message_de"
                  value={
                    this.props.values.message_de ? this.props.values.message_de : ""
                  }
                  onChange={this.props.handleChange}
                  rows="4"
                  placeholder={msg}
                  className="w-100 fs-12"
                />
              )}
            </FormattedMessage>

            {/* {this.displayError("message_de")} */}
          </div>
          <div className="form-group w-100 d-flex justify-content-md-center justify-content-start mt-n3">
            {this.displayError("message_de")}
          </div>
          {/* {(this.state.showPeriodicFields || this.props.values.editID !== -1) && this.props.values.recurrence_option && this.props.values.is_periodical_maintenance ?
            <div className="d-flex flex-wrap pt-2 periodic">
              <div className="form-group col-xs-10 col-sm-3 col-md-3 col-lg-3 col-wd start-periodical-maintenance periodic-time"
              >
                <span className="fs-12 label-mb d-block text-transform-initial">
                  <FormattedMessage id="START_TIME" />
                </span>
                <Datetime
                locale="en"
                  closeOnSelect={false}
                  isValidDate={current =>
                    this.blockDates(
                      current,
                      this.props.values.end_date,
                      ""
                    )
                    && this.blockDates(current,
                      this.props.values.end_date, this.props.values.start_date)
                  }
                  value={
                    this.props.values.start_date &&
                      this.props.values.start_date !== "-"
                      ? new Date(
                        moment(this.props.values.start_date).format()
                      )
                      : ""
                  }
                  onChange={momentObj =>
                    this.setDate(momentObj, "start_date")
                  }
                  dateFormat={false}
                  timeFormat={TIME_FORMAT}
                  className="custom-datepicker timepicker"
                />
              </div>
              <div className="form-group col-xs-10 col-sm-3 col-md-3 col-lg-3 col-wd endDate-periodical-maintenance periodic-time" >
                <span className="fs-12 label-mb d-block text-transform-initial">
                  <FormattedMessage id="END_TIME" />
                </span>
                <Datetime
                locale="en"
                  isValidDate={current =>
                    this.blockDates(
                      current,
                      "",
                      this.props.values.start_date
                    )
                    && this.blockDates(current,
                      this.props.values.end_date, this.props.values.start_date)
                  }
                  value={
                    this.props.values.end_date &&
                      this.props.values.start_date !== "-"
                      ? new Date(
                        moment(this.props.values.end_date).format()
                      )
                      : ""
                  }
                  onChange={momentObj =>
                    this.setDate(momentObj, "end_date")
                  }
                  closeOnSelect={false}
                  dateFormat={false}
                  timeFormat={TIME_FORMAT}
                  className="custom-datepicker timepicker"
                />
              </div>
              <div className="form-group col-xs-10 col-sm-3 col-md-3 col-lg-3 col-wd start-periodical-maintenance">
                <span className="fs-12 label-mb d-block text-transform-initial">
                  <FormattedMessage id="RECURRENCE_OPTION" />
                </span>
                <input readOnly={true} className="dates-periodic form-control text-capitalize" type="text"
                  value={this.props.values.recurrence_option}
                  onClick={this.showPeriodicModal}
                />
              </div>
               <div className="form-group col-xs-10 col-sm-12 start-periodical-maintenance recurrence-info">
                <span className="fs-12 label-mb text-transform-initial">
                  <FormattedMessage id="INFORMATION" />:{' '}
                </span>
                <span className="fs-13">{this.renderDetailsText()}</span>
                {this.displayError('recurrence_option_value')}
              </div> 
            </div>
            : null} */}

          {/* <div className="Form-group col-xs-12 col-sm-12 col-md-12 col-lg-12 periodic-enable">
            <div className="fs-12  d-block">

              <input type="checkbox" id="enable-mail" onChange={(e) => this.showMailNotifications(e)}
                checked={this.state.showMailFields} value={this.state.showMailFields} />
              <label htmlFor="enable-mail"></label>
              <label htmlFor="enable-mail" className="ml-5"><FormattedMessage id="ENABLE_MAIL" /></label>
            </div>
          </div> */}
          {/* {this.state.showMailFields ?
            <div className="d-flex flex-wrap pt-2 periodic">

              <div className="Form-group col-xs-10 col-sm-6 col-md-6 col-lg-6 start-periodical-maintenance">
                <span className="fs-12 label-mb d-block text-transform-initial">
                  <FormattedMessage id="RECIPIENT_LIST" />
                </span>
                <input readOnly={true} className="dates-periodic Form-control text-capitalize" type="text"
                  // value={this.props.values.recurrence_option}
                  onClick={this.showPeriodicModal}
                  disabled={true}
                />
                <div className="Form-group col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 mt-3">
                  <span className="fs-12 label-mb  text-transform-initial">
                    <FormattedMessage id="MESSAGE_TEMPLATE_OPTION" />:
                </span>
                  <div className="ml-3 mt-3 d-inline-block">
                    <input type="checkbox" id="default-mail" onChange={(e) => this.setMailTemplateType('default')}
                      checked={this.state.mailTemplateType === 'default'} value={this.state.mailTemplateType} />
                    <label htmlFor="default-mail" className="mb-0"></label>
                    <label htmlFor="default-mail" className="ml-2 mb-0"><FormattedMessage id="DEFAULT" /></label>
                    <input type="checkbox" id="custom-mail" onChange={(e) => this.setMailTemplateType('custom')}
                      checked={this.state.mailTemplateType === 'custom'} value={this.state.mailTemplateType} />
                    <label htmlFor="custom-mail" className="mb-0 ml-3"></label>
                    <label htmlFor="custom-mail" className="ml-2 mb-0"><FormattedMessage id="CUSTOM" /></label>
                  </div>
                </div>
              </div>

              <div className="Form-group col-xs-10 col-sm-6 col-md-6 col-lg-6 language-switcher-left">
                <p className="fs-12 label-mb d-block">
                  <FormattedMessage id="MESSAGE_TEMPLATE" />
                </p>
                <textarea
                  id="message_en"
                  name="message_en"
                  label="message_en"
                  value={
                    this.findMailTemplate
                  }
                  onChange={this.props.handleChange}
                  onBlur={this.props.handleBlur}
                  rows="4"
                  placeholder="Mail template"
                  className="w-100 fs-12"
                  disabled={true}
                />
              </div>

            </div>
            : null} */}

          <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12 periodic-enable">
            {!isEmergencyEntry &&
              <div className="fs-12  d-block">
                <input type="checkbox" id="periodic" onChange={(e) => this.switchPeriodic(e)}
                  checked={this.props.values.is_periodical_maintenance} value={this.props.values.is_periodical_maintenance} />
                <label htmlFor="periodic" />
                <label htmlFor="periodic" className="ml-5 tt-none"><FormattedMessage id="SELECT_IF_PERIODIC" /></label>
              </div>
            }
            {(this.props.values.is_periodical_maintenance && !isEmergencyEntry) && <div className="recurrence-options-form">
              <div className="recurrence-options">
                <div className="options">
                  <div className="mr-4 option">
                    <input type="radio" id="daily" name="recurrence-type" className="default" value="daily"
                      checked={this.props.values.recurrence_option === 'daily'}
                      onChange={() => this.handleRecurrenceOptionChange('daily')}
                    />
                    <label htmlFor="daily" className="type-radio text-capitalize fs-12">
                      <FormattedMessage id="DAILY" />
                    </label>
                  </div>
                  <div className="mr-4 option">
                    <input type="radio" id="weekly" name="recurrence-type" className="default" value="weekly"
                      checked={this.props.values.recurrence_option === 'weekly'} onChange={() => this.handleRecurrenceOptionChange('weekly')} />
                    <label htmlFor="weekly" className="type-radio text-capitalize fs-12">
                      <FormattedMessage id="WEEKLY" />
                    </label>
                  </div>
                  <div className="mr-4 option">
                    <input type="radio" id="monthly" name="recurrence-type" className="default" value="monthly"
                      checked={this.props.values.recurrence_option === 'monthly'} onChange={() => this.handleRecurrenceOptionChange('monthly')} />
                    <label htmlFor="monthly" className="type-radio text-capitalize fs-12">
                      <FormattedMessage id="MONTHLY" />
                    </label>
                  </div>
                </div>
              </div>
              {this.props.values.recurrence_option && <>
                <div className="recurrence-option-details">
                  <div className="fill d-flex jc-center">
                    {this.renderRecurrenceDetails()}
                  </div>
                  {/* {this.renderRecurrenceOptionValidationMsg()} */}
                  <div>
                    {this.displayError("recurrence_option_value")}
                  </div>
                </div>
                <div className="recurrence-details-text">
                  {renderDetailsText(this.props.values.recurrence_option, this.props.values.recurrence_option_value, this.props.values.start_date, this.props.intl.locale)}
                </div>
              </>
              }
            </div>}
          </div>


          <div className="form-group col-xs-10 col-sm-12 col-md-12 col-lg-12">
            <button
              onClick={this.cancel}
              className="btn btn-secondary text-capitalize fs-13 actions"
            >
              <FormattedMessage id="CANCEL" />
            </button>
            <button
              type="submit"
              variant="raised"
              disabled={this.props.isDataSaved}
              title={(this.props.submitCount) ? "Correct the above errors" : ""}
              className="btn btn-primary text-capitalize fs-13 actions"
              onClick={this.saveClicked}
              id="form-submit"
            >
              <FormattedMessage id={this.props.isDataSaved ? "SAVING" : "SAVE"} />
            </button>
            {"edit" in queryString.parse(this.props.location.search) && !isEmergencyEntry ? <span>
              <button
                onClick={this.showDeleteModal}
                type="button"
                className="btn btn-secondary text-capitalize fs-13 actions"
              >
                <FormattedMessage id="DELETE" />
              </button>
            </span> : ''}
            {this.props.values.end_date === '-' && !isEmergencyEntry && !("duplicate" in queryString.parse(this.props.location.search)) ? <span>
              <button
                type="button"
                className="btn btn-secondary text-capitalize fs-13 actions"
                onClick={() => this.showEndEventModal(true)}
              >
                <FormattedMessage id="END_EVENT" />
              </button>
            </span> : ''}
          </div>
        </form>
      </div>
    );
  }
}

export default withRouter(injectIntl(CreateAlert));
