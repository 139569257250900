import React, { Component } from 'react';

import styled from "styled-components";

// This component is shown for invalid routes
export default class PageNotFound extends Component {
  render() {
    const DIV = styled.div`
        position: fixed;
        top: 0px;
        left: 0;
        right: 0;
        bottom: 0;
        background: white;
        z-index: 9999990;  
        padding: 10px;
        color: black;       
        `
    return (
      <DIV className="PageNotFound" >
       404 Page not found
      </DIV>
    )
  }
}
