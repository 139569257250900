import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PlusIcon from './../../assets/icons/Plus.svg';
import Select from "react-select";
import axiosInstance from '../../utlities/Interceptor';
import ApiPath from '../../constants/ApiPath';
import { SELECT_COLOUR_STYLES } from '../../utlities/ReactSelectFunctions';
import WarningPopup from '../WarningPopup/WarningPopup';
import EntitlementsContext from '../../contexts/EntitlementsContext';
import { entitlements } from '../../constants/Entitlements';
import './AddNewItemForm.scss';

export default class AddNewItemForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownList: [],
      default: '',
      apiLoaded: false,
      isOpen: true,
      enableDataEntry: false,
      onLoad: true
    };
    this.displayError = this.displayError.bind(this);
    this.setSelectValue = this.setSelectValue.bind(this);
    this.getDropdownList = this.getDropdownList.bind(this);
    if (this.props.dropdownAPI)
      this.getDropdownList();
  }
  componentDidUpdate(prevProps) {
    if (this.props.isDataSaved !== prevProps.isDataSaved && this.props.isDataSaved === false) {
      this.setSelectValue(this.state.dropdownList[0], 'dropdownList')
    }
    if (this.context.entitlements.length > 0 && entitlements['componentsCreation'].includes(this.context.entitlements) && this.state.onLoad) {
      this.setState({ enableDataEntry: true, onLoad: false })

    }
  }
  displayError(field) {
    if (this.props.errors[field] === "REQUIRED") {
      // required message has to be shown only if submit button clicked
      if (this.props.values.isSubmitting && this.props.errors[field]) {
        return (
          <div className="errors-text fs-12">
            <FormattedMessage id={this.props.errors[field]} />
            {this.props.values.isSubmitting ? <WarningPopup isOpen={this.state.isOpen} toggle={this.closePopup} message="ERROR_WARNING" class="error" /> : ''}
          </div>
        )
      }
    }
    else if (this.props.touched[field] && this.props.errors[field] && this.props.values.isSubmitting) {
      return (
        <div className="errors-text fs-12">
          <FormattedMessage id={this.props.errors[field]} />
          {this.props.values.isSubmitting ? <WarningPopup isOpen={this.state.isOpen} toggle={this.closePopup} message="ERROR_WARNING" class="error" /> : ''}
        </div>
      )
    }
  }

  getDropdownList() {
    axiosInstance.get(ApiPath[this.props.dropdownAPI]).then(res => {
      let categoryList = res.data;
      this.setState({ apiLoaded: true });
      let transformed = categoryList.map((name) => ({
        label: name,
        value: name
      }));
      this.setState({
        dropdownList: transformed,
        default: transformed[0],
        apiLoaded: false
      });
      this.setSelectValue(transformed[0], 'dropdownList')
    });
  }

  setSelectValue(data, field) {
    this.props.setFieldTouched(field, true);
    this.props.setFieldValue(field, data);
  }

  saveClicked = () => {
    this.setState({ isOpen: true })
    this.props.setFieldValue('isSubmitting', true)
  }
  closePopup = () => {
    this.setState({ isOpen: false })
  }
  render() {
    return (
      <form onSubmit={this.props.handleSubmit} className="add-new-item-form d-flex flex-wrap">
        <div className={`form-group col-xs-12 col-sm-12 col-md-4 ${!!(this.props.dropdownAPI && this.props.dropdownAPI.length && this.state.dropdownList.length) ? 'col-lg-5' : 'col-lg-4'} pl-3 pr-0 mb-0 mr-3`}>
          <span className={`fs-12 mt-2 label-mb d-block ${this.props.touched.item_name && Boolean(this.props.errors.item_name) && 'is-invalid'}`}>
            <FormattedMessage id={this.props.textLabel} />
            <sup>*</sup>
          </span>
          <input
            id="item_name"
            name="item_name"
            label="item_name"
            autoComplete="off"
            type="text"
            className={`form-control ${this.props.touched.item_name && Boolean(this.props.errors.item_name) && 'is-invalid'}`}
            value={this.props.values.item_name ? this.props.values.item_name : ''}
            disabled={!this.state.enableDataEntry}
            onChange={this.props.handleChange}
            onBlur={this.props.handleBlur}
          />

          {this.displayError('item_name')}
        </div>
        {!!(this.props.dropdownAPI && this.props.dropdownAPI.length && this.state.dropdownList) &&
          <div className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-5 pl-2 mb-0">
            <span className="fs-12 mt-2 label-mb d-block"><FormattedMessage id={this.props.dropdownLabel} /></span>
            <Select
              defaultValue={[this.state.dropdownList[0]]}
              value={this.props.values.dropdownList}
              options={this.state.dropdownList}
              onChange={data =>
                this.setSelectValue(data, "dropdownList")
              }
              isDisabled={!this.state.enableDataEntry}
              // closePopupMenuOnSelect={true}
              className="custom-multi-select"
              styles={SELECT_COLOUR_STYLES}
              onInputKeyDown={this.props.onInputKeyDown}
            />

            {(this.state.dropdownList.length === 0) && (this.state.apiLoaded) ?
              <div className="errors-text fs-12">
                <FormattedMessage id="REQUIRED" />
              </div> : ''
            }
          </div>
        }
        <div className="form-group col-xs-10 col-sm-12 col-md-3 col-lg-2 mb-0 p-0 pr-3 text-left">
          <span className="fs-12 mt-2 label-mb d-block">&nbsp;</span>
          <button
            type="submit"
            disabled={this.props.isDataSaved
              || (this.props.submitCount && !this.props.isValid)
              || !this.state.enableDataEntry
              || !this.props.values.item_name}
            className="btn btn-primary text-capitalize fs-13 add-item-btn"
            onClick={this.saveClicked}
          >
            {this.props.saveBtnText === "SAVE" && <FormattedMessage id={this.props.isDataSaved ? "SAVING" : "SAVE"} />}
            {this.props.saveBtnText !== "SAVE" &&
              <>
                <img src={PlusIcon} alt="add" className="pr-16 desktop-only" />
                <span className="desktop-only"><FormattedMessage id={this.props.saveBtnText} /></span>
                <span className="text-capitalize mobile-only"><FormattedMessage id={this.props.isDataSaved ? "SAVING" : "SAVE"}
                /></span>
              </>
            }
          </button>
        </div>
      </form>
    )
  }
}
AddNewItemForm.contextType = EntitlementsContext;