import {token_url} from "./OpenIdConfig";

const baseURL = process.env.REACT_APP_API_BASE_URL;
const URL = process.env.REACT_APP_BASE_URL;
const logoutRedirect = process.env.REACT_APP_LOGOUT_URL;

export const ApiPath = {
  login: `${URL}backend/login`,
  list: `${baseURL}alert-maintenance`,
  system: `${baseURL}system`,
  category: `${baseURL}category`,
  email_Content: `${baseURL}email-template`,
  function_category: `${baseURL}function-category`,
  function_category_systems: `${baseURL}function-category-system`,
  communication_status: `${baseURL}communication-status`,
  xmlRequest: `${URL}api/workshop-maintenance-service-info`,
  getToken: `${token_url}`,
  userInfo: `${baseURL}who-is-who`,
  authorization: `${baseURL}authorization`,
  awsLogout: `${baseURL}cognitologout`,
  daimlerLogout: `${baseURL}daimlerlogout`,
  send_email: `${baseURL}communication-status/send-email`,
  isAuthenticated: `${baseURL}is-authenticated`,
  openIdGlobalLogout: "https://sso.mercedes-benz.com/idp/startSLO.ping",
  logout: `${baseURL}logout`,
  logoutRedirect: `${logoutRedirect}`,
};

export default ApiPath;
