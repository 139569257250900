import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Redirect } from 'react-router';

import { HeaderContext } from '../index';
import { AlertDataContext } from '../contexts/AlertDataContext';
import List from '../pages/List/List';
import PageNotFound from '../pages/PageNotFound/PageNotFound';
import CreateAlert from '../pages/CreateAlert/CreateAlert';
import Systems from '../pages/Systems/Systems';
import Category from '../pages/Category/Category';
import FunctionCategory from '../pages/FunctionCategory/FunctionCategory';
import SystemFunctionMapping from '../pages/SystemFunctionMapping/SystemFunctionMapping';
import ApiValidator from '../pages/ApiValidator/ApiValidator';
import Email from '../pages/Email/Email';
import OAuth from '../pages/oAuth';

export default class Routing extends Component {
  constructor(props) {
    super(props);
    this.setData = (data) => {
      this.setState(state => ({
        alertData: data
      }));
    }
    this.setDelete = (data, id) => {
      this.setState(state => ({
        showDeletePopup: data,
        deleteId: id
      }));
    }
    this.endEvent = (show, data) => {
      this.setState(state => ({
        showEndEventPopup: show,
        endData: data
      }));
    }

    this.state = {
      alertData: {},
      userInfo: '',
      setData: this.setData,
      setDelete: this.setDelete,
      endEvent: this.endEvent,
      showDeletePopup: false
    };
  }

  render() {
    return (
      <HeaderContext.Consumer>
        {({ isMenuOpen }) => (
          <div className={isMenuOpen ? 'side-nav-margin' : 'side-nav-close-margin'}>
            <AlertDataContext.Provider value={this.state}>
              <Switch>
                {/* <React.StrictMode> */}
                <Route exact path='/list' component={List} />
                <Route exact path='/alert' component={CreateAlert} />
                <Route exact path='/systems' component={Systems} />
                <Route exact path='/category' component={Category} />
                <Route exact path='/function_categories' component={FunctionCategory} />
                <Route exact path='/mapping' component={SystemFunctionMapping} />
                <Route exact path='/api-validator' component={ApiValidator} />
                <Route exact path='/email' component={Email} />
                // <Route exact path="/oauth2/idpresponse" component={OAuth} />
                <Redirect exact path='/' to='/list' />
                <Route component={PageNotFound} />
                {/* </React.StrictMode> */}
              </Switch>
            </AlertDataContext.Provider>
          </div>
        )}
      </HeaderContext.Consumer>
    )
  }
}