import React,{ useEffect } from "react";

function OAuth() {
    const queryString = require('query-string');
    const { code, state } = queryString.parse(window.location.search);
    console.log(code)
    console.log(state)
    useEffect(() => {
        window.location = `/alb/?code=${code}&state=${state};`
    }, [])
    return(<></>)
}
export default OAuth;