import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import './GenericPopup.scss';

class GenericPopup extends Component {
  render() {
    return (
      <div className="delete-confirmation">
        <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} className="generic-modal" centered>
          <ModalHeader toggle={this.props.toggle}><FormattedMessage id={this.props.header} /></ModalHeader>
          <ModalBody>
            <FormattedMessage id={this.props.message} />
          </ModalBody>
          <ModalFooter className="d-flex flex-wrap">
            <Button color="cancel" onClick={this.props.toggle} size="modal">
              <FormattedMessage id={this.props.cancelTag} defaultMessage="Cancel" />
            </Button>
            <Button color="primary" onClick={this.props.onSubmit} disabled={this.props.isLoading} size="modal">
                <FormattedMessage id={this.props.okTag} defaultMessage="Delete" />
              {this.props.isLoading && <div className="lds-dual-ring white" />}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}

export default GenericPopup;