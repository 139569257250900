import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { FormattedMessage } from "react-intl";
import { Redirect } from "react-router";
import styled from 'styled-components';

import Plus from "./../../assets/icons/Plus.svg";
import ApiPath from "../../constants/ApiPath";
import axiosInstance from "../../utlities/Interceptor";
import { columns } from "./TableFunctions";
import EntitlementsContext from '../../contexts/EntitlementsContext';
import { entitlements } from '../../constants/Entitlements';
import AlertDataContext from '../../contexts/AlertDataContext';
import DeleteConfirmation from '../../components/DeleteConfirmation/DeleteConfirmation';
import GenericPopup from '../../components/popups/GenericPopup/GenericPopup';
import "./List.scss";
import * as moment from 'moment';
import * as _ from 'lodash';
import CommunicationPossibleContext from '../../contexts/CommunicationPossibleContext';

/*
  List component shows the list of all alerts. Each alert has actions: edit, delete, duplicate. Alert with no end date has
  action to end the event. These actions are enabled only for users with entitlement for data entry
 */
export default class List extends Component {
  constructor(props) {
    super(props);
    this.newAlert = this.newAlert.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.checkEndOfList = this.checkEndOfList.bind(this);
    this.state = {
      alertList: [],
      totalCount: 0,
      toNewAlert: false,
      pagination: "-1",
      isLoading: true,
      isEndOfList: false,
      isCommunicationPossible: true,
      showWarningPopup: false,
      onLoad: true,
      isAuthorized: false,
      activeCount: 0,
      plannedCount: 0,
      emergencyCount: 0,
      expiredList: [],
      paginatedCount: 10,
    };
  }

  componentDidMount() {
    this.getAlerts();
  }
  componentDidUpdate() {
    if (this.context.entitlements.length > 0 && entitlements["newAlert"].includes(this.context.entitlements) && this.state.onLoad) {
      this.setState({ isAuthorized: true, onLoad: false })
    }
  }
  // Function calls the get api to fetch all the alerts
  getAlerts = () => {
    axiosInstance.get(`${ApiPath.list}`).then(res => {
      let alertList = [...res.data.result];
      const lastItem = res.data.last_item;
      this.setState({
        alertList: alertList,
        pagination: lastItem,
        isLoading: false,
        totalCount: res.data.total_count,
        activeCount: res.data.active_count,
        plannedCount: res.data.planned_count
      });
      // find expired alerts and show it at end of list
      let expiredList = [];
      let activeList = [];
      alertList.forEach((each, index) => {
        if (each.is_expired) {
          expiredList.push(each)
        }
        else {
          activeList.push(each);
        }
      })
      // Check if there is an active emergency alert
      if (_.some(alertList, (el) => { return (el.id && el.id.includes('emergency_entry') && !el.is_expired) }))
        this.setState({ emergencyCount: 1 });

      alertList = [...activeList, ...expiredList];

      this.setState({ alertList: alertList, expiredList: expiredList })
      this.checkEndOfList();
    })
  }
  newAlert() {
    this.setState({
      toNewAlert: true
    });
  }
  /*
   Function calls the api to load more results. Last item obtained from last get api is saved and sent in the next api call
   as parameter for pagination
  */
  loadMore() {
    // this.setState({
    //   isLoading: true
    // });
    // axiosInstance.get(`${ApiPath.list}?${this.state.pagination}&`).then(res => {
    //   let alertList = this.state.alertList;
    //   let newAlerts = res.data.result.slice();
    //   // remove expired items at the end from current list
    //   alertList = this.state.alertList;
    //   alertList.splice(this.state.alertList.length - this.state.expiredList.length, this.state.expiredList.length)
    //   // Find expired list
    //   let expiredList = this.state.expiredList.slice();
    //   let newArr = [];
    //   newAlerts.forEach((each, index) => {
    //     if (each.is_expired) {
    //       expiredList.push(each)
    //     } else {
    //       newArr.push(each)
    //     }
    //   })
    //   const lastItem = res.data.last_item;
    //   this.setState(prevState => ({
    //     alertList: [...alertList, ...newArr]
    //   }));
    //   this.setState({
    //     pagination: lastItem,
    //     isLoading: false
    //   });
    //   // push expired items to the end of alertlist
    //   alertList = this.state.alertList.concat(expiredList)
    //   this.setState({ alertList: alertList, expiredList: expiredList })
    //   this.checkEndOfList();

    // });
  }
  // Function checks whether the end of list has reached.
  checkEndOfList() {
    if (this.state.pagination == null || (this.state.alertList.length === this.state.totalCount)) {
      this.setState({
        isEndOfList: true
      });
    }
  }
  closeWarningPopup = () => {
    this.setState({ showWarningPopup: false });
  }

  isActiveEmergency = obj => obj.id && obj.id.includes('emergency_entry') && !obj.is_expired;

  findClasses = (row, rowIndex) => {
    let classes = 'custom-row-class';

    // Single Conditions 
    if (this.context.language.code === 'de')
      classes += ' german';
    if (this.context.isMenuOpen) {
      classes += ' SideNav-open'
    }
    if (!this.state.isAuthorized) {
      classes += ' unauthorized'
    }

    // One-of Conditions
    if (!!row.is_expired) {
      classes += ' expired';
    }
    else {
      classes += ' upcoming';
      if (this.isActiveEmergency(row)) {
        classes += ' emergency';
      }
      else if (row.status === 'active') {
        classes += ' active';
      }
    }
    return classes;
  }
  deleteAlert = (data, setDelete) => {
    this.setState({
      isDeleting: true
    });
    const body = { maintenance_id: data.id, created_date: data.created_date }
    axiosInstance.delete(`${ApiPath.list}`, { data: body })
      .then(res => {
        setDelete(false)
        this.setState({ isDeleting: false })
        let index = this.state.alertList.findIndex(x => x.id === data.id);
        //On deletion, update the list, total count, active and planned count
        if (this.state.alertList[index].status === "active") {
          this.setState({ activeCount: this.state.activeCount - 1 })
        } else if (this.state.alertList[index].status === "planned") {
          this.setState({ plannedCount: this.state.plannedCount - 1 })
        }
        this.state.alertList.splice(index, 1)
        this.setState({ alertList: this.state.alertList })
        this.setState({ totalCount: this.state.totalCount - 1 })
        this.checkEndOfList()
      })
      .catch(error => {
        this.setState({
          isDeleting: false,
          showDeleteModal: false,
        });
      });
  }
  handleEndEvent = (data, endEvent) => {
    this.setState({
      isEnding: true
    });
    const updatedData = {
      ...data,
      maintenance_id: data.id,
      publication_date: moment.utc().format(),
      end_date: moment.utc().format()
    }
    axiosInstance.put(ApiPath.list, updatedData)
      .then(res => {
        endEvent(false);
        this.setState({
          isEnding: false
        });
        this.props.history.push('/');
        this.props.history.push('/list');
      })
      .catch(err => {
        this.setState({
          isEnding: false
        });
      })
  }
  toggleDeleteModal = (setDelete) => {
    setDelete(false)
  }
  groupAlertsByStatus = () => {
    const { alertList } = this.state;
    if (!alertList || !alertList.length)
      return [];
    const groupedAlerts = _.groupBy(alertList, (el) => {
      // if (el.type === 'e')
      //   return 'emergency';
      // else if (el.is_expired)
      //   return 'expired';
      // else if (el.status === 'active' || el.status === 'planned')
      //   return el.status;
      if (el.is_expired)
        return 'expired';
      else if (this.isActiveEmergency(el))
        return 'emergency';
      else if (el.status === 'active' || el.status === 'planned')
        return el.status;
    });
    const groupedList = [
      ...(groupedAlerts.emergency ? groupedAlerts.emergency : []),
      ...(groupedAlerts.active ? groupedAlerts.active : []),
      ...(groupedAlerts.planned ? groupedAlerts.planned : []),
      ...(groupedAlerts.expired ? groupedAlerts.expired : []),
    ];
    return groupedList;
    // return _.slice([
    //   ...(groupedAlerts.emergency ? groupedAlerts.emergency : []),
    //   ...(groupedAlerts.active ? groupedAlerts.active : []),
    //   ...(groupedAlerts.planned ? groupedAlerts.planned : []),
    //   ...(groupedAlerts.expired ? groupedAlerts.expired : []),
    // ], 0, this.state.paginatedCount);
  }
  // getExpiredListCount = (isCommunicationPossible) => {
  //   let expiredCount = this.state.totalCount - (this.state.activeCount + this.state.plannedCount);
  //   if (!isCommunicationPossible && expiredCount)
  //     expiredCount = expiredCount - 1;
  //   return expiredCount;
  // }
  getExpiredListCount = () => this.state.totalCount - (this.state.activeCount + this.state.plannedCount + this.state.emergencyCount);

  render() {
    /**IE fix for table */
    const DIV = styled.div`
      table {
        border-collapse: separate !important;
      }
    `;
    if (this.state.toNewAlert === true) {
      return <Redirect push to="/alert" />;
    }
    return (
      <AlertDataContext.Consumer>
        {({ setData, setDelete, showDeletePopup, showEndEventPopup, endEvent, endData, deleteId }) => (
          <div className="list-container" >
            <DeleteConfirmation isOpen={showDeletePopup} toggle={() => this.toggleDeleteModal(setDelete)}
              deleteRow={() => this.deleteAlert(deleteId, setDelete)} isDeleting={this.state.isDeleting}
              deleteText="YOU_ARE_ABOUT_TO_DELETE_AN_ALERT_ARE_YOU_SURE" />
            <GenericPopup
              isOpen={showEndEventPopup}
              toggle={() => { endEvent(false) }}
              onSubmit={() => { this.handleEndEvent(endData, endEvent) }}
              header="CONFIRM"
              okTag="END_EVENT"
              message="CONFIRM_END_EVENT"
              cancelTag="CANCEL"
              isLoading={this.state.isEnding}
            />
            <div>
              <div className="mb-4 d-flex mt-4 w-100 pl-3">
                <div className="left">
                  <span className="heading text-capitalize">
                    <FormattedMessage id="ALERTS" defaultMessage="alerts" />
                  </span>
                  <div className="count">
                    <span className="emergency">
                      <FormattedMessage id="EMERGENCY" defaultMessage="Emergency" />: {this.state.emergencyCount}&nbsp;
                      <svg width="12" height="12" className="ml-1">
                        <rect width="12" height="12" />
                      </svg>
                    </span>
                    <span className="ml-3 active">
                      <FormattedMessage id="ACTIVE" defaultMessage="Active" />: {this.state.activeCount}&nbsp;
                      <svg width="12" height="12" className="ml-1">
                        <rect width="12" height="12" />
                      </svg>
                    </span>
                    <span className="ml-3 planned">
                      <FormattedMessage id="PLANNED" defaultMessage="Planned" />: {this.state.plannedCount}&nbsp;
                      <svg width="12" height="12" className="ml-1">
                        <rect width="12" height="12" />
                      </svg></span>
                    <span className="ml-3 expired">
                      <FormattedMessage id="EXPIRED" />: {this.getExpiredListCount()}&nbsp;
                      <svg width="12" height="12" className="ml-1">
                        <rect width="12" height="12" />
                      </svg>
                    </span>
                  </div>
                </div>
                <div className="right-align">
                  <button className="btn btn-primary fs-13 btn-generic" onClick={this.newAlert} disabled={!this.state.isAuthorized}>
                    <img src={Plus} alt="" className="pr-2" />
                    <FormattedMessage id="NEW_ALERTS" defaultMessage="New Alert" />
                  </button>
                </div>
              </div>
              <hr />
              <DIV className={"w-100 pl-3 last-row-sticky"}>
                <BootstrapTable
                  keyField="id"
                  data={this.groupAlertsByStatus()}
                  columns={columns}
                  bordered={false}
                  bootstrap4={true}
                  rowClasses={this.findClasses}
                  headerClasses={this.context.language.code === "de" ? 'de ' : ''}
                />
                <div className="d-flex justify-content-center flex-column">
                  <div className="text-center">
                    {this.state.alertList.length}&nbsp;
                        <FormattedMessage id="OF" />
                    &nbsp;
                        {this.state.totalCount > this.state.alertList.length &&
                      this.state.alertList.length !== 0
                      ? this.state.totalCount
                      : this.state.alertList.length}
                  </div>
                  {!this.state.paginatedCount === parseInt(this.state.totalCount) && (
                    <div className="text-center">
                      <span
                        className="load-more text-capitalize pointer btn text-center"
                        onClick={this.loadMore}
                        disabled={this.state.paginatedCount === parseInt(this.state.totalCount) || this.state.isLoading}
                      >
                        <FormattedMessage
                          id={
                            this.state.isLoading
                              ? "LOADING"
                              : this.state.paginatedCount === parseInt(this.state.totalCount) && "LOAD_MORE"
                                ? "END_OF_LIST"
                                : "LOAD_MORE"
                          }
                        />
                        {this.state.isLoading && <div className="lds-dual-ring" />}
                      </span>
                    </div>
                  )}
                </div>
              </DIV>
            </div>

          </div>
        )}
      </AlertDataContext.Consumer>
    );
  }

}
List.contextType = EntitlementsContext;

