import { Formik } from "formik";
import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { FormattedMessage, injectIntl } from "react-intl";
import styled from 'styled-components';
import * as Yup from "yup";

import Delete from "../../assets/icons/Delete.svg";
import Plus from "../../assets/icons/Plus.svg";
import Close from "../../assets/icons/Plus_1.svg";
import Search from "../../assets/icons/Search.svg";
import AddNewItemForm from "../../components/AddNewItemForm/AddNewItemForm";
import DeleteConfirmation from "../../components/DeleteConfirmation/DeleteConfirmation";
import ApiPath from "../../constants/ApiPath";
import { ALLOWED_TEXT } from "../../constants/Constants";
import { customTableHeader } from "../../utlities/BootstrapTableFunctions";
import axiosInstance from "../../utlities/Interceptor"
import EntitlementsContext from '../../contexts/EntitlementsContext';
import { entitlements } from '../../constants/Entitlements';
import './FunctionCategory.scss';

export const columns = [
  {
    dataField: "function",
    text: "FUNCTION",
    headerFormatter: (column, colIndex, { sortElement, filterElement }) =>
      customTableHeader(column, colIndex, { sortElement, filterElement })
  },
  {
    dataField: "category",
    text: "CATEGORY",
    headerFormatter: (column, colIndex, { sortElement, filterElement }) =>
      customTableHeader(column, colIndex, { sortElement, filterElement })
  },
  {
    dataField: "actions",
    isDummyField: true,
    text: "",
    formatter: (cellContent, row) => {
      return (
        <span className="d-flex justify-content-center">
          <img src={Delete} alt="" className="pointer delete" />
        </span>
      );
    }
  }
];

/*
  FunctionCategory component lets the users to view, create and delete function categories. Any user with entitlement startAuthorisation can
  view the page. Only the users with componentsCreation can create or delete function categories
*/

class FunctionCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fcategoryList: [],
      fcategoryListFiltered: [],
      isLoading: true,
      values: {
        item_name: "",
        dropdownList: ""
      }
    };
    this.formik = React.createRef();
    this.searchInput = React.createRef();
    Yup.addMethod(Yup.string, "noDuplicate", this.noDuplicate);
  }
  componentDidMount() {
    axiosInstance.get(ApiPath.function_category).then(res => {
      let fcategoryList = res.data;
      fcategoryList.sort(function (a, b) {
        return a.function.localeCompare(b.function);
      });
      this.setState({
        fcategoryList: fcategoryList,
        fcategoryListFiltered: fcategoryList,
        isLoading: false
      });
    });
  }
  noDuplicate(ref, ref2, msg) {
    // test function returns false if new value is a duplicate
    return this.test('noDuplicate', msg, function (value) {
      if (value && this.resolve(ref2)) {
        let functionValue = value.toString().toUpperCase();
        let category = this.resolve(ref2).value;
        let check = this.resolve(ref).find(
          item => (item.function === functionValue && item.category === category)
        )
        if (check)
          return false;
        return true;
      }
      return true;

    });
  }

  submitValues = (data) => {
    // Submit the Form of function category creation. Function calls the post api.
    if (!data.dropdownList) {
      return;
    }
    this.setState({
      isSubmitting: true
    });
    let postData = {
      function: (data.item_name).trim().toUpperCase(),
      category: (data.dropdownList.value).trim().toUpperCase(),
    }
    axiosInstance.post(ApiPath.function_category, postData)
      .then(res => {
        const timestamp = Date.now();
        postData = { ...postData, id: timestamp, created_by: true }
        this.newRow(postData);
        if (this.formik.current) {
          this.formik.current.resetForm();
        }
        this.setState({
          isSubmitting: false,
          popupActive: false,
        });
      })
      .catch(error => {
        this.setState({
          isSubmitting: false,
          popupActive: false,
        });
      });
  }

  newRow = (data) => {
    // Push the new function category into the array and sorts the array in alphabetical order
    this.setState(prevState => ({
      fcategoryList: [data, ...prevState.fcategoryList],
      fcategoryListFiltered: [data, ...prevState.fcategoryListFiltered]
    }))
    this.state.fcategoryList.sort(function (a, b) {
      return a.function.localeCompare(b.function);
    });
    this.state.fcategoryListFiltered.sort(function (a, b) {
      return a.function.localeCompare(b.function);
    });
  }

  deleteRow = () => {
    // Delete a function category. Functions the delete api by appending the function name and category name in the api url

    this.setState({
      isDeleting: true
    });
    axiosInstance.delete(`${ApiPath.function_category}?function=${this.state.rowData.function}&category=${this.state.rowData.category}`)
      .then(res => {
        this.setState({
          isDeleting: false,
          showDeleteModal: false,
        });
        if (res.status === 200) {
          const filteredItems = this.state.fcategoryList.filter(item =>
            (item.function !== this.state.rowData.function || item.category !== this.state.rowData.category)
          )
          if (this.searchInput.current) {
            this.searchInput.current.value = ''
          }
          this.setState({
            fcategoryList: [...filteredItems],
            fcategoryListFiltered: [...filteredItems]
          })
        }
      })
      .catch(error => {
        this.setState({
          isDeleting: false,
          showDeleteModal: false,
        });
      });
  }

  deleteItem = (rowData) => {
    // Enables the delete functionality only for users with entitlement for components creation
    if (this.context.entitlements.length > 0 && entitlements['componentsCreation'].includes(this.context.entitlements)) {
      this.setState({
        showDeleteModal: true,
        rowData: rowData
      });
    }
  }

  toggleDeleteModal = () => {
    // function that enables to toggle the delete modal
    this.setState({
      showDeleteModal: !this.state.showDeleteModal
    });
  }



  popupHandler = () => {
    this.setState({ popupActive: !this.state.popupActive });
  }

  search = () => {
    /* Search for a given input name among the existing function category list. Checks whether given search input is included in 
       category name or function name of any function category
    */
    if (!this.searchInput.current) {
      return
    }
    if (this.searchInput.current.value.length === 0) {
      this.setState({
        fcategoryListFiltered: this.state.fcategoryList,
        isLoading: false
      });
    }
    let value = (this.searchInput.current.value).toString().toUpperCase();
    const filteredItems = this.state.fcategoryList.filter(item => (item.category.includes(value) || item.function.includes(value)))

    this.setState({
      fcategoryListFiltered: filteredItems,
      isLoading: false
    });
  }
  onInputKeyDown(event) {
    // Inorder to enable submit of Form on pressing enter, the default function of enter is disabled by event.preventDefault
    switch (event.keyCode) {
      case 13: // ENTER
        event.preventDefault();
        break;
      default: break;
    }
  }
  findClasses = (row, rowIndex) => {
    // find the style classes according to the status and entitlement
    let classes = "custom-row-class";
    if (!!row.created_by) {
      classes += " upcoming";

    }
    if (this.context.entitlements.length > 0 && entitlements['componentsCreation'].includes(this.context.entitlements)) {
      classes += ' enabled';
    }
    else {
      classes += ' disable-delete';
    }
    return classes;
  };
  render() {
    /**IE fix for table */
    const DIV = styled.div`
      padding-top: 20px;
      table {
        border-collapse: separate !important;
        th, td {
          padding-left: 30px;
         }
      }
    `;
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        if ((e.target.classList.contains('delete') || (e.target.parentElement && e.target.parentElement.classList.contains('delete'))))
          this.deleteItem(row)
      }
    }
    const validationSchema = Yup.object({
      item_name: Yup.string("enter name")
        .matches(ALLOWED_TEXT, { message: 'NO_SPECIAL_CHARA', excludeEmptyString: true })
        .noDuplicate(this.state.fcategoryList, Yup.ref('dropdownList'), 'NO_DUPLICATE_FUNCTION_ALLOWED')
        .required("REQUIRED"),
    });
    return (
      <div className="function-category page-padding">
        <DeleteConfirmation isOpen={this.state.showDeleteModal} toggle={this.toggleDeleteModal} deleteRow={this.deleteRow} isDeleting={this.state.isDeleting} deleteText="YOU_ARE_ABOUT_TO_DELETE_FUNCTION_CATEGORY_ARE_YOU_SURE"></DeleteConfirmation>
        <div className="mb-4 d-flex mt-4 w-100 px-3 flex-wrap heading-text-div">
          <span className="heading text-capitalize fs-16 page-header col-sm-12 col-md-7 col-lg-10 p-0">
            <FormattedMessage id="FUNCTION_CATEGORIES" />
          </span>

          <div className="input-group flex-nowrap col-10 col-sm-8 col-md-5 col-lg-2 p-0">
            <input type="text" className="form-control"
              placeholder={this.props.intl.formatMessage({ id: 'SEARCH' })}
              ref={this.searchInput}
              onChange={e => this.search(e.target.value)}
              aria-label="Search" aria-describedby="addon-wrapping" />
            <div className="input-group-append pointer" onClick={this.search}>
              <span className="input-group-text search-icon" id="addon-wrapping"><img src={Search} alt="" /></span>
            </div>
          </div>
          <div className="d-flex col-2 p-0">
            <button className="plus-btn ml-2" onClick={this.popupHandler}>
              <img src={Plus} alt="Plus" />
            </button>
          </div>
        </div>
        <hr />
        <div className={"add-new-item" + (this.state.popupActive ? " active" : "")}>
          <span className="heading text-capitalize fs-16 mobile-only popup-header">
            <FormattedMessage id="FUNCTION_CATEGORIES" defaultMessage="FUNCTION CATEGORIES" />
            <button onClick={this.popupHandler}>
              <img src={Close} alt="close" />
            </button>
          </span>
          <Formik
            ref={this.formik}
            render={props =>
              <AddNewItemForm {...props} isDataSaved={this.state.isSubmitting}
                textLabel="NAME_OF_NEW_FUNCTION_CATEGORY"
                dropdownLabel="CHOOSE_CATEGORY"
                saveBtnText="NEW_FUNCTION_CATEGORY"
                dropdownAPI="category"
                plusIcon={true}
                onInputKeyDown={this.onInputKeyDown}
              />
            }
            initialValues={this.state.values}
            validationSchema={validationSchema}
            onSubmit={this.submitValues}
          />
        </div>
        <DIV className="w-100 px-3">
          <BootstrapTable
            keyField="id"
            data={this.state.fcategoryListFiltered}
            columns={columns}
            bordered={false}
            bootstrap4={true}
            rowClasses={this.findClasses}
            rowEvents={rowEvents}
          />

          {(this.state.fcategoryList.length === 0 && !this.state.isLoading) &&
            <div className="text-center">
              <span className="load-more text-capitalize pointer btn text-center" >
                <FormattedMessage id="NO_DATA" />
              </span>
            </div>
          }
          {(this.state.fcategoryListFiltered.length === 0 && this.state.fcategoryList.length !== 0 && !this.state.isLoading) &&
            <div className="text-center">
              <span className="load-more text-capitalize pointer btn text-center" >
                <FormattedMessage id="NO_SEARCH_DATA" />
              </span>
            </div>
          }
          {this.state.isLoading && (
            <div className="text-center">
              <span className="load-more text-capitalize pointer btn text-center">
                <FormattedMessage id="LOADING" />
                {this.state.isLoading && <div className="lds-dual-ring" />}
              </span>
            </div>
          )}
        </DIV>
      </div>
    );
  }
}

export default injectIntl(FunctionCategory);
FunctionCategory.contextType = EntitlementsContext;