import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next/lib/src/bootstrap-table';
import { FormattedMessage, injectIntl } from 'react-intl';
import styled from 'styled-components';

import Search from "../../assets/icons/Search.svg";
import ApiPath from '../../constants/ApiPath';
import axiosInstance from '../../utlities/Interceptor';
import Checkbox from './Checkbox/Checkbox';
import tableHeaderTranslation from '../List/TableFunctions';
import EntitlementsContext from '../../contexts/EntitlementsContext';
import { entitlements } from '../../constants/Entitlements';
import './SystemFunctionMapping.scss';

/*
  SystemFunctionMapping component lets the users to view and edit the mapping between function-category and system. 
  Any user with entitlement startAuthorisation can view the page. Only the users with componentsCreation can edit
  the mapping.
*/

class SystemFunctionMapping extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mappingList: [],
      filteredmappingList: [],
      systemList: [],
      isLoading: true,
      isDataSaved: false,
      columns: [],
      onLoad: true
    };
    this.searchInput = React.createRef();
  }
  componentDidMount() {
    this.getMappings();
    this.getSystems();
  }
  componentDidUpdate() {
    // only users with entitlement componentsCreation can edit the mapping
    if (this.context.entitlements.length > 0 && entitlements['componentsCreation'].includes(this.context.entitlements) && this.state.onLoad) {
      this.setState({enableDataEntry: true, onLoad: false})
    }
  }
  toggleSystems = (checkboxValue, rowData, col) => {
    this.setState({
      isSaving: true,
    });
    let originalRow = this.state.mappingList.find((item) => item.function === rowData.function 
          && item.category === rowData.category);
    let index = this.state.mappingList.findIndex((item) => item.function === rowData.function 
    && item.category === rowData.category);
    let systems = originalRow.systems;
    if (checkboxValue) {
      systems.push(col)
    } else {
      const result = systems.filter(system => col !== system);
      systems = [...result]
    }
    this.updateRowData(rowData.id, systems, index)
    this.updateSystems(rowData.id, col, originalRow)
  }
  // to update the mapping: category, function and system name is passed
  updateSystems = (id, system, originalRow) => {
    let body = {system: system, category: originalRow.category, function: originalRow.function}
    axiosInstance.put(`${ApiPath.function_category_systems}`, body)
      .then(res => {
        this.setState({
          isSaving: false,
        });
      })
      .catch(error => {
        this.setState({
          isSaving: false,
        });
      });
  }

  updateRowData = (id, systems, index) => {
    let newMapping = this.state.mappingList;
    // const index = this.state.mappingList.map(e => e.id).indexOf(id)
    const category = newMapping[index].category;
    const functionName = newMapping[index].function;
    const newRow = { id: id, category, function: functionName, systems }
    newRow.systems.forEach(system => {
      newRow[system] = true;
    });
    newMapping[index] = newRow
    this.setState({
      mappingList: newMapping,
    });
    // this.search();
  }  
  getSystems = () => {
    // Function calls the get api to get all the systems
    axiosInstance.get(ApiPath.system).then(res => {
      let systemList = [];
      res.data.forEach(element => {
        systemList.push({name: element})
      });
      systemList.sort(function (a, b) {
        return a.name.localeCompare(b.name);
      });
      this.setState({
        systemList: systemList,
      });
      this.generateColumns();
    });
  }

  getMappings = () => {
    // Function calls the get api to get all the functionCategory-system mapping
    axiosInstance.get(ApiPath.function_category_systems).then(res => {
      let mappingList = res.data;
      mappingList.sort(function (a, b) {
        return a.function.localeCompare(b.function);
      });
      mappingList.forEach(element => {
        element.systems.forEach(system => {
          element[system] = true;
        });
      });
      this.setState({
        mappingList: mappingList,
        filteredmappingList: mappingList,
        isLoading: false
      });
    });
  }
 
  generateColumns() {  
    // for each system, generate a column
    let columns = [{
      dataField: 'function',
      text: 'FUNCTION_CATEGORY',
      headerFormatter: (column, colIndex) => tableHeaderTranslation(column, colIndex),

    }, {
      dataField: 'category',
      text: 'CATEGORY',
      headerFormatter: (column, colIndex) => tableHeaderTranslation(column, colIndex),

    }];
    this.state.systemList.forEach((element, index) => {
      columns.push({
        dataField: element.name,
        text: element.name,
        formatter: (cell, row, index) => {
          return (
            <Checkbox rowData={row} cellContent={cell} index={index}
             col={element.name} toggleSystems={this.toggleSystems} enableDataEntry={this.state.enableDataEntry}/>
          );
        }
      })
    });
    this.setState({
      columns: columns,
    });
  }

  search = () => {
     /* Search for a given input name among the existing function category list. Checks whether given search input is included in 
       category name or function name of any function categoty
    */
    if (!this.searchInput.current) {
      return
    }
    if (this.searchInput.current.value.length === 0) {
      this.setState({
        filteredmappingList: this.state.mappingList,
        isLoading: false
      });
    }
    let value = (this.searchInput.current.value).toString().toUpperCase();
    const filteredItems = this.state.mappingList.filter(item => (item.category.includes(value) || item.function.includes(value)))

    this.setState({
      filteredmappingList: filteredItems,
      isLoading: false
    });
  }

  render() {
    /**IE fix for table */
    const DIV = styled.div`
      table {
        border-collapse: separate !important;
      }
    `;
    return (
      <div className="system-function-mapping  page-padding">
        <div className="mb-4 d-flex mt-4 w-100 px-3 flex-wrap heading-text-div">
          <span className="heading text-capitalize fs-16 page-header col-sm-12 col-md-7 col-lg-9 p-0">
            <FormattedMessage id="SYSTEM_FUNCTION_CATEGORY_MAPPING" />
          </span>
          <div className="input-group flex-nowrap col-10 col-sm-8 col-md-5 col-lg-3 p-0">
            <input type="text" className="form-control"
              placeholder={this.props.intl.formatMessage({ id: 'SEARCH' })}
              ref={this.searchInput}
              onChange={e => this.search(e.target.value)}
              aria-label="Search" aria-describedby="addon-wrapping" />
            <div className="input-group-append pointer" onClick={this.search}>
              <span className="input-group-text search-icon" id="addon-wrapping"><img src={Search} alt="" /></span>
            </div>
          </div>
        </div>
        <hr />
        {(!!this.state.columns.length && !!this.state.systemList.length) &&
          <DIV className="w-100 px-3">
            <BootstrapTable
              keyField="id"
              data={this.state.filteredmappingList}
              columns={this.state.columns}
              bordered={false}
              bootstrap4={true}
            />
          </DIV>
        }
        {((this.state.mappingList.length === 0 ) && !this.state.isLoading) &&
          <div className="text-center">
            <span className="load-more text-capitalize pointer btn text-center" >
              <FormattedMessage id="NO_DATA" />
            </span>
          </div>
        }
        {(this.state.filteredmappingList.length === 0 && this.state.mappingList.length !== 0 && !this.state.isLoading) &&
          <div className="text-center">
            <span className="load-more text-capitalize pointer btn text-center" >
              <FormattedMessage id="NO_SEARCH_DATA" />
            </span>
          </div>
        }
        {this.state.isLoading && (
          <div className="text-center">
            <span className="load-more text-capitalize pointer btn text-center">
              <FormattedMessage id="LOADING" />
              {this.state.isLoading && <div className="lds-dual-ring" />}
            </span>
          </div>
        )}
      </div>
    )
  }
}

export default injectIntl(SystemFunctionMapping);
SystemFunctionMapping.contextType = EntitlementsContext;