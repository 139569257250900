import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";

import Logo from "./../../assets/icons/logo.svg";
import logoutImage from "./../../assets/icons/logout.png"
import * as CONSTANTS from "../../constants/Constants";
import { HeaderContext } from "../../index";
import CommunicationToggle from "./CommunicationToggle/CommunicationToggle";
import SideNav from "./SideNav/SideNav"
import CommunicationPossibleContext from './../../contexts/CommunicationPossibleContext';
import UserInfoContext from './../../contexts/UserInfoContext';
import EntitlementsContext from './../../contexts/EntitlementsContext';
import axiosInstance from "../../utlities/Interceptor"
import { logout } from '../../utlities/Utils';
import ApiPath from '../../constants/ApiPath';
import Notifier from '../../components/Notifier/Notifier';
import GenericPopup from '../../components/popups/GenericPopup/GenericPopup';
import "./Header.scss"

/* Header component contains the elements in Header - toggle switch for communication possible, user name, option to change
  the language, logout button
*/
export default class Header extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false,
      logOutOpen: false,
      userInfo: '',
      onLoad: true,
      onLoadComm: true,
      isCommunicationPossible: true,
      showModal: false
    };
  }
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChange();
    }
  }
  onRouteChange = () => {
    this.getCommunicationPossible(this.setCommunication);
  }

  setUserInfo = (setData) => {
    // calls the function to get the user info. 
    this.getUserInfo(setData);
    this.setState({ onLoad: false })
  }
  getUserInfo = (setData) => {
    /*Function calls the get api to retrieve the user info.Then call the entitlement function to set the user info 
    in the global state. Userinfo api gives the username and user's entilements as result which are set in global state.
    */
    if (localStorage.hasOwnProperty('jwt_token')) {
      let config = { type: 'userInfo' }
      axiosInstance.get(`${ApiPath.userInfo}`, config).then(res => {
        this.setState({ userName: res.data.username.replace('Daimler_', '') })
        setData(res.data.username.replace('Daimler_', ''));
        this.setEntitlements(this.context.setEntitlements, res.data.username.replace('Daimler_', ''))
      }).catch(error => {
        console.log(error)
      })
    }
  }
  setComm = (setCommunication) => {
    this.getCommunicationPossible(setCommunication)
    this.setState({ onLoadComm: false })
  }
  getCommunicationPossible = (setCommunication) => {
    axiosInstance.get(`${ApiPath.communication_status}`).then(res => {
      this.setState({ isCommunicationPossible: res.data.status_info.communication_status })
      setCommunication(this.state.isCommunicationPossible)
      if (!this.state.isCommunicationPossible) {
        this.setState({ showWarningPopup: true })
      }

    });
  }
  setEntitlements = (setEntitlements, userName) => {
    this.getEntitlements(setEntitlements, userName)
    this.setState({ onLoadEntitlement: false })
  }
  getEntitlements = (setEntitlements, userName) => {
    /*  Function calls the api to get entitlements of the user and sets in global state. For an unauthorised user, status code
    5 is returned and logout function is called
    */
    axiosInstance.get(process.env.REACT_APP_API_BASE_URL, { type: 'authorization'}).then(res => {
      if (!res.data.is_active) {
        logout();
    } else {
      setEntitlements(res.data.payload)
    }
    }).catch(error => {
      if (error.response && error.response.data.statusCode === 5) {
        alert(error.response.data.reason)
        logout();
      }
    })
  }
  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }
  toggleLogout = () => {
    this.setState({ logOutOpen: !this.state.logOutOpen })
  }
  showLogoutModal = (show) => {
    this.setState({ showModal: show })
  }
  logout = () => {
    this.setState({ isLoading: true })
    logout();
  }
  render() {
    return (
      <div>
        {window.location.pathname === '/login' || window.location.pathname === '/home' || window.location.pathname === '/loggingout' ? '' :
          <HeaderContext.Consumer>
            {({ language, setLanguage, isMenuOpen, toggleMenu }) => (
              <div
                className={
                  isMenuOpen
                    ? "header side-nav-margin"
                    : "header side-nav-close-margin"
                }
              >
                <SideNav isOpen={isMenuOpen} toggleMenu={toggleMenu} />
                <GenericPopup isOpen={this.state.showModal} toggle={() => this.showLogoutModal(false)}
                  message="ARE_YOU_SURE_YOU_WANT_TO_LOGOUT" okTag="YES" cancelTag="NO" header="LOGOUT_CONFIRMATION" onSubmit={this.logout}
                  isLoading={this.state.isLoading} />
                <div className="top-bar d-flex">
                  <div className="left text-capitalize d-flex flex-column  logo-holder">
                    <img src={Logo} alt="Logo" className="logo" />
                    <span className="logo-name">
                      {/* <FormattedMessage id="LOGO-NAME"></FormattedMessage> */}
                    </span>
                  </div>
                  <CommunicationPossibleContext.Consumer>
                    {({ isCommunicationPossible, setCommunication, showWarningPopup, setWarning, toggleComm }) => {
                      this.setCommunication = setCommunication;
                      return (
                        <div className="center-align">
                          <span>{this.state.onLoadComm ? this.setComm(setCommunication) : ''}</span>
                          {!isCommunicationPossible ? <div className="warning-message">
                            <FormattedMessage id="WARNING_FOR_COMMUNICATION_POSSIBLE" />
                          </div> : ''}
                          {!this.state.isCommunicationPossible ? <Notifier /> : ''}

                        </div>)
                    }}

                  </CommunicationPossibleContext.Consumer>
                  <div className="right d-flex flex-row">
                    <div className="right-first" />
                    <div className="header-toggle mr-1">

                      <CommunicationToggle {...this.props} />
                    </div>
                    <div className="language mr-3">
                      <ButtonDropdown
                        isOpen={this.state.dropdownOpen}
                        toggle={this.toggle}
                      >
                        <DropdownToggle caret>
                          <img
                            src={language.image}
                            alt="language"
                            className="flag"
                          />
                          &nbsp;&nbsp;
                                {language.code}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem onClick={() => setLanguage("EN")}>
                            <img
                              src={CONSTANTS.LANGUAGES.EN.image}
                              alt="EN - Language"
                              className="flag mr-1"
                            />
                            &nbsp;&nbsp;
                                  {CONSTANTS.LANGUAGES.EN.code}
                          </DropdownItem>
                          <DropdownItem divider />
                          <DropdownItem onClick={() => setLanguage("DE")}>
                            <img
                              src={CONSTANTS.LANGUAGES.DE.image}
                              alt="DE - Language"
                              className="flag mr-1"
                            />
                            &nbsp;&nbsp;
                                  {CONSTANTS.LANGUAGES.DE.code}
                          </DropdownItem>
                        </DropdownMenu>
                      </ButtonDropdown>
                    </div>
                    <div className="user-name">
                      <ButtonDropdown
                        isOpen={this.state.logOutOpen}
                        toggle={this.toggleLogout}
                      >
                        <DropdownToggle caret>
                          <div className="greetings d-flex flex-column text-right desktop-only">
                            <span className="text fs-11">
                              <FormattedMessage
                                id="WELCOME"
                                defaultMessage="Welcome"
                                description="Welcome header"
                              />
                            </span>
                          </div>
                          <UserInfoContext.Consumer>
                            {({ userInfo, setUserInfo }) => (
                              <span>
                                {/*<span>{this.state.onLoad && localStorage.hasOwnProperty('jwt_token') ? this.setUserInfo(setUserInfo) : ''}</span>*/}
                                <span className="name fs-15">
                                  {userInfo}</span>
                              </span>
                            )}
                          </UserInfoContext.Consumer>
                        </DropdownToggle>
                        <DropdownMenu className="logout">
                          <DropdownItem onClick={this.showLogoutModal}>
                            <img src={logoutImage} className="logout-icon" alt="" />
                            <span className="text">
                              <FormattedMessage id="LOGOUT" />
                            </span>
                            &nbsp;&nbsp;
                                </DropdownItem>
                        </DropdownMenu>
                      </ButtonDropdown>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </HeaderContext.Consumer>
        }
      </div>
    );
  }
}
Header.contextType = EntitlementsContext;
