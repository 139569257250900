
import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl';
import EntitlementsContext from '../../contexts/EntitlementsContext';
import { entitlements } from '../../constants/Entitlements';
import WarningPopup from '../../components/WarningPopup/WarningPopup';
import axiosInstance from '../../utlities/Interceptor';
import ApiPath from '../../constants/ApiPath';

import './Email.scss';
const app_base_url = process.env.REACT_APP_BASE_URL;

export default class Email extends Component {
    constructor(props) {
        super(props);
        this.state = {
            emailContent: '',
            toEmail: 'wms mail box',
            isSubmitted: false,
            isValid: true,
            isUploaded: false,
            showWarningPopup: false,
            onLoad: true,
            isAuthorized: false
        }
    }
    componentDidMount() {
        this.findAuth();
        axiosInstance.get(ApiPath.email_Content)
            .then(res => {
                console.log(res);
                if (res.data) {
                    this.setState({
                        emailContent: res.data,
                    });
                } else {
                    this.addDefaultTemplate();
                }

            }).catch(error => {
                this.addDefaultTemplate();
                console.log(error)
            })
    }

    addDefaultTemplate = () => {
        this.setState({
            emailContent: `English
....................................................................................................................................................................................

Dear Colleagues,
This is to notify you that the emergency kill switch has been activated in the "Workshop Maintenance Service (WMS)" application.
All communications between XENTRY API and WMS is disabled.
For more details regarding this emergency, please login to ${app_base_url}

....................................................................................................................................................................................
Deutsch
....................................................................................................................................................................................

Liebe Kolleginnen und Kollegen,
Hiermit möchten wir sie Informieren, dass der Notfall-Schalter in der Workshop Maintenance Service (WMS) Applikation aktiviert wurde!
Die Kommunikation zwischen XENTRY API und WMS ist wieder verfügbar.
Weitere Informationen finden Sie unter:  ${app_base_url}
....................................................................................................................................................................................

Regards,
WMS Support Team`,
        });
    }
    componentDidUpdate() {
        this.findAuth();
    }

    // function to set the variable to show the warning popup
    setWarning(show) {
        this.setState({ showWarningPopup: show })
    }

    // Function check whether the user has the entitlement 'apiValidator'; if no, show the warning popup
    findAuth = () => {
        if (this.context && this.context.entitlements.length > 0 && this.state.onLoad) {
            if (!entitlements['email'].includes(this.context.entitlements)) {
                this.setWarning(true)
                this.setState({ onLoad: false, isAuthorized: false })
            }
            else {
                this.setState({ isAuthorized: true, onLoad: false })
            }
        }
    }

    // Function save the request in state variable
    onChange = (event) => {
        this.setState({ emailContent: event.target.value })
    }

    save = () => {
        console.log(this.state);
        let fd = { template: this.state.emailContent }
        axiosInstance.post(ApiPath.email_Content, fd)
            .then(res => {
                console.log(res);
            })
            .catch(error => {
            });
    }

    // Fuction to show a popup if the user is unauthorised to view the file and redirect user to overview page
    closeWarningPopup = () => {
        this.setWarning(false)
        this.props.history.push("/list")
    }

    render() {
        return (
            <div>
                <WarningPopup isOpen={this.state.showWarningPopup} toggle={this.closeWarningPopup} message="YOU_ARE_NOT_AUTHORIZED" />
                {this.state.isAuthorized ? <div className="email-page">
                    <div className="mb-4 d-flex mt-4 w-100 px-3 heading-text-div">
                        <span className="heading text-capitalize fs-16 page-header">
                            <FormattedMessage id="EMAIL"
                                defaultMessage="Email" />
                        </span>
                    </div>
                    <hr />
                    <div className="w-100 pl-3 last-row-sticky">
                        <div className="fs-6 col-xs-10 col-sm-6 col-md-6 col-lg-6 mr-5">
                            <label htmlFor="to" className="text-capitalize">
                                <FormattedMessage id="to" defaultMessage="To : " ></FormattedMessage>
                            </label>
                            <input type="text" name="to" className="input-field" value={this.state.toEmail} readOnly={true} spellCheck="false" />
                        </div>
                        <div className="fs-6 mt-4 col-xs-10 col-sm-6 col-md-6 col-lg-6 mr-5">
                            <textarea className="w-100 fs-12" rows="6" value={this.state.emailContent} spellCheck="false" placeholder='Enter email content' onChange={this.onChange}
                            ></textarea>
                        </div>
                        <div className="fs-6 mt-4 col-xs-10 col-sm-6 col-md-6 col-lg-6 mr-5">
                            <button className="btn btn-primary text-capitalize download" onClick={this.save}>
                                <FormattedMessage id="SAVE" />
                            </button>
                        </div>
                    </div>
                </div> : null}
            </div>
        )
    }
}

Email.contextType = EntitlementsContext;