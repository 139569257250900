import * as Yup from "yup";
import * as moment from "moment";

Yup.addMethod(Yup.string, "dateGreaterThanPublication", dateGreaterThanPublication);
Yup.addMethod(Yup.string, "futureDatesOnly", futureDatesOnly);
Yup.addMethod(Yup.string, "isAfterTime", isAfterTime);
Yup.addMethod(Yup.string, "isBeforeTime", isBeforeTime);
Yup.addMethod(Yup.string, "dateLessThanEndDate", dateLessThanEndDate);
Yup.addMethod(Yup.string, "monthlyMaxVal", monthlyMaxVal);
Yup.addMethod(Yup.string, "dailyDayExist", dailyDayExist);
Yup.addMethod(Yup.string, "weeklyErrors", weeklyErrors);
Yup.addMethod(Yup.string, "monthlyDayExist", monthlyDayExist);
Yup.addMethod(Yup.string, "startLessThanEnd", startLessThanEnd);


export const validationSchema = Yup.object({
    start_date: Yup.string()
        .when('publication_date', (other, schema) => (other ? schema.dateGreaterThanPublication(
            Yup.ref("publication_date"),
            "GREATER_THAN_PUBLICATION_DATE"
        ) : schema))
        .required("START_DATE_AND_TIME_REQUIRED"),
    end_date: Yup.string().nullable()
        .when('start_date', (other, schema) => (other ? schema.isAfterTime(Yup.ref("start_date"), "GREATER_THAN_START_TIME") : schema))
        .required("END_DATE_AND_TIME_REQUIRED")
        .when('start_date', (other, schema) => (other ? schema.startLessThanEnd(Yup.ref("start_date"), "GREATER_THAN_END_TIME") : schema))
    ,
    recurrence_option_value: Yup.string()
        .required("RECURRENCE_OPTION_VALUE_REQUIRED")
        .when('recurrence_option', (other, schema) => (other ? schema.dailyDayExist(Yup.ref("recurrence_option"), Yup.ref("start_date"), Yup.ref("end_date"), "SELECTED_DAY_DOES_NOT_EXIST") : schema))
        .when('recurrence_option', (other, schema) => (other ? schema.weeklyErrors(Yup.ref("recurrence_option"), Yup.ref("recurrence_option_value"), Yup.ref("valid_days"),
            Yup.ref("start_date"), Yup.ref("end_date"), "NO_VALID_DAYS") : schema))
        .when('recurrence_option', (other, schema) => (other ? schema.monthlyDayExist(Yup.ref("recurrence_option"), Yup.ref("recurrence_option_value"), Yup.ref("start_date"), Yup.ref("end_date"), "SELECTED_DAY_DOES_NOT_EXIST") : schema))
});

function isBeforeTime(ref, msg) {
    return this.test('isBeforeTime', msg, function (value) {
        if (this.resolve(ref) && this.resolve(ref) !== '-' && value && value !== '-') {
            let checkField = moment(this.resolve(ref)).utc();
            let valueField = moment(value).utc();
            if (valueField instanceof moment) {
                return (valueField.isSameOrBefore(checkField));
            }
        }
        return true;
    })
}

function isAfterTime(ref, msg) {
    return this.test('isAfterTime', msg, function (value) {
        if (this.resolve(ref) && this.resolve(ref) !== '-' && value && value !== '-') {
            let checkField = moment(this.resolve(ref)).utc();
            let valueField = moment(value).utc();
            if (valueField instanceof moment) {
                return (valueField.isAfter(checkField));
            }
        }
        return true;
    })
}

function futureDatesOnly(msg) {
    return this.test('futureDatesOnly', msg, function (value) {
        //in case of create alert, no need of validation. On clicking save, take current time
        let currentField;
        if (value instanceof moment) {
            currentField = value.utc();
        } else {
            currentField = moment(value).utc();
        }
        if (currentField instanceof moment) {
            let today = moment().utc();
            return (currentField.isSameOrAfter(today));
        }
        return true;
    });
}
function dateGreaterThanPublication(ref, msg) {
    return this.test('dateGreaterThanPublication', msg, function (value) {
        let currentField;
        if (this.resolve(ref)) {
            if (value instanceof moment) {
                currentField = value.utc();
            } else {
                currentField = moment(value).utc();
            }
            let checkField = moment(this.resolve(ref)).utc();
            if (currentField.diff(checkField, "minutes") >= 0) return true;
            else return false;
        }
        return true;
    });
}
function dateLessThanEndDate(ref, msg) {
    return this.test('dateLessThanEndDate', msg, function (value) {
        let currentField;
        if (this.resolve(ref)) {
            if (value instanceof moment) {
                currentField = value.utc();
            } else {
                currentField = moment(value).utc();
            }
            let checkField = moment(this.resolve(ref)).utc();
            if (currentField.diff(checkField, "minutes") >= 0) return true;
            else return false;
        }
        return true;
    });
}

function weeklyErrors(option, option_value, validDays, start_date, end_date, msg) {
    return this.test('NO_VALID_DAYS', msg, function (value) {
        if (this.resolve(option) && this.resolve(option) === 'weekly' && this.resolve(validDays) &&
            this.resolve(start_date) && this.resolve(end_date)) {
            if (this.resolve(validDays).length === 0) {
                return false
            }
        }
        return true;
    })
}
function monthlyMaxVal(ref, msg) {
    return this.test('DAY_VALUE_LESS_THAN', msg, function (value) {
        if (this.resolve(ref) && this.resolve(ref) === 'monthly' && value >= 31) {
            return false
        }
        return true;
    })
}
function dailyDayExist(option, start_date, end_date, msg) {
    return this.test('DAY_VALUE_LESS_THAN', msg, function (value) {
        if (this.resolve(option) && this.resolve(option) === 'daily') {
            if (this.resolve(start_date) && this.resolve(start_date) !== '-' && value && value !== '-' &&
                this.resolve(end_date) && this.resolve(end_date) !== '-' && value && value !== '-') {
                if ((moment(this.resolve(start_date)).add(value, 'days').isAfter(moment(this.resolve(end_date)))) || value > 365) {
                    return false
                }
            }
        }
        return true;
    })
}
function monthlyDayExist(option, value, start_date, end_date, msg) {
    return this.test('SELECTED_DAY_DOES_NOT_EXIST', msg, function (value) {
        if (this.resolve(option) && this.resolve(option) === 'monthly') {
            if (this.resolve(start_date) && this.resolve(start_date) !== '-' && value && value !== '-' &&
                this.resolve(end_date) && this.resolve(end_date) !== '-' && value && value !== '-') {
                let startDate = moment(this.resolve(start_date));
                let month = startDate.format('MM');
                let year = startDate.format('YYYY');
                let current_month_date = moment(new Date(year, (month - 1) % 12, value))
                current_month_date.set({ hour: startDate.get('hour'), minute: startDate.get('minute') })
                year = moment(this.resolve(end_date)).format('YYYY')
                let next_month_date = moment(new Date(year, month % 12, value))
                next_month_date.set({ hour: startDate.get('hour'), minute: startDate.get('minute') })
                if (((moment(current_month_date).isSameOrAfter(moment(this.resolve(start_date)))) &&
                    (moment(current_month_date).isSameOrBefore(moment(this.resolve(end_date))))) || (moment(next_month_date).isSameOrBefore(moment(this.resolve(end_date))))) {
                    return true;
                }
                else {
                    return false;
                }
            }
        }
        return true;
    })

}
function startLessThanEnd(ref, msg) {
    // compare the time part - start time should be less than time in a periodic maintenance
    return this.test('startLessThanEnd', msg, function (value) {
        if (this.resolve(ref) && this.resolve(ref) !== '-' && value && value !== '-') {
            let newDate = moment(this.resolve(ref)).clone();
            newDate.set({ 'hour': moment(value).get('hour'), 'minute': moment(value).get('minute') })
            newDate.format()
            if (moment(this.resolve(ref)).isSameOrAfter(newDate)) {
                return false;
            }
        }
        return true;
    })
}


