// File contains the validation schema for periodic maintenance form

import * as Yup from "yup";
import * as moment from "moment";

Yup.addMethod(Yup.string, "dateGreaterThanPublication", dateGreaterThanPublication);
Yup.addMethod(Yup.string, "futureDatesOnly", futureDatesOnly);
Yup.addMethod(Yup.string, "isAfterTime", isAfterTime);
Yup.addMethod(Yup.string, "isBeforeTime", isBeforeTime);
Yup.addMethod(Yup.string, "dateLessThanEndDate", dateLessThanEndDate);
Yup.addMethod(Yup.string, "monthlyMaxVal", monthlyMaxVal);
Yup.addMethod(Yup.string, "dailyDayExist", dailyDayExist);
Yup.addMethod(Yup.string, "weeklyErrors", weeklyErrors);
Yup.addMethod(Yup.string, "monthlyDayExist", monthlyDayExist);
Yup.addMethod(Yup.string, "endDateRequired", endDateRequired);

// Recurrence Validations
Yup.addMethod(Yup.mixed, "timeRangeValid", timeRangeValid);

export const validationSchema = Yup.object().shape({
    type: Yup.string(),
    publication_date: Yup.string("Select publication date")
        .required("PUBLICATION_DATE_IS_REQUIRED"),
    // .futureDatesOnly("GREATER_THAN_CURRENT_TIME"),
    start_date: Yup.string()
        .when('publication_date', (other, schema) => (other ? schema.dateGreaterThanPublication(
            Yup.ref("publication_date"),
            "GREATER_THAN_PUBLICATION_DATE"
        ) : schema))
        .required("START_DATE_IS_REQUIRED"),
    end_date: Yup.string().nullable()
        .when('start_date', (other, schema) =>
            (other ? schema.isAfterTime(Yup.ref("start_date"), "GREATER_THAN_START_TIME") : schema))
        .when('type', (other, schema) =>
            (other ? schema.endDateRequired(Yup.ref("type"), Yup.ref("id"), "END_DATE_IS_REQUIRED") : schema)),
    message_en: Yup.string()
        .trim()
        .nullable()
        .when(['message_de', 'type'], {
            is: (message_de, type) => !message_de && type === 'i',
            then: Yup.string().required("MESSAGE_IS_REQUIRED"),
            otherwise: Yup.string()
        }),
    message_de: Yup.string()
        .trim()
        .nullable()
        .when(['message_en', 'type'], {
            is: (message_en, type) => !message_en && type === 'i',
            then: Yup.string().required("MESSAGE_IS_REQUIRED"),
            otherwise: Yup.string()
        }),
    recurrence_option_value: Yup.string()
        .nullable()
        .when('recurrence_option', (other, schema) => (other ? schema.dailyDayExist(Yup.ref("is_periodical_maintenance"), Yup.ref("recurrence_option"), Yup.ref("start_date"), Yup.ref("end_date"), Yup.ref("recurrence_data"), "SELECTED_DAY_DOES_NOT_EXIST") : schema))
        .when('recurrence_option', (other, schema) => (other ? schema.weeklyErrors(Yup.ref("is_periodical_maintenance"), Yup.ref("recurrence_option"), Yup.ref("recurrence_option_value"), Yup.ref("recurrence_data"), Yup.ref("valid_days"),
            Yup.ref("start_date"), Yup.ref("end_date"), "NO_VALID_DAYS") : schema))
        .when('recurrence_option', (other, schema) => (other ? schema.monthlyDayExist(Yup.ref("is_periodical_maintenance"), Yup.ref("recurrence_option"), Yup.ref("recurrence_option_value"), Yup.ref("start_date"), Yup.ref("end_date"), Yup.ref("recurrence_data"), "SELECTED_DAY_DOES_NOT_EXIST") : schema))
        .when('is_periodical_maintenance', (other, schema) => (other ? schema.timeRangeValid(Yup.ref("recurrence_option"), Yup.ref("start_date"), Yup.ref("end_date"), Yup.ref("recurrence_data"), "GREATER_THAN_END_TIME") : schema))
    // recurrence_option_value: Yup.mixed()
    //     .when('recurrence_option', (option, schema) => {
    //         switch (option) {
    //             case 'daily':
    //                 return schema.dailyRecurrence(Yup.ref("start_date"), Yup.ref("end_date"));
    //             default:
    //                 return schema;
    //         }
    //     })
}, [
    ['type', 'message_en'],
    ['type', 'message_de'],
    ['message_en', 'message_de'],
]);

// function checks whether reference time is less than another time field
function isBeforeTime(ref, msg) {
    return this.test('isBeforeTime', msg, function (value) {
        if (this.resolve(ref) && this.resolve(ref) !== '-' && value && value !== '-') {
            let checkField = moment(this.resolve(ref)).utc();
            let valueField = moment(value).utc();
            if (valueField instanceof moment) {
                return (valueField.isSameOrBefore(checkField));
            }
        }
        return true;
    })
}

// function checks whether reference time is after another time field
function isAfterTime(ref, msg) {
    return this.test('isAfterTime', msg, function (value) {
        if (this.resolve(ref) && this.resolve(ref) !== '-' && value && value !== '-') {
            let checkField = moment(this.resolve(ref)).utc();
            let valueField = moment(value).utc();
            if (valueField instanceof moment) {
                return (valueField.isAfter(checkField));
            }
        }
        return true;
    })
}
// function checks whether reference data is future date
function futureDatesOnly(msg) {
    return this.test('futureDatesOnly', msg, function (value) {
        //in case of create alert, no need of validation. On clicking save, take current time
        let currentField;
        if (value instanceof moment) {
            currentField = value.utc();
        } else {
            currentField = moment(value).utc();
        }
        if (currentField instanceof moment) {
            let today = moment().utc();
            return (currentField.isSameOrAfter(today));
        }
        return true;
    });
}
// function checks whether reference date is greater than publication date
function dateGreaterThanPublication(ref, msg) {
    return this.test('dateGreaterThanPublication', msg, function (value) {
        let currentField;
        if (this.resolve(ref)) {
            if (value instanceof moment) {
                currentField = value.utc();
            } else {
                currentField = moment(value).utc();
            }
            let checkField = moment(this.resolve(ref)).utc();
            if (currentField.diff(checkField, "minutes") >= 0) return true;
            else return false;
        }
        return true;
    });
}
// function checks whether reference date is less than end date
function dateLessThanEndDate(ref, msg) {
    return this.test('dateLessThanEndDate', msg, function (value) {
        let currentField;
        if (this.resolve(ref)) {
            if (value instanceof moment) {
                currentField = value.utc();
            } else {
                currentField = moment(value).utc();
            }
            let checkField = moment(this.resolve(ref)).utc();
            if (currentField.diff(checkField, "minutes") >= 0) return true;
            else return false;
        }
        return true;
    });
}

// function to check weekly errors: Whether selected day exist between start date and end date
function weeklyErrors(isPeriodic, option, option_value, recurrence_data, validDays, start_date, end_date, msg) {
    return this.test('NO_VALID_DAYS', msg, function (value) {
        const startDate = this.resolve(start_date);
        const endDate = this.resolve(end_date);
        const recurrenceOption = this.resolve(option);
        const recurrenceOptionValue = this.resolve(recurrence_data) ? JSON.parse(this.resolve(recurrence_data)) : {};
        if (recurrenceOption === 'weekly' && !Object.keys(recurrenceOptionValue).length) {
            return false;
        }
        if (startDate && endDate && endDate !== '-' && recurrenceOption && recurrenceOption === 'weekly' && recurrenceOptionValue && this.resolve(isPeriodic)) {
            if (moment(endDate).diff(moment(startDate), 'days') >= 7)
                return true;
            const validDaysArr = [];
            for (let m = moment(startDate); m.diff(moment(endDate).startOf('day'), 'days') <= 0; m.add(1, 'days')) {
                validDaysArr.push(m.isoWeekday());
            }
            let isValid = true;
            Object.keys(recurrenceOptionValue).forEach(el => {
                if (!validDaysArr.includes(parseInt(el.split('_')[1])))
                    isValid = false;
            })
            return isValid;

        }
        return true;
    })
}
// function to check whether the max value of the recurrence option is less than 31 in case of monthly
function monthlyMaxVal(ref, msg) {
    return this.test('DAY_VALUE_LESS_THAN', msg, function (value) {
        if (this.resolve(ref) && this.resolve(ref) === 'monthly' && value >= 31) {
            return false
        }
        return true;
    })
}
// function to check whether given nth day exist between start date and end date
function dailyDayExist(isPeriodic, option, start_date, end_date, recurrence_data, msg) {
    return this.test('DAY_VALUE_LESS_THAN', msg, function (value) {
        const recurrenceOptionValue = this.resolve(recurrence_data) ? JSON.parse(this.resolve(recurrence_data)) : {};
        if (this.resolve(option) && this.resolve(option) === 'daily' && this.resolve(isPeriodic)) {
            const recurrenceValue = parseInt(recurrenceOptionValue.value);
            if (this.resolve(start_date) && this.resolve(start_date) !== '-' && this.resolve(end_date) && this.resolve(end_date) !== '-' && recurrenceValue && recurrenceValue !== '-') {
                if (moment(this.resolve(start_date)).add(recurrenceValue, 'days').isAfter(moment(this.resolve(end_date))) || recurrenceValue < 0) {
                    return false
                }
            }
        }
        return true;
    })
}
// function to check whether at least one nth day of a given month exist between start date and end date
function monthlyDayExist(isPeriodic, option, value, start_date, end_date, recurrence_data, msg) {
    return this.test('SELECTED_DAY_DOES_NOT_EXIST', msg, function (value) {
        const recurrenceOptionValue = this.resolve(recurrence_data) ? JSON.parse(this.resolve(recurrence_data)) : {};
        if (this.resolve(option) && this.resolve(option) === 'monthly' && this.resolve(isPeriodic)) {
            const recurrenceValue = parseInt(recurrenceOptionValue.value);
            if (this.resolve(start_date) && this.resolve(start_date) !== '-' && this.resolve(end_date) && this.resolve(end_date) !== '-') {
                if (recurrenceValue < 0)
                    return false;
                const startMoment = moment(this.resolve(start_date)).clone();
                const endMoment = moment(this.resolve(end_date)).clone();
                const recurrenceStartMoment = startMoment.clone().date(recurrenceValue);
                const recurrenceEndMoment = endMoment.clone().date(recurrenceValue);

                if (endMoment.diff(startMoment, 'months') >= 1 || recurrenceStartMoment.isBetween(startMoment, endMoment, null, '[]') || recurrenceEndMoment.isBetween(startMoment, endMoment, null, '[]')) {
                    return true;
                }
                else {
                    return false;
                }

                // let startDate = moment(this.resolve(start_date));
                // let month = startDate.format('MM');
                // let year = startDate.format('YYYY');
                // let current_month_date = moment(new Date(year, (month - 1) % 12, parseInt(recurrenceOptionValue.value)))
                // current_month_date.set({ hour: startDate.get('hour'), minute: startDate.get('minute') })
                // year = moment(this.resolve(end_date)).format('YYYY')
                // let next_month_date = moment(new Date(year, month % 12, parseInt(recurrenceOptionValue.value)))
                // next_month_date.set({ hour: startDate.get('hour'), minute: startDate.get('minute') })
                // if (((moment(current_month_date).isSameOrAfter(moment(this.resolve(start_date)))) &&
                //     (moment(current_month_date).isSameOrBefore(moment(this.resolve(end_date))))) || (moment(next_month_date).isSameOrBefore(moment(this.resolve(end_date))))) {
                //     return true;
                // }
                // else {
                //     return false;
                // }


            }
        }
        return true;
    })
}
// function defines end date as a required field for type maintenance
function endDateRequired(type, id, msg) {
    return this.test('END_DATE_IS_REQUIRED', msg, function (value) {
        const resolvedId = this.resolve(id);
        if(resolvedId && resolvedId.includes('emergency_entry')) {
            return true;
        }
        if (this.resolve(type) === 'm' && (!value || value === '-')) {
            return false
        }
        return true
    })
}



//Recurrence

function timeRangeValid(option, start_date, end_date, recurrence_data, msg) {
    return this.test('GREATER_THAN_END_TIME', msg, function (recurrence) {
        const recurrenceOptionValue = this.resolve(recurrence_data) ? JSON.parse(this.resolve(recurrence_data)) : null;
        if (recurrenceOptionValue) {
            if (this.resolve(option) === 'weekly') {
                let isValid = true;
                Object.keys(recurrenceOptionValue).forEach(el => {
                    let startTimeMoment = moment(moment(moment.utc(recurrenceOptionValue[el].startTime, 'H:mm:ss')).local().format('H:mm:ss'), 'H:mm:ss');
                    let endTimeMoment = moment(moment(moment.utc(recurrenceOptionValue[el].endTime, 'H:mm:ss')).local().format('H:mm:ss'), 'H:mm:ss');
                    if (startTimeMoment.isSameOrAfter(endTimeMoment)) {
                        isValid = false;
                        return false;
                    }
                })
                return isValid;
            }
            else {
                const { startTime, endTime } = recurrenceOptionValue;
                let startTimeMoment = moment(moment(moment.utc(startTime, 'H:mm:ss')).local().format('H:mm:ss'), 'H:mm:ss');
                let endTimeMoment = moment(moment(moment.utc(endTime, 'H:mm:ss')).local().format('H:mm:ss'), 'H:mm:ss');
                if (startTimeMoment.isSameOrAfter(endTimeMoment))
                    return false;
            }
        }
        return true;
    });
}