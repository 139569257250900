// File contains the functions shared between components

// import { FormattedMessage } from "react-intl";
// import React from "react";
import { toast } from "react-toastify";
import { ApiPath } from "../constants/ApiPath";
import Cookies from "js-cookie";

export const keydown = (event) => {
  // Function prevents history.back() occuring in ie on pressing backspace in readonly input fields
  if (event.keyCode === 8) {
    event.preventDefault();
  }
};

export const logout = async () => {
  try {
    const response = await fetch(ApiPath.logout, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    await response.json();
    window.location.href = ApiPath.logoutRedirect;
  } catch (err) {
    console.error(err);
  }
};

export const showToast = (message) => {
  // Function to show the error messages occuring in api calls as a toast message.
  // to do translations, pass id and change to formattedmessage
  toast.dismiss();
  toast.error(message, {
    position: "top-right",
    autoClose: 2800,
    hideProgressBar: false,
  });
};
export const generateRandomNumber = (n) => {
  return Math.floor(Math.random() * (9 * 10 ** n - 1)) + (10 ** n - 1);
};
