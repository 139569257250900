// Navigation items is an object that stores the details about the items displayed in sidebar
export const NAVIGATION_ITEMS =
{
  user: [
    {
      id: 1,
      text: 'Overview',
      translation: 'OVERVIEW',
      image: 'Overview.svg',
      url: '/list'
    },
    {
      id: 2,
      text: 'New Alerts',
      translation: 'NEW_ALERTS',
      image: 'New.svg',
      url: '/alert',
      type: 'newAlert'
    }
  ],
  admin: [
    {
      id: 3,
      text: 'Categories',
      translation: 'CATEGORIES',
      image: 'Category.svg',
      url: '/category'
    },
    {
      id: 4,
      text: 'Function Categories',
      translation: 'FUNCTION_CATEGORIES',
      image: 'Function_Categories.svg',
      url: '/function_categories'
    },
    {
      id: 5,
      text: 'Systems',
      translation: 'SYSTEMS',
      image: 'Systems.svg',
      url: '/systems'
    },
    {
      id: 6,
      text: 'Mapping',
      translation: 'MAPPING',
      image: 'Mapping.svg',
      url: '/mapping'
    },
    {
      id: 7,
      text: 'API Validator',
      translation: 'API_VALIDATOR',
      image: 'api-validator.png',
      url: '/api-validator',
      class: 'api-validator',
      type: 'apiValidator'
    }, 
    {
      id: 8,
      text: 'Email',
      translation: 'EMAIL',
      image: 'send-email-50.png',
      url: '/email',
      class: 'email',
      type: 'email'
    }
  ]
};

export default NAVIGATION_ITEMS;